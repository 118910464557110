import { gql } from 'apollo-angular';

export const Mutation_AddServiceVisit = gql`
  mutation addServiceVisitFromWorkOrder(
    $dto: DtoAddServiceVisitFromWorkOrder!
  ) {
    addServiceVisitFromWorkOrder(dto: $dto) {
      id
      serviceVisitNum
      status
      address
      address1
      address2
      city
      category
      state
      country
      lat
      lng
      mapURL
      scheduledStartDateTime
      scheduledEndDateTime
      customer {
        id
        name
      }
      serviceVisitWorkers {
        id
        status
        statusRef {
          id
          color
        }
        worker {
          id
          displayName
          user {
            id
            email
            avatarURL
          }
        }
      }
      serviceVisitWorkOrders {
        id
        workOrder {
          id
          workOrderNum
          subject
          status
          type
          lat
          lng
        }
      }
    }
  }
`;
