import { gql } from 'apollo-angular';

export const Mutation_AddWorkOrderFromExternalLink = gql`
  mutation addWorkOrderFromExternalLink($dto: DtoAddWorkOrderExternal!) {
    addWorkOrderFromExternalLink(dto: $dto) {
      workOrder {
        workOrderNum
        id
      }
      attachmentUploadUrls
    }
  }
`;
