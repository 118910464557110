import { gql } from "apollo-angular";

export const Query_GetReportingCodes = gql`
  query GetReportingCodes($search: DtoPaginatedFilter!) {
  getReportingCodes(search: $search) {
    totalCount
    edges {
      cursor
      node {
        id
        type
        name
        description
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
`;