import { gql } from "apollo-angular";

export const Mutation_DeleteWorkOrdersFromServiceVisit = gql`
  mutation deleteWorkOrdersFromServiceVisit($ids: [String!]!) {
    deleteWorkOrdersFromServiceVisit(ids: $ids) {
      id
      serviceVisitWorkOrders {
        id
        workOrder {
          id
        }
      }
    }
  }
`;