import { gql } from 'apollo-angular';

export const Mutation_UpdateColumnLayoutByObjectType = gql`
  mutation UpdateObjectColumnLayoutByObjectType(
    $dto: DtoUpdateColumnLayoutByObjectType!
  ) {
    UpdateObjectColumnLayoutByObjectType(dto: $dto) {
      id
      columns
      object
      buttons
    }
  }
`;
