<grid-menu
  class="mt-3"
  *ngIf="!hideToolBar && buttons && buttons.length > 0"
  [selectedRow]="selectedRow"
  [gridMenuButtons]="buttons"
  (buttonAction)="emitButtonAction($event)"
>
</grid-menu>

<nb-card class="mt-1">
  <nb-card-body [ngClass]="viewType === 'Scheduler' ? 'less-padding' : ''">
    <div class="d-flex w-100 justify-content-center">
      @if (showQuickSearch) {
        <input
          id="quickSearch"
          nbInput
          fullWidth
          placeholder="Search"
          autofocus
          (input)="onQuickSearchChange($event.target.value)"
          class="mb-2"
        />
      }
      <grid-queue
        #gridQueue
        [id]="rndId + '-grid-queue'"
        class="w-100"
        [adhocFilter]="adhocFilter"
        [filters]="queues"
        [object]="object"
        *ngIf="!hideControlBar"
        [hideQueues]="hideQueues"
        (onClick)="filterGrid($event)"
        (onAddQueue)="addQueue($event)"
        (onShowSettings)="openDialog(settings)"
        (onDeleteQueue)="deleteQueue($event)"
        (onRefresh)="refreshGrid()"
        [page]="page"
      ></grid-queue>
    </div>
    <ag-grid-angular
      #agGrid
      [id]="rndId + '-ag-grid'"
      style="width: 100%"
      style.height="{{ height || 800 }}px"
      [gridOptions]="gridOptions"
      [columnDefs]="columnDefs"
      [getContextMenuItems]="contextMenu"
      [allowContextMenuWithControlKey]="true"
      class="ag-theme-balham"
    >
    </ag-grid-angular>
  </nb-card-body>
</nb-card>

<!-- TODO PUT THIS IN A COMPONENT!!! -->
<ng-template #settings let-data let-ref="dialogRef">
  <nb-card accent="primary" style="width: 800px; height: 800px">
    <nb-card-header>{{ object }} View Settings </nb-card-header>
    <nb-card-body>
      <nb-tabset>
        <nb-tab tabTitle="Columns">
          <json-editor
            class="columns"
            [options]="editorOptions"
            [data]="columnDefs"
            #editor
          ></json-editor>
        </nb-tab>
        <nb-tab tabTitle="Buttons">
          <json-editor
            class="columns"
            [options]="editorOptions"
            [data]="buttons"
            #editorButtons
          ></json-editor>
        </nb-tab>
        <nb-tab tabTitle="Queues">
          <nb-accordion>
            <nb-accordion-item *ngFor="let queue of queues">
              <nb-accordion-item-header>
                <nb-icon status="medium" icon="funnel"></nb-icon>
                {{ queue.name }}
                <span class="flex-expand"></span>
                <span
                  class="badge filterTabsCount float-right mr-4"
                  [class]="
                    queue.badgeColor
                      ? 'badge-' + queue.badgeColor
                      : 'badge-light'
                  "
                >
                  <i
                    class="b-fa b-fa-spinner b-fa-pulse b-fa-spin"
                    *ngIf="queue.recordCount < 0"
                  ></i>
                  Record Count
                  {{ queue.recordCount < 0 ? '' : queue.recordCount }}
                </span>
              </nb-accordion-item-header>
              <nb-accordion-item-body>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="externalQuoteNumber" class="label"
                        >Queue Name</label
                      >
                      <input
                        type="text"
                        nbInput
                        fullWidth
                        id="description"
                        [(ngModel)]="queue.name"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="externalQuoteNumber" class="label"
                        >Queue Type</label
                      >
                      <input
                        type="text"
                        nbInput
                        fullWidth
                        id="description"
                        [(ngModel)]="queue.type"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>

                <h6>Filter</h6>
                <div>
                  <json-editor
                    class="filters"
                    [options]="editorOptions"
                    [data]="queue.filter"
                    #editor2
                    style="height: 500px"
                  ></json-editor>

                  <br />

                  <h6>Badge Logic</h6>
                  <json-editor
                    class="filters"
                    [options]="editorOptions"
                    [data]="queue.badgeLogic"
                    #editor2
                    style="height: 500px"
                  ></json-editor>

                  <br />
                  <button
                    nbButton
                    hero
                    size="small"
                    status="danger"
                    class="float-right"
                    (click)="deleteQueue(queue.id)"
                  >
                    <nb-icon icon="trash-outline"></nb-icon> Delete Filter
                  </button>
                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
    <nb-card-footer class="d-flex">
      <button nbButton (click)="ref.close()">Cancel</button>
      <button
        nbButton
        class="restore-default-button"
        status="basic"
        (click)="openDialog(defaults, object)"
      >
        Restore Defaults
      </button>
      <span class="flex-expand"></span>
      <button
        nbButton
        status="primary"
        (click)="
          updateObjectColumnLayout(editor.get(), editorButtons.get());
          ref.close()
        "
      >
        Save
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #defaults let-data let-ref="dialogRef">
  <nb-card accent="warning">
    <nb-card-header
      class="dialog-header"
      style="display: flex; justify-content: center"
      >Are you sure you want to restore defaults?
    </nb-card-header>
    <nb-card-body>
      <div style="display: flex; justify-content: center">
        <nb-icon
          style="font-size: 4rem; color: #ffaa00"
          class="delete-icon"
          icon="alert-circle-outline"
        ></nb-icon>
      </div>
    </nb-card-body>
    <nb-card-footer class="defaults-cancel">
      <button nbButton (click)="ref.close()">Cancel</button>
      <button
        nbButton
        status="primary"
        (click)="handleResetObjectColumnLayout(object); ref.close()"
      >
        Yes
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>
