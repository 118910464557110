<div
  class="main"
  [style.height.px]="height"
  [ngClass]="{ isFullScreen: fullScreen }"
>
  <nb-card class="floatingMenu" *ngIf="isDispatchHub">
    <nb-actions size="medium">
      <nb-action
        nbButton
        icon="options-2-outline"
        title="Map Options"
        [nbPopover]="mapOptionPopup"
        id="1"
        [nbPopoverContext]="{ text: 'Example context' }"
        nbPopoverPlacement="bottom"
        nbPopoverTrigger="noop"
        (click)="showOptionPopup()"
      ></nb-action>
    </nb-actions>
  </nb-card>
  <!-- <div class="header-container" [hidden]="hideDropDowns || !isDispatchHub">
    <next-locationinputselect
      (onLocationChange)="onLocationChange($event)"
    ></next-locationinputselect>
    <nb-select
      multiple
      size="small"
      placeholder="Select Teams"
      class="teamSelect"
      [selected]="location?.teams"
      (selectedChange)="onTeamChange($event)"
    >
      <nb-option *ngFor="let team of location?.teams" [value]="team">
        {{ team.name }}
      </nb-option>
    </nb-select>

    <span class="flex-expand"></span>
   
  </div>
  -->
  <div [id]="mapContainerID" class="map-container"></div>
  <ng-template #mapOptionPopup let-data>
    <nb-card class="popover-card">
      <nb-card-body>
        <div class="header-container">
          <h6 style="float: left">Map Options</h6>
          <span class="flex-expand"></span>
          <button
            nbButton
            status="primary"
            ghost
            class="closeMapOptionsButton"
            (click)="closeOptionPopup()"
          >
            <nb-icon icon="close-outline"></nb-icon>
          </button>
        </div>

        <div>
          <nb-tabset>
            <nb-tab tabTitle="Layers">
              <div class="options">
                <div class="row">
                  <nb-toggle
                    status="primary"
                    [checked]="optionShowLastActivity"
                    (checkedChange)="
                      mapOptionChange('optionShowLastActivity', $event)
                    "
                    >Show Workers Last Activity</nb-toggle
                  >
                </div>
                <div class="row" *ngIf="!isDispatchHub">
                  <nb-toggle
                    status="primary"
                    [checked]="optionShowGrid"
                    (checkedChange)="mapOptionChange('optionShowGrid', $event)"
                    >Show Work Order Grid</nb-toggle
                  >
                </div>
                <div class="row" *ngIf="!isDispatchHub">
                  <nb-toggle
                    status="primary"
                    [checked]="optionShowScheduledRoute"
                    (checkedChange)="
                      mapOptionChange('optionShowWorkersHomeBase', $event)
                    "
                    >Show Worker Home Base</nb-toggle
                  >
                </div>
                <!-- <div class="row" *ngIf="!isDispatchHub">
                  <nb-toggle
                    status="primary"
                    [checked]="optionShowScheduledRoute"
                    (checkedChange)="
                      mapOptionChange('optionShowScheduledRoute', $event)
                    "
                    >Show Customer Grid</nb-toggle
                  >
                </div> -->
                <div class="row" *ngIf="!isDispatchHub">
                  <nb-toggle
                    status="primary"
                    [checked]="optionShowScheduledRoute"
                    (checkedChange)="
                      mapOptionChange('optionShowScheduledRoute', $event)
                    "
                    >Show Scheduled Routes</nb-toggle
                  >
                </div>

                <!-- <div class="row" *ngIf="!isDispatchHub">
                  <nb-toggle
                    status="primary"
                    [checked]="optionShowScheduledRoute"
                    (checkedChange)="
                      mapOptionChange('optionShowScheduledRoute', $event)
                    "
                    >Show Workers Assigned Routes</nb-toggle
                  >
                </div> -->

                <br />
                <div class="row">
                  <nb-select
                    multiple
                    placeholder="All Workers"
                    class="workerMultiSelect"
                    fullWidth
                    multiple
                  >
                    <!-- <nb-option value="1">Dan</nb-option>
                    <nb-option value="2">John</nb-option>
                    <nb-option value="3">Option 3</nb-option>
                    <nb-option value="4">Option 4</nb-option> -->
                  </nb-select>
                </div>
              </div>
            </nb-tab>
            <nb-tab tabTitle="Replay">
              <div class="row">
                <nb-calendar [(date)]="replayDate"> </nb-calendar>
              </div>
              <br />

              <!-- <div class="progress-wrap" style="width: 300px;">
                                <input dir="ltr" type="range" class="progress" min="0" max="100" step="0.01"
                                    #duration />
                                <div class="progress-foreground" [style.width.%]="0"></div>
                            </div>
                            <div class="timing">
                                 <small class="current">{{ player?.currentTime | timing }}</small>
                                <small class="remaining">- {{ player?.duration - player?.currentTime | timing }}</small> 
                            </div>
                            <div class="row">
                                <div class="controls">
                                    <button class="control-button" nbButton ghost size="tiny">
                                        <nb-icon icon="shuffle-2-outline" pack="eva"></nb-icon>
                                    </button>
                                    <button class="control-button" nbButton ghost size="medium">
                                        <nb-icon class="skip" icon="skip-back-outline" pack="eva"></nb-icon>
                                    </button>
                                    <button class="control-button play-button" nbButton ghost size="medium">
                                        <nb-icon class="play" [icon]="
                        player?.paused
                          ? 'play-circle-outline'
                          : 'pause-circle-outline'
                      " pack="eva">
                                        </nb-icon>
                                    </button>
                                    <button class="control-button skip-forward-button" nbButton ghost size="medium">
                                        <nb-icon class="skip" icon="skip-forward-outline" pack="eva"></nb-icon>
                                    </button>
                                    <button class="control-button" nbButton ghost size="tiny">
                                        <nb-icon icon="repeat-outline" pack="eva"></nb-icon>
                                    </button>
                                </div>
                            </div> -->
              <br />
              <div class="row">
                <nb-select
                  multiple
                  placeholder="All Workers"
                  class="workerMultiSelect"
                  fullWidth
                  multiple
                >
                  <nb-option value="1">Dan</nb-option>
                  <nb-option value="2">John</nb-option>
                  <nb-option value="3">Option 3</nb-option>
                  <nb-option value="4">Option 4</nb-option>
                </nb-select>
              </div>
              <br />
              <button
                nbButton
                status="primary"
                fullWidth
                (click)="showReplay()"
              >
                <nb-icon icon="play-circle-outline"></nb-icon>REPLAY
              </button>
            </nb-tab>
          </nb-tabset>
        </div>
      </nb-card-body>
    </nb-card>
  </ng-template>
</div>

<ng-template #editTmpl>
  <ngx-toolbar [mainTitle]="'Attack Alert!'"> </ngx-toolbar>
  <section class="section">
    <h1>Attack Type: Malware</h1>
    <button type="button" class="btn" (click)="openDrawer()">
      Open Details
    </button>
  </section>
</ng-template>
