import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { QUERY_GetAttachments } from '../graphql/queries/attachment/getAttachments';
import { DtoFillServiceVisitSummaryOfWork } from '../interfaces/DtoFillServiceVisitSummaryOfWork';
import { Mutation_GenerateServiceVisitSummaryOfWork } from '../graphql/mutations/forms/serviceVisitSummaryOfWork.mutation';

@Injectable({
  providedIn: 'root',
})
export class FormsApiService {
  constructor(private apollo: Apollo) {}

  fillForm_ServiceVisitSummaryOfWork(dto: DtoFillServiceVisitSummaryOfWork) {
    return this.apollo.mutate({
      mutation: Mutation_GenerateServiceVisitSummaryOfWork,
      variables: { dto },
      refetchQueries: [
        {
          query: QUERY_GetAttachments,
          variables: {
            data: {
              object: 'Service_Visit',
              refId: dto.serviceVisitId,
            },
          },
        },
      ],
    });
  }
}
