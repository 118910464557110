import { gql } from 'apollo-angular';

export const Mutation_SetLastMessageRead = gql`
  mutation setLastMessageRead($data: DtoSetLastMessageRead!) {
    setLastMessageRead(data: $data) {
      id
      sessionId
      lastReadMessageId
    }
  }
`;
