import { query } from '@angular/animations';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { tap } from 'rxjs/operators';
import { Mutation_SendMessage } from '../../graphql/mutations/messaging/sendMessage';
import { Mutation_SetLastMessageRead } from '../../graphql/mutations/messaging/setLastMessageRead';
import { Query_GetMyMessagingSessions } from '../../graphql/queries/messaging/getMySessions.query';
import { Query_GetSession } from '../../graphql/queries/messaging/getSession.query';
import { Query_GetSessionMessages } from '../../graphql/queries/messaging/getSessionMessages.query';
import { Subscription_MessageAdded } from '../../graphql/queries/messaging/messageAdded.subscription';
import { Mutation_HideSession } from '../../graphql/mutations/messaging/hideSession';
import { DtoSendMessage } from '../../interfaces/DtoSendMessage';

@Injectable({
  providedIn: 'root',
})
export class MessagingApiService {
  constructor(private apollo: Apollo) {}

  subscriptionMessageAdded() {
    return this.apollo.subscribe({
      query: Subscription_MessageAdded,
    });
  }

  hideSession(sessionId) {
    return this.apollo.mutate({
      mutation: Mutation_HideSession,
      variables: { sessionId },
      refetchQueries: [{ query: Query_GetMyMessagingSessions }],
    });
  }

  getMySessions(fetchPolicy: any = 'cache-and-network') {
    return this.apollo.watchQuery({
      query: Query_GetMyMessagingSessions,
      //pollInterval:5000,
      fetchPolicy,
    }).valueChanges;
  }

  getSession(sessionId) {
    return this.apollo.query({
      query: Query_GetSession,
      variables: { id: sessionId },
    });
  }

  getSessionMessages(sessionId) {
    return this.apollo.watchQuery({
      query: Query_GetSessionMessages,
      variables: { sessionId },
    }).valueChanges;
  }

  setLastMessageRead(sessionId: string, messageId: string) {
    return this.apollo
      .mutate({
        mutation: Mutation_SetLastMessageRead,
        variables: { data: { sessionId, messageId } },
        update: (cache, r) => {
          const sentMessage = (<any>r)?.data?.sendMessage;
          const { sessionId } = (<any>r)?.data?.setLastMessageRead;
          const query = cache.readQuery({
            query: Query_GetMyMessagingSessions,
          });
          const sessions = (<any>query)?.getMySessions;
          let newSessions: any = {};
          newSessions = sessions?.map((session) => {
            if (session.sessionId === sessionId) {
              return {
                ...session,
                unreadMessageCount: 0,
              };
            } else {
              return session;
            }
          });
          cache.writeQuery({
            query: Query_GetMyMessagingSessions,
            data: { getMySessions: newSessions },
          });
        },
      })
      .pipe(tap((e) => console.log(e)));
  }

  sendMessage(data: DtoSendMessage) {
    return this.apollo.mutate({
      mutation: Mutation_SendMessage,
      variables: { data },
      update: (cache, r) => {
        const sentMessage = (<any>r)?.data?.sendMessage;
        const { id, sessionId } = sentMessage;
        const query = cache.readQuery({
          query: Query_GetMyMessagingSessions,
        });
        const sessions = (<any>query)?.getMySessions;
        let newSessions: any = {};
        newSessions = sessions?.map((t) => {
          if (t.sessionId === sessionId) {
            return {
              ...t,
              messages: [...t.messages, sentMessage],
              lastMessageRef: {
                id,
                message: data.message,
                createdAt: new Date(),
              },
            };
          } else {
            return t;
          }
        });
        cache.writeQuery({
          query: Query_GetMyMessagingSessions,
          data: { getMySessions: newSessions },
        });
      },
      // refetchQueries: [
      //   { query: Query_GetSessionMessages, variables: { sessionId } },
      // ],
    });
  }
}
