<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img height="40px" src="assets/img/stack-logo.png" alt="Stack Logo" />
    </a>
    <div class="tenant-name-container">
      <p class="tenant-name">{{ user.tenantName }}</p>
    </div>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
  <!-- <ngx-layout-direction-switcher class="direction-switcher"></ngx-layout-direction-switcher> -->
</div>

<div class="header-container">
  <nb-actions size="medium">
    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action> -->
    <!-- <nb-action class="control-item" icon="email-outline"></nb-action> -->
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <nb-action
      class="control-item chat"
      icon="message-circle-outline"
      title="Messaging"
      [nbPopover]="list"
      nbPopoverPlacement="bottom"
      badgePosition="top right"
      [badgeText]="unreadMessaegeCount"
      badgeStatus="danger"
    ></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user
        class="user-avatar"
        shape="round"
        [nbContextMenu]="userMenu"
        nbContextMenuTag="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.name"
        [picture]="user?.avatarURL"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
  <!-- <button nbButton size="tiny" [nbPopover]="adminOnly">Admin Only</button> -->
</div>

<ng-template #adminOnly>
  <nb-list>
    <nb-list-item>
      <strong>Dev <span>&nbsp;</span></strong>
      <br />
      <nb-toggle
        status="primary"
        [checked]="sharedEnv.getCurrentEnv()"
        (checkedChange)="toggleEnv(!sharedEnv.getCurrentEnv())"
      ></nb-toggle>
      <br />
      <strong> <span>&nbsp;</span>Prod</strong>
    </nb-list-item>
    <nb-list-item>
      <strong>Expt. Feat.<span>&nbsp;</span> </strong>
      <nb-toggle
        status="primary"
        [checked]="sharedEnv.getExperimentalFeatures()"
        (checkedChange)="toggleExptFeats(!sharedEnv.getExperimentalFeatures())"
      ></nb-toggle>
    </nb-list-item>
  </nb-list>
</ng-template>

<ng-template #list>
  <nb-card style="width: 310px">
    <!-- <nb-list-item> <b>Dispatch Messaging </b> </nb-list-item> -->
    <nb-list>
      <nb-list-item
        *ngFor="let session of teamMessages$ | async"
        (click)="openChatSession(session)"
        class="clickable"
      >
        <nb-user
          [name]="session?.teamName"
          [title]="session?.lastMessageRef?.message"
          badgePosition="top right"
          [badgeText]="session?.unreadMessageCount"
          badgeStatus="danger"
        >
        </nb-user>
      </nb-list-item>
    </nb-list>
    <nb-list-item>
      <b>Direct Messaging</b>
      <!--  <span></span>
    <button nbButton ghost status="primary">
        <nb-icon icon="plus-outline"></nb-icon>
        New
      </button> -->
    </nb-list-item>
    <nb-list>
      <nb-list-item
        *ngFor="let session of directMessages$ | async"
        class="clickable"
      >
        <nb-user
          (click)="openChatSession(session)"
          [picture]="session?.recipentUser?.avatarURL"
          [badgeText]="session?.unreadMessageCount"
          badgeStatus="danger"
          shape="semi-round"
          badgePosition="top right"
          [name]="session?.recipentUser?.fullName"
          [title]="session?.lastMessageRef?.message"
        ></nb-user>
        <span></span>
        <button nbButton ghost size="small" (click)="hideSession(session)">
          <nb-icon icon="close-outline"></nb-icon>
        </button>
      </nb-list-item>
    </nb-list>
  </nb-card>
</ng-template>
