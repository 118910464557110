import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import mapboxgl from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
export interface ILatLng {
  lat: number;
  lng: number;
}

export interface IStaticMapSize {
  x: number;
  y: number;
}

interface MapWorkOrderEvent {
  type: 'hover' | 'click';
  workOrderId: string;
}
@Injectable({
  providedIn: 'root',
})
export class MapService {
  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v11';
  lat = 32.7767;
  lng = -96.797;
  zoom = 12;

  private baseUrl: string = 'https://api.mapbox.com';
  private apiKey: string = `access_token=${environment.mapbox.accessToken}`;

  private mapWorkOrderEventObs: BehaviorSubject<MapWorkOrderEvent> =
    new BehaviorSubject(null);

  constructor(private http: HttpClient) {
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;
  }

  setMapWorkOrderEventObs(records) {
    this.mapWorkOrderEventObs.next(records);
  }

  getMapWorkOrderEventObs() {
    return this.mapWorkOrderEventObs.asObservable();
  }

  buildMap() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: this.zoom,
      center: [this.lng, this.lat],
    });
  }

  async getMapBoxDirections(
    start: any,
    end: any,
    geoType: string = 'polyline',
  ) {
    if (!start || !end) return;
    let pointA: string = `${start[0]},${start[1]}`;
    let pointB: string = `${end[0]},${end[1]}`;
    const uri = encodeURI(
      `${this.baseUrl}/directions/v5/mapbox/driving/${pointA};${pointB}?radiuses=1000;100&geometries=${geoType}&${this.apiKey}`,
    );
    var resp: any = await this.http.get(uri).toPromise();
    if (!resp.routes || !resp.routes[0]) return;
    let dir: any = {
      distance: resp?.routes[0]?.distance,
      duration: resp?.routes[0]?.duration,
      geometry: resp?.routes[0]?.geometry,
    };
    return dir;
  }

  resize() {
    this.map.resize();
  }

  drawMarker(lng, lat) {
    let marker = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(this.map);
  }

  fitBounds(bounds: any) {
    this.map.fitBounds(bounds);
  }

  getStaticMapMarkerImage(latlng: any, size: any, zoom: number): string {
    var pointA: string = `${latlng.lng},${latlng.lat}`;
    var url: string = `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/pin-s+ff815b(${pointA})/${pointA},${zoom},0/${size.x}x${size.y}?${this.apiKey}`;
    return encodeURI(url);
  }

  async goecode(address: string) {
    const sanitizedAddress = encodeURIComponent(address);
    let resp: any = await this.http
      .get(
        encodeURI(
          `${this.baseUrl}/geocoding/v5/mapbox.places/${sanitizedAddress}.json?&${this.apiKey}`,
        ),
      )
      .toPromise();
    let latlng: ILatLng = {
      lat: resp.features[0].geometry.coordinates[0],
      lng: resp.features[0].geometry.coordinates[1],
    };
    return latlng;
  }

  // async getStaticMapFromAtoB(startAddy: string, endAddy: string) {
  //   let llA: ILatLng = await this.goecode(startAddy);
  //   let llB: ILatLng = await this.goecode(endAddy);
  //   let dir: any = await this.getMapBoxDirections(
  //     [llA.lat, llA.lng],
  //     [llB.lat, llB.lng]
  //   );

  //   return this.staticUrlMapPointAtoB(llA, llB, dir.geometry, {
  //     x: 300,
  //     y: 300,
  //   });
  // }

  async getStaticMapDirectionsFromLatLng(
    fromLatLng: string[],
    toLatLng: string[],
  ) {
    let dir: any = await this.getMapBoxDirections(
      fromLatLng,
      toLatLng,
      'polyline',
    );

    const { geometry } = dir;
    return this.staticUrlMapPointAtoB(fromLatLng, toLatLng, geometry, {
      x: 300,
      y: 300,
    });
  }

  staticUrlMapPointAtoB(
    fromLatLng: string[],
    toLatLng: string[],
    polyline: string,
    size: IStaticMapSize,
  ): string {
    const pointA: string = `${fromLatLng[0]},${fromLatLng[1]}`;
    const pointB: string = `${toLatLng[0]},${toLatLng[1]}`;
    const url: string = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l-car+000(${pointA}),pin-l-home+9ed4bd(${pointB}),path(${encodeURIComponent(
      polyline,
    )})/auto/${size.x}x${size.y}?${this.apiKey}`;
    return url;
  }
}
