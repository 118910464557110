import { gql } from 'apollo-angular';

export const Query_GetWorkOrderChangeLogs = gql`
  query ($search: DtoPaginatedFilter!) {
    getWorkOrderChangeLogs(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          action
          id
          createdAt
          workOrder {
            id
            workOrderNum
          }
          user {
            fullName
            id
            worker {
              id
            }
          }
          newValue
          oldValue
        }
      }
    }
  }
`;
