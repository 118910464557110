import { gql } from 'apollo-angular';

const REPORTING_CODE_FRAGMENT = gql`
  fragment ReportingCodeDetails on ReportingCode {
    id
    name
    description
    type
  }
`;

const SEGMENT_NOTE_FRAGMENT = gql`
  fragment SegmentNoteDetails on SegmentNote {
    canModify
    id
    createdAt
    createdBy
    createdByRef {
      id
      firstname
      lastname
      avatarURL
    }
    updatedAt
    updatedBy
    updatedByRef {
      id
      firstname
      lastname
      avatarURL
    }
    note
    type
    workOrderSegmentId
  }
`;

const WORK_ORDER_SEGMENT_NOTE_CODES_FRAGMENT = gql`
  fragment WorkOrderSegmentNoteCodesDetails on WorkOrderSegmentNoteCodes {
    reportingCodes {
      id
      reportingCodeId
      workOrderSegmentId
      reportingCode {
        ...ReportingCodeDetails
      }
    }
    notes {
      ...SegmentNoteDetails
    }
  }
`;

export const QUERY_GETWORKORDERSEGMENTS = gql`
  ${REPORTING_CODE_FRAGMENT}
  ${SEGMENT_NOTE_FRAGMENT}
  ${WORK_ORDER_SEGMENT_NOTE_CODES_FRAGMENT}

  query ($search: DtoPaginatedFilter!) {
    getWorkOrderSegments(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          createdAt
          workOrderSegmentNum
          subject
          status
          make
          model
          serial
          customerEquipmentNum
          address
          address1
          address2
          city
          state
          zipcode
          country
          lat
          lng
          note_ProblemDesc
          note_Findings
          note_Corrections
          note_Internal
          scheduledStartDateTime
          scheduledEndDateTime
          workOrder {
            id
            workOrderNum
            status
            type
            category
            shipTo {
              id
              name
              customerNum
              address
              lat
              lng
            }
          }
          billTo {
            id
            name
            customerNum
          }
          equipment {
            id
            equipmentNum
            make
            model
          }
          worker {
            id
            displayName
          }
          SegmentNote {
            ...SegmentNoteDetails
          }
          SegmentReportingCode {
            id
            workOrderSegmentId
            reportingCodeId
            reportingCode {
              ...ReportingCodeDetails
            }
          }
          noteComplaint {
            ...WorkOrderSegmentNoteCodesDetails
          }
          noteCause {
            ...WorkOrderSegmentNoteCodesDetails
          }
          noteCorrection {
            ...WorkOrderSegmentNoteCodesDetails
          }
          noteRecommendation {
            ...WorkOrderSegmentNoteCodesDetails
          }
          noteInternal {
            ...WorkOrderSegmentNoteCodesDetails
          }
        }
      }
    }
  }
`;
