import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispatchHubRoutingModule } from './dispatchhub-routing.module';
import {
  NbAccordionModule,
  NbActionsModule,
  NbButtonGroupModule,
  NbButtonModule,
  NbCalendarModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbLayoutModule,
  NbPopoverModule,
  NbSelectModule,
  NbSidebarModule,
  NbTabsetModule,
  NbToggleModule,
} from '@nebular/theme';
import { DispatchHubComponent } from './dispatchhub.component';
import { DispatchHubReplayComponent } from './options/replay/replay.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [DispatchHubComponent, DispatchHubReplayComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbActionsModule,
    NbPopoverModule,
    NbToggleModule,
    NbSelectModule,
    NbButtonModule,
    NbIconModule,
    NbTabsetModule,
    NbCheckboxModule,
    NbCalendarModule,
    SharedModule,
    NbButtonGroupModule,
    NbAccordionModule,
    NbSidebarModule,
    NbLayoutModule,
  ],
  exports: [DispatchHubComponent],
})
export class DispatchHubModule {}
