import { Injectable, signal } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Mutation_AddBlockTimes } from '../graphql/mutations/blocktime/addBlockTimes.mutation';
import { Mutation_DeleteBlockTimes } from '../graphql/mutations/blocktime/deleteBlockTimes.mutation';
import { Query_GetBlockTime } from '../graphql/queries/blocktime/getBlockTime.query';
import { IPaginatedFilter } from '../interfaces/IPaginatedFilter';
import { Query_GetBlockTimes } from '../graphql/queries/blocktime/getBlockTimes.query';
import { Subject, tap } from 'rxjs';
import { Mutation_DeleteServiceVisits } from '../graphql/mutations/servicevisit/deleteServiceVisits.mutation';
import { Mutation_UpdateServiceVisitStatus } from '../graphql/mutations/servicevisit/updateServiceVisitStatus.mutation';

@Injectable({ providedIn: 'root' })
export class SchedulerService {
  constructor(private apollo: Apollo) {}
  public selectedWorkers: any = signal([]);
  public currentSchedulerDateTime: any = signal(new Date());
  private trigger = new Subject<string[] | null>();
  trigger$ = this.trigger.asObservable();
  private serviceVisitDeletedTrigger = new Subject<string[] | null>();
  serviceVisitDeletedTrigger$ = this.serviceVisitDeletedTrigger.asObservable();
  private serviceVisitWorkersDeletedTrigger = new Subject<string[] | null>();
  serviceVisitWorkersDeletedTrigger$ = this.serviceVisitWorkersDeletedTrigger.asObservable();

  async addBlockTimes(dto: any) {
    const workerIds = dto.map((blockTime) => blockTime.workerId);
    await this.apollo
      .mutate({
        mutation: Mutation_AddBlockTimes,
        variables: { dto: dto },
        refetchQueries: [
          {
            query: Query_GetBlockTime,
            variables: { dto: { workerId: workerIds } },
          },
        ],
        awaitRefetchQueries: true,
      })
      .toPromise();
  }

  getBlockTime(workerIds: string[]) {
    return this.apollo.watchQuery({
      query: Query_GetBlockTime,
      fetchPolicy: 'cache-and-network',
      variables: { dto: { workerId: workerIds } },
    }).valueChanges;
  }

  getBlockTimes(search: IPaginatedFilter) {
    return this.apollo.watchQuery({
      query: Query_GetBlockTimes,
      fetchPolicy: 'cache-and-network',
      variables: { search: search },
    }).valueChanges;
  }

  refreshBlockTimes() {
    this.trigger.next(null);
  }

  removeBlockTimesFromScheduler(blockTimeIds: string[]) {
    this.trigger.next(blockTimeIds);
  }

  // updateBlockTime(dto: any) {
  //   return this.apollo.mutate({
  //     fetchPolicy: 'network-only',
  //     mutation: Mutation_AddBlockTime,
  //     variables: { dto },
  //     refetchQueries: [
  //       {
  //         query: Query_GetBlockTime,
  //         variables: { id: dto.id },
  //       },
  //     ],
  //   });
  // }

  deleteBlockTimes(ids: string[]) {
    return this.apollo
      .mutate({
        mutation: Mutation_DeleteBlockTimes,
        variables: { ids },
      })
      .pipe(tap(() => this.removeBlockTimesFromScheduler(ids)));
  }

  deleteServiceVisits(ids: string[]) {
    return this.apollo
      .mutate({
        mutation: Mutation_DeleteServiceVisits,
        variables: { ids },
        update: (cache) => {
          ids.forEach((id) => {
            cache.evict({ id: `ServiceVisit:${id}` });
          });
          cache.gc();
        },
      })
      .pipe(tap(() => this.removeServiceVisitsFromScheduler(ids)));
  }

  cancelServiceVisits(dto: any) {
    return this.apollo
    .mutate({
      mutation: Mutation_UpdateServiceVisitStatus,
      variables: { dto },
      // We can only cancel one service visit at a time
        update: (cache) => {
          cache.evict({ id: `ServiceVisit:${dto.ids}` });
          cache.gc();
        },
      })
      .pipe(tap(() => this.removeServiceVisitsFromScheduler([dto.ids])));
  }

  removeServiceVisitsFromScheduler(serviceVisitIds: string[]) {
    this.serviceVisitDeletedTrigger.next(serviceVisitIds);
  }

  removeServiceVisitWorkersFromScheduler(serviceVisitWorkerIds: string[]) {
    this.serviceVisitWorkersDeletedTrigger.next(serviceVisitWorkerIds);
  }
}
