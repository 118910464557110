import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { NbToastrService } from '@nebular/theme';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MUTATION_ADDUSER } from '../graphql/mutations/user/addUser.mutation';
import { QUERY_GETUSERROLESTYPES } from '../graphql/queries/users/getuserrolestypes.query';
import { QUERY_GETUSERS } from '../graphql/queries/users/getusers.query';
import { User } from '../graphql/types/user.type';

@Injectable({
  providedIn: 'root',
})
export class DispatchHubService {
  private _gridRecords: any = [];
  private _schedulerRecords: any = [];
  //public subject = new Subject<any>();
  private _gridRecordsSource = new BehaviorSubject(this._gridRecords);
  private _schedulerRecordsSource = new BehaviorSubject(this._schedulerRecords);
  gridRecords = this._gridRecordsSource.asObservable();
  schedulerRecords = this._schedulerRecordsSource.asObservable();

  constructor() {} // using Angular Dependency Injection

  updateGridRecords(records: any) {
    this._gridRecordsSource.next(records);
  }

  updateSchedulerRecords(records: any) {
    this._schedulerRecordsSource.next(records);
  }
}
