<nb-card
  [nbSpinner]="loading"
  nbSpinnerStatus="primary"
  nbSpinnerSize="large"
  nbSpinnerMessage=""
  class="popover-card"
>
  <nb-card-body>
    <div>
      <b>{{ customer?.name }}</b>
    </div>
    <span class="subheader">{{ customer?.customerNum }}</span>
    <div class="appearance-body">Add stuff here!</div>

    <div class="appearance-container">
      <button
        nbButton
        size="tiny"
        appearance="ghost"
        status="info"
        [routerLink]="['/pages/customers', this.id]"
      >
        View Customer
      </button>
    </div>
  </nb-card-body>
</nb-card>
