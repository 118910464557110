import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvService } from './env.service';

@NgModule({
  imports: [CommonModule],
  exports: [],
  providers: [EnvService],
})
export class EnvironmentModule {

}

