import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { GridUtilService } from '../../../../@core/services/gridUtil.service';
import { SubSink } from 'subsink';
import { GridApi } from 'ag-grid-community';
import { IPaginatedFilter } from '../../../../@core/interfaces/IPaginatedFilter';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent implements OnInit, OnDestroy {
  sub = new SubSink();

  @Input() height: string;
  @Input() hideToolBar: boolean;
  @Input() gotoDetailOnClick: boolean;
  @Output() onDoubleClicked = new EventEmitter();

  _filter: any;
  @Input() set filter(filter) {
    this._filter = filter;
    if (!filter) return;
    this.filterGrid(filter);
  }
  get filter() {
    return this._filter;
  }

  newOrderLoading: boolean;
  lastRowId: string;
  isLoading: boolean = false;
  orders: any = [];
  gridOptions: any = null;
  gridApi: GridApi;
  columnDefs = [
    {
      headerName: 'First Name',
      field: 'firstname',
      width: 150,
      sort: 'asc',
    },
    {
      headerName: 'Last Name',
      field: 'lastname',
      width: 150,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      width: 170,
    },
    {
      headerName: 'Email',
      field: 'email',
      width: 170,
    },
    {
      headerName: 'Notes',
      field: 'note',
      width: 200,
    },
    {
      headerName: 'Customer #',
      field: 'customer.customerNum',
      width: 100,
    },
    {
      headerName: 'Customer Name',
      field: 'customer.name',
      width: 150,
      // cellRenderer: (params) => {
      //   return params.value + " days";
      // },
    },
  ];

  constructor(
    private customerService: CustomerService,
    private router: Router,
    private gridUtilService: GridUtilService,
    private dialogService: NbDialogService
  ) {
    this.setupGridOptions();
  }

  ngOnInit(): void {}

  async newContact() {}

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  openDialog(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }

  filterGrid(filter) {
    if (!this.gridApi) return;
    let filterModel = {};
    if (filter?.length > 0) {
      filter.forEach((filter: any) => {
        filterModel[filter.column] = {
          filter: filter.value,
          filterType: 'Text',
          type: 'contains',
        };
      });
    }
    this.gridApi.setFilterModel(filterModel);
    this.gridApi.onFilterChanged();
  }

  viewDetail(customer: any) {
    const { id } = customer.data;
    if (!id) return;
    this.router.navigate(['/pages/equipment/', id]);
  }

  setupGridOptions() {
    if (this.gridOptions) return;
    const _this = this;
    this.gridOptions = {
      columnDefs: this.columnDefs,
      rowSelection: 'single',
      toolbar: true,
      onRowDoubleClicked: (row) => {
        this.onDoubleClicked.emit(row.data);
        if (this.gotoDetailOnClick) this.viewDetail(row);
      },
      onGridReady: (e) => {
        _this.gridApi = e.api;
      },
      datasource: this.gridDS(),
      enableSorting: true,
      enableServerSideFilter: true,
      rowModelType: 'infinite',
      filter: true,
      paginationAutoPageSize: true,
      infiniteInitialRowCount: 1,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        suppressMenu: true,
        suppressFilterButton: true,
        filterParams: { buttons: ['reset'], debounceMs: 800 },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          debounceMs: 200,
        },
      },
    };
  }

  gridDS() {
    let me = this;
    return {
      rowCount: null,
      getRows: function (params) {
        if (me.gridApi) {
          let filter = me.gridUtilService.normalizeGridFilter(
            params.filterModel
          );
          const orderBy = me.gridUtilService.normalizeGridSort(
            params.sortModel
          );
          const { startRow } = params;

          if (me?.filter?.length > 0) {
            filter = [...filter, ...me.filter];
          }

          const search: IPaginatedFilter = {
            first: 200,
            after: params.startRow ? me.lastRowId : null,
            filter,
            orderBy,
          };

          me.gridApi.showLoadingOverlay();
          me.sub.sink = me.customerService
            .getContacts(search)
            .subscribe(({ error, loading, data }) => {
              const rtn = (<any>data).getContacts;
              const { totalCount } = (<any>data)?.getContacts;
              if (loading) {
                this.isLoading = loading;
                return;
              }

              if (rtn.length == 0) {
                me.gridApi.showNoRowsOverlay();
              }

              const objs = [];
              rtn.edges.forEach(({ node }) => {
                objs.push({ ...node });
              });
              me.lastRowId = objs[objs.length - 1]?.id;
              params.successCallback(objs, totalCount);
              me.gridApi.hideOverlay();
            });
        }
      },
    };
  }
}
