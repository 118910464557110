import { gql } from 'apollo-angular';

export const Mutation_UpdateWorkOrderEstTravel = gql`
  mutation updateWorkOrderEstTravel($dto: DtoUpdateWorkOrderEstTravelTime!) {
    updateWorkOrderEstTravel(dto: $dto) {
      id
      estTravelTime
      estTravelDistance
      estTravelHash
      estTravelFromLatLng
    }
  }
`;
