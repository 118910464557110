import { gql } from 'apollo-angular';

export const QUERY_GetActivityCodes = gql`
  query {
    getActivityCodes {
      id
      code
      description
      type
    }
  }
`;
