import { gql } from 'apollo-angular';

export const MUTATION_AddAttachment = gql`
  mutation addAttachment($data: DtoAddAttachment!) {
    addAttachment(data: $data) {
      id
      preSignedURL
    }
  }
`;
