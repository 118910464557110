import { Injectable } from '@angular/core';
import { FormsApiService } from './forms-api.service';
import { DtoFillServiceVisitSummaryOfWork } from '../interfaces/DtoFillServiceVisitSummaryOfWork';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  constructor(private formApi: FormsApiService) {}

  fillForm_ServiceVisitSummaryOfWork(
    dto: DtoFillServiceVisitSummaryOfWork,
  ): any {
    return this.formApi.fillForm_ServiceVisitSummaryOfWork(dto);
  }
}
