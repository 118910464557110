import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Mutation_DeleteWorkersFromServiceVisit } from '../graphql/mutations/servicevisit/deleteWorkersFromServiceVisit.mutation';
import { Mutation_AddWorkersToServiceVisit } from '../graphql/mutations/servicevisit/addWorkersToServiceVisit.mutation';
import { Query_GetServiceVisitById } from '../graphql/queries/service-visit/getServiceVisitById';
import { Mutation_UpdateServiceVisitStatus } from '../graphql/mutations/servicevisit/updateServiceVisitStatus.mutation';
import { Mutation_DeleteServiceVisits } from '../graphql/mutations/servicevisit/deleteServiceVisits.mutation';
import { Query_GetScheduledServiceVisits } from '../graphql/queries/service-visit/getServiceVisitByWorkerIds';
import { IPaginatedFilter } from '../interfaces/IPaginatedFilter';
import { Query_GetServiceVisits } from '../graphql/queries/service-visit/getServiceVisits.query';
import { Query_GetServiceVisitWorkers } from '../graphql/queries/service-visit/getServiceVisitWorkers.query';
import { Query_GetCustomerSignatureByServiceVisitWorker } from '../graphql/queries/customerSignature/getCustomerSignaturesByServiceVisitWorker';
import { Query_GetCustomerSignatureByServiceVisitId } from '../graphql/queries/customerSignature/getCustomerSignaturesByServiceVisitId.query';
import { NbWindowService } from '@nebular/theme';
import { ServiceVisitDetailComponent } from '../../pages/service-visit/pages/service-visit-detail/service-visit-detail.component';
import { WindowService } from './window.service';
import { SchedulerService } from './scheduler.service';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceVisitService {
  constructor(
    private apollo: Apollo,
    private windowService: WindowService,
    private schedulerService: SchedulerService,
  ) {}

  addWorkersToServiceVisit(serviceVisitId: string, workerNums: string[]) {
    return this.apollo.mutate({
      mutation: Mutation_AddWorkersToServiceVisit,
      variables: { serviceVisitId, workerNums },
      refetchQueries: [
        {
          query: Query_GetServiceVisitById,
          variables: { id: serviceVisitId },
        },
      ],
    });
  }

  getCustomerSignatureByServiceVisitWorker(dto: any) {
    return this.apollo.watchQuery({
      query: Query_GetCustomerSignatureByServiceVisitWorker,
      variables: { dto },
    });
  }

  getCustomerSignatureByServiceVisitId(serviceVisitId: any) {
    return this.apollo.watchQuery({
      query: Query_GetCustomerSignatureByServiceVisitId,
      fetchPolicy: 'network-only',
      variables: { serviceVisitId },
    });
  }

  deleteWorkersFromServiceVisit(ids: string[]) {
    return this.apollo
      .mutate({
        mutation: Mutation_DeleteWorkersFromServiceVisit,
        variables: { ids },
        update: (cache, { data }) => {
          const deletedWorkerIds = (data as any).deleteWorkersFromServiceVisit;
          deletedWorkerIds.forEach((workerId: string) => {
            cache.evict({ id: `ServiceVisitWorker:${workerId}` });
          });
          cache.gc();
        },
      })
      .pipe(
        tap(() => {
          this.schedulerService.removeServiceVisitWorkersFromScheduler(ids);
        }),
      );
  }

  updateServiceVisitStatus(dto: any) {
    return this.apollo.mutate({
      mutation: Mutation_UpdateServiceVisitStatus,
      variables: { dto },
    });
  }

  deleteServiceVisits(ids: string[], workerIds, startDate, endDate) {
    return this.apollo
      .mutate({
        mutation: Mutation_DeleteServiceVisits,
        variables: { ids },
        refetchQueries: [
          {
            query: Query_GetScheduledServiceVisits,
            variables: { data: { workerIds, startDate, endDate } },
          },
        ],
        awaitRefetchQueries: true,
      })
      .toPromise();
  }

  getServiceVisits(search: IPaginatedFilter) {
    return this.apollo.watchQuery({
      query: Query_GetServiceVisits,
      variables: { search: search },
    });
  }

  getServiceVisitWorkers(search: IPaginatedFilter) {
    return this.apollo.watchQuery({
      query: Query_GetServiceVisitWorkers,
      variables: { search: search },
    });
  }

  showServiceVisitDetail(id: string) {
    if (!id) return;

    this.windowService.openWindow(ServiceVisitDetailComponent, {
      objectId: id,
      context: { id },
    });
  }
}
