import { AgGridModule } from 'ag-grid-angular';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbAccordionModule,
  NbButtonModule,
  NbCardModule,
  NbContextMenuModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbPopoverModule,
  NbSelectModule,
  NbTabsetModule,
} from '@nebular/theme';

import { DynamicListViewComponent } from './dynamiclistview.component';
import { GridQueueModule } from '../grid-queues/grid-queue.module';
import { AngJsoneditorModule } from '@maaxgr/ang-jsoneditor';
import { DraggableCellRenderer } from '../cellrenderers/CellRenderShowInScheduler.component';
import { ButtonRendererComponent } from './cellRenderers/buttonCellRenderer';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { GridMenuComponent } from './components/grid-menu/grid-menu.component';
import { GridMenuButtonComponent } from './components/grid-menu-button/grid-menu-button.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@NgModule({
  declarations: [
    DynamicListViewComponent,
    DraggableCellRenderer,
    ButtonRendererComponent,
    GridMenuComponent,
    GridMenuButtonComponent,
  ],
  exports: [
    DynamicListViewComponent,
    GridMenuComponent,
    GridMenuButtonComponent,
  ],
  imports: [
    NbCardModule,
    AgGridModule,
    NbButtonModule,
    NbIconModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GridQueueModule,
    NbTabsetModule,
    NbInputModule,
    NbPopoverModule,
    NbAccordionModule,
    NbDialogModule,
    NbContextMenuModule,
    AngJsoneditorModule,
    NzMenuModule,
    NzDropDownModule,
    NzToolTipModule,
    NbSelectModule,
    NzPopoverModule,
  ],
  providers: [DraggableCellRenderer, ButtonRendererComponent],
})
export class DynamicListViewModule {}
