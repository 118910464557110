import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbButtonModule, NbCardModule, NbIconModule } from '@nebular/theme';
import { AgGridModule } from 'ag-grid-angular';
import { EquipmentlistComponent } from './equipmentlist.component';
import { DynamicListViewModule } from '../../dynamiclistview/dynamiclistview.module';

@NgModule({
  declarations: [EquipmentlistComponent],
  exports: [EquipmentlistComponent],
  imports: [
    CommonModule,
    NbCardModule,
    AgGridModule,
    NbButtonModule,
    NbIconModule,
    DynamicListViewModule,
  ],
})
export class EquipmentListModule {}
