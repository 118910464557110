import { gql } from 'apollo-angular';

export const QUERY_GETCUSTOMERTYPES = gql`
  query {
    __type(name: "CustomerType") {
      enumValues {
        name
      }
    }
  }
`;
