import { gql } from 'apollo-angular';

export const Query_GetIntegrationLogs = gql`
  query ($search: DtoPaginatedFilter!) {
    getIntegrationLogs(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          createdAt
          acknowledgedAt
          type
          action
          payload
          response
          isError
          reportingObjectId
          apiHost
          fromHost
          retryCount
          markAsReviewed
        }
      }
    }
  }
`;
