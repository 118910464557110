import { gql } from 'apollo-angular';

export const QUERY_GETTEAMSBYIDS = gql`
  query ($teamIds: String!, $branchNum: String!) {
    getTeamsByIds(teamIds: $teamIds, branchNum: $branchNum) {
      id
      name
      workers {
        id
        displayName
        workerNum
        location
        team
        startDayFromLat
        startDayFromLng
        user {
          email
          avatarURL
          id
        }
      }
    }
  }
`;
