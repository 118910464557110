<form [formGroup]="form" autocomplete="off">
  <div class="row">
    <div class="col-lg-12">
      <nb-tabset>
        <nb-tab tabTitle="Address">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <!-- <label for="address" class="label">Address Search</label> -->
                <input
                  type="text"
                  status="primary"
                  fullWidth
                  class="form-control"
                  (keydown.enter)="$event.preventDefault()"
                  placeholder="Search Address"
                  autocomplete="chrome-off"
                  autocomplete="off"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="off"
                  type="text"
                  #search
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="address1" class="label">Address Line 1</label>
                <input
                  type="text"
                  nbInput
                  fullWidth
                  id="address1"
                  formControlName="address1"
                  autocomplete="off"
                  fieldSize="small"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="address2" class="label">Address Line 2</label>
                <input
                  type="text"
                  nbInput
                  fullWidth
                  id="address2"
                  formControlName="address2"
                  autocomplete="off"
                  fieldSize="small"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5">
              <div class="form-group">
                <label for="city" class="label">City</label>
                <input
                  type="text"
                  nbInput
                  fullWidth
                  id="city"
                  autocomplete="off"
                  formControlName="city"
                  fieldSize="small"
                />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="form-group">
                <label for="state" class="label">State</label>
                <nb-select
                  fullWidth
                  formControlName="state"
                  autocomplete="off"
                  size="small"
                  id="state"
                >
                  <nb-option
                    *ngFor="let state of states | keyvalue"
                    [value]="state.value"
                  >
                    {{ state.value }}
                  </nb-option>
                </nb-select>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label for="zipcode" class="label">Zipcode</label>
                <input
                  type="text"
                  nbInput
                  fullWidth
                  id="zipcode"
                  autocomplete="off"
                  formControlName="zipcode"
                  fieldSize="small"
                />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="form-group">
                <label for="country" class="label">Country</label>
                <input
                  type="text"
                  nbInput
                  fullWidth
                  id="country"
                  autocomplete="off"
                  formControlName="country"
                  fieldSize="small"
                />
              </div>
            </div>
          </div>
        </nb-tab>
        <nb-tab tabTitle="Map">
          <div class="row">
            <!-- <agm-map #AgmMap [latitude]="lat" [longitude]="lng" [zoom]="12">
                            <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"
                                (dragEnd)="markerDragEnd($event)">
                            </agm-marker>
                        </agm-map> -->
          </div>
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-3">
              <div class="form-group">
                <label for="lat" class="label">Latitude</label>
                <input
                  type="text"
                  nbInput
                  fullWidth
                  id="lat"
                  autocomplete="off"
                  formControlName="lat"
                  fieldSize="small"
                />
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label for="lng" class="label">Longitude</label>
                <input
                  type="text"
                  nbInput
                  fullWidth
                  id="lng"
                  autocomplete="off"
                  formControlName="lng"
                  fieldSize="small"
                />
              </div>
            </div>
          </div>
        </nb-tab>
      </nb-tabset>
    </div>
  </div>
</form>
