import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbPopoverModule,
  NbRadioModule,
  NbRouteTabsetModule,
  NbSelectModule,
  NbTabsetModule,
  NbTagModule,
  NbTimepickerModule,
  NbTreeGridModule,
  NbUserModule,
  NbToggleModule,
  NB_TIME_PICKER_CONFIG,
  NbFormFieldModule,
} from '@nebular/theme';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { WorkOrderSegmentComponent } from './workordersegment.component';
import { WorkOrderSegmentDetailComponent } from './detail/workordersegmentdetail.component';
import { WorkOrderSegmentCreateComponent } from './create/createworkordersegment.component';
import { EquipmentInputModule } from '../../shared/component/equipment/input/equipment-input.module';
import { EquipmentListModule } from '../../shared/component/equipment/list/equipment-list.module';
import { CustomerInputModule } from '../../shared/component/customers/input/customer-input.module';
import { DynamicListViewModule } from '../../shared/component/dynamiclistview/dynamiclistview.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ChecklistDetailComponent } from '../checklist/components/checklist-detail/checklist-detail.component';

@NgModule({
  declarations: [
    WorkOrderSegmentComponent,
    WorkOrderSegmentDetailComponent,
    WorkOrderSegmentCreateComponent,
  ],
  exports: [WorkOrderSegmentDetailComponent],
  imports: [
    CommonModule,
    SharedModule,
    NbAccordionModule,
    NbActionsModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbDialogModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbListModule,
    NbPopoverModule,
    NbRadioModule,
    NbRouteTabsetModule,
    NbSelectModule,
    NbTabsetModule,
    NbTagModule,
    NbTreeGridModule,
    NbUserModule,
    NbTimepickerModule,
    NbDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    EquipmentListModule,
    EquipmentInputModule,
    CustomerInputModule,
    NzCollapseModule,
    NzSwitchModule,
    NbToggleModule,
    DynamicListViewModule,
    NzToolTipModule,
    NbFormFieldModule,
  ],
})
export class WorkOrderSegmentModule {}
