import { gql } from 'apollo-angular';

export const Query_GetEquipmentModels = gql`
  query ($search: DtoPaginatedFilter!) {
    getEquipmentModels(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          make
          model
          group
          category
          description
          imageUrl
          factoryLeadTimeDays
          countInStock
        }
      }
    }
  }
`;
