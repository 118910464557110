import { gql } from 'apollo-angular';

export const QUERY_GetFabActivityCodes = gql`
  query {
    getFabActivityCodes {
      id
      code
      description
      type
      fabEnabled
      fabLabel
      fabColor
      fabIcon
      fabOrder
    }
  }
`;
