import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Mutation_UpdateColumnLayoutByObjectType } from '../../../@core/graphql/mutations/objectfieldmetadata/updateColumnLayoutByObjectType.mutation copy';
import { Query_GetObjectColumnLayout } from '../../../@core/graphql/queries/objectmetadata/getObjectColumnLayout.query';
import { DtoGetObjectColumnLayout } from './dto/dtoGetObjectColumnLayout.dto';
import { Mutation_ResetColumnLayoutByObjectType } from '../../../@core/graphql/mutations/objectfieldmetadata/resetObjectColumnLayoutByObjectType.mutation';
import { DtoResetColumnLayoutByObjectType } from './dto/dtoResetColumnLayoutByObjectType.dto';

@Injectable({
  providedIn: 'root',
})
export class DynamicListViewService {
  constructor(private apollo: Apollo) {}

  getObjectColumnLayout(dto: DtoGetObjectColumnLayout) {
    return this.apollo.query({
      query: Query_GetObjectColumnLayout,
      variables: { dto },
      fetchPolicy: 'cache-first',
    });
  }

  updateObjectColumnLayout(dto: any) {
    return this.apollo.mutate({
      mutation: Mutation_UpdateColumnLayoutByObjectType,
      variables: { dto },
    });
  }

  resetObjectColumnLayout(dto: DtoResetColumnLayoutByObjectType) {
    return this.apollo.mutate({
      mutation: Mutation_ResetColumnLayoutByObjectType,
      variables: { dto },
    }).toPromise();
  }
}
