import { gql } from 'apollo-angular';

export const Query_GetBlockTime = gql`
  query getBlockTime($dto: DtoGetBlockTime!) {
    getBlockTime(dto: $dto) {
      id
      scheduledStartDateTime
      scheduledEndDateTime
      workerId
      reason
      description
    }
  }
`;
