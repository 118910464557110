import {
  ChangeDetectorRef,
  Component,
  Injectable,
  Input,
  NgZone,
  OnInit,
} from '@angular/core';
import { NbCalendarDayCellComponent, NbDateService } from '@nebular/theme';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'nb-calendar-custom-day-cell',
  templateUrl: './dayCellComponent.component.html',
  styleUrls: ['./dayCellComponent.component.scss'],
  standalone: true,
})
export class CalendarCustomDayCellComponent extends NbCalendarDayCellComponent<Date> {
  sub = new SubSink();
  selectedValue: Date;
  selectedBool: boolean;
  serviceVisits: any;
  color: string = 'lightgray';
  get day(): number {
    return this.date.getDate();
  }
  @Input() set day(val: number) {
    this.date.setDate(val);
  }

  set selected(val: boolean) {
    this.selectedValue = val ? this.date : null;
  }
  get selected(): boolean {
    // return !!this.selectedBool;
    return !!this.selectedValue;
  }

  constructor(
    dateService: NbDateService<Date>,
    private _dayCellService: dayCellService,
    private cdr: ChangeDetectorRef,
  ) {
    super(dateService);
  }
  ngOnInit() {
    this.day = this.date.getDate();
    this.selected = this.selectedValue === this.date;

    let tt = this._dayCellService.serviceVisits$.subscribe((data) => {
      this.serviceVisits = data;
      //this.date to date contains any service visit scheduled start date are the same selected date
      if (this.serviceVisits) {
        const matchingSv = this.serviceVisits.find((element) => {
          return (
            new Date(
              element?.scheduledStartDateTime || element,
            ).toLocaleDateString() === new Date(this.date).toLocaleDateString()
          );
        });

        if (matchingSv) {
          if (
            moment(this.date).startOf('day').isBefore(moment()) &&
            !matchingSv.id
          ) {
            this.selected = false;
          } else {
            this.selected = true;
          }
          if (matchingSv?.status !== 'Planned') {
            this.setBackgroundColor('#lightgray');
          }
          if (matchingSv?.toBeDeleted) {
            this.setBackgroundColor('#ff3d71');
          } else {
            if (matchingSv?.id) {
              this.setBackgroundColor('#598bff');
            } else {
              this.setBackgroundColor('#2ce69b');
            }
          }
        }
      }
    });

    setTimeout(() => {
      tt.unsubscribe();
    }, 200);
  }

  ngOnDestory() {
    this.sub.unsubscribe();
  }

  setBackgroundColor(color) {
    this.color = color;
  }
}

@Injectable({
  providedIn: 'root',
})
export class dayCellService {
  private serviceVisits = new BehaviorSubject<any>(null);
  serviceVisits$ = this.serviceVisits.asObservable();

  constructor() {}

  changeData(data: any) {
    this.serviceVisits.next(data);
  }
}
