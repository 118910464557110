import { gql } from 'apollo-angular';

export const Mutation_UpdateServiceVisitAssignment = gql`
  mutation updateServiceVisitAssignment(
    $dto: DtoUpdateServiceVisitAssignment!
  ) {
    updateServiceVisitAssignment(dto: $dto) {
      id
      scheduledStartDateTime
      scheduledEndDateTime
      serviceVisitWorkers {
        id
        status
        worker {
          id
          workerNum
          displayName
        }
      }
    }
  }
`;
