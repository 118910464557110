<nb-card class="mt-3">
  <div class="flex">
    <grid-menu-button
      [button]="button"
      *ngFor="let button of gridMenuButtons"
      (buttonAction)="emitButtonAction($event)"
    >
    </grid-menu-button>
  </div>
</nb-card>
