import { gql } from 'apollo-angular';

export const QUERY_GETCUSTOMERS = gql`
  query ($search: DtoPaginatedFilter!) {
    getCustomers(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          customerNum
          id
          name
          type
          address
          address1
          address2
          city
          state
          zipcode
          country
          lat
          lng
          createdAt
          updatedAt
          billTo {
            customerNum
            name
            id
            address
          }
        }
      }
    }
  }
`;
