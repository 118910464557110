import { gql } from 'apollo-angular';
import { Fragment_Order } from '../../fragments/order.fragment';

export const Query_GetOrders = gql`
  query ($search: DtoPaginatedFilter!) {
    getOrders(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          ...OrderFragment
        }
      }
    }
  }
  ${Fragment_Order}
`;
