import { gql } from 'apollo-angular';
import { Fragment_Order } from '../../fragments/order.fragment';

export const Query_GetQueuesByObject = gql`
  query ($dto: DtoGetQueuesByObject!) {
    getQueuesByObjectType(dto: $dto) {
      id
      name
      sort
      type
      filter
      badgeLogic
    }
  }
`;
