import { gql } from "apollo-angular";

export const Query_GetWorkOrderFieldGroupByCount = gql`
  query ($fields: [String!]!, $search: DtoPaginatedFilter!) {
    getWorkOrderFieldGroupByCount(fields: $fields, search: $search) {
      field
      values {
        value
        count
      }
    }
  }
`;
