import { gql } from 'apollo-angular';
import { Fragment_Order } from '../../fragments/order.fragment';

export const Query_GetOrderLines = gql`
  query ($search: DtoPaginatedFilter!) {
    getOrderLines(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          qty
          orderNum
          lineNum
          isParent
          orderRef {
            orderNum
            status
            id
            shipTo {
              id
              name
              customerNum
              address
              address1
              address2
              city
              state
              lat
              lng
              zipcode
            }
            billTo {
              id
              name
              customerNum
              address
              address1
              address2
              city
              state
              zipcode
            }
          }
          unitAmount
          optionsAmount
          baseAmount
          downPaymentAmount
          childLinesAmount
          lineType
          description
          lineAmount
          priceEffectiveDate
          itemNum
          externalQuoteNumber
          notes
          parentLine {
            itemNum
          }
          model {
            imageUrl
            id
            model
            group
            category
            make
          }
          equipment {
            id
            equipmentNum
            serial
            make
            model
          }
          options {
            description
            qty
            salePrice
            purchPrice
            notes
          }
        }
      }
    }
  }
`;
