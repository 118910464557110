<div class="header-container" *ngIf="!hideTopToolbar">
  <span class="flex-expand"></span>
  <button nbButton hero size="small" status="primary" (click)="addCustomer()">
    <nb-icon icon="plus-outline"></nb-icon>Add Customer
  </button>
</div>
<nb-card>
  <nb-card-body>
    <!-- <next-object-list [height]="size"></next-object-list> -->

    <!-- <ag-grid-angular
      style="width: 100%"
      style.height="{{ size || 800 }}px"
      [rowData]="customers"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      class="ag-theme-balham"
    >
    </ag-grid-angular> -->
  </nb-card-body>
</nb-card>
