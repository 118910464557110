<div *ngIf="workorder">
  <div class="header-container">
    <div>
      <h4>
        {{ workorder?.workOrderNum }} <span>&bull;</span> {{ workorder?.type }}
      </h4>
    </div>
    <span class="flex-expand"></span>
    <div *ngIf="false">
      <button
        nbButton
        hero
        size="small"
        status="primary"
        (click)="updateWorkOrder()"
      >
        <nb-icon icon="save-outline"></nb-icon>Save Workorder
      </button>
    </div>
  </div>
  <nb-tabset (changeTab)="onTabChange($event)">
    <nb-tab
      tabTitle="Details"
      tabIcon="clipboard-outline"
      [active]="activeTab === 'Details' || !activeTab"
    >
      <!--<nb-card>
        <nb-card-body>-->
      <form [formGroup]="form" autocomplete="off">
        <nb-accordion multi #woAccordion accordion-shadow="false">
          <nb-accordion-item
            #custItem
            (collapsedChange)="onCustCollapseChange($event)"
            [collapsed]="custClosed()"
          >
            <nb-accordion-item-header>
              <div class="item-container">
                <div>Customer Details</div>
                <div class="item-tags" *ngIf="custItem.expanded">
                  <button
                    nbButton
                    size="tiny"
                    status="primary"
                    ghost
                    (click)="toggleCustShowMore($event)"
                  >
                    {{ custShowMore() ? 'Show Less' : 'Show More' }}
                  </button>
                  &nbsp;
                </div>
                <div class="item-tags" *ngIf="custItem.collapsed">
                  <nb-tag size="tiny" [text]="shipTo.customerNum"> </nb-tag>
                  <nb-tag size="tiny" [text]="shipTo.name"> </nb-tag>
                  <nb-tag size="tiny" [text]="billTo.customerNum"> </nb-tag>
                  <nb-tag size="tiny" [text]="billTo.name"> </nb-tag>
                </div></div
            ></nb-accordion-item-header>
            <nb-accordion-item-body>
              <nb-card class="sv-detail-card">
                <nb-card-body>
                  <div class="row">
                    <div class="col">
                      <div class="row mb-2">
                        <div
                          class="col-4 leaders"
                          style="vertical-align: middle"
                        >
                          <label class="label">Ship To </label>
                        </div>
                        <div class="col">
                          <next-customerinput
                            #shipToNum
                            [customer]="shipTo"
                            fieldSize="small"
                            (onCustomerChange)="shipToChange($event)"
                          ></next-customerinput>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-4 leaders">
                          <span>
                            <label class="label"> Ship To Address </label>
                          </span>
                          <span></span>
                        </div>
                        <div class="col">
                          <textarea
                            nbInput
                            fullWidth
                            id="address"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="address"
                            rows="3"
                            readonly
                          ></textarea>
                        </div>
                      </div>

                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label class="label"> TM </label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="tm"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>

                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label class="label"> CSS </label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="css"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>

                      <div class="row mb-2" [hidden]="!custShowMore()">
                        <div class="col-4 leaders">
                          <label class="label"> Contact Name </label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="contact"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="contactName"
                            readonly
                          />
                        </div>
                      </div>

                      <div class="row mb-2" [hidden]="!custShowMore()">
                        <div class="col-4 leaders">
                          <label class="label"> Contact Phone </label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="contact"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="contactPhone"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!custShowMore()">
                        <div class="col-4 leaders">
                          <label class="label"> Contact Email </label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="contact"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="contactEmail"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label class="label">Bill To</label>
                        </div>
                        <div class="col">
                          <next-customerinput
                            #shipToNum
                            [customer]="billTo"
                            fieldSize="small"
                            (onCustomerChange)="billToChange($event)"
                          ></next-customerinput>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-4 leaders">
                          <label class="label">
                            Bill To Address
                            <!-- <nb-icon
                              icon="map-outline"
                              status="primary"
                              style="font-size: 1.2em"
                              class="ml-1"
                            >
                            </nb-icon> -->
                          </label>
                        </div>
                        <div class="col">
                          <textarea
                            nbInput
                            fullWidth
                            id="address"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="address"
                            rows="3"
                            readonly
                          ></textarea>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label class="label">Customer PO</label>
                        </div>
                        <div class="col d-flex">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="poNumber"
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label class="label"> Payment Terms </label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="paymentTerms"
                            autocomplete="off"
                            fieldSize="small"
                            [value]="billTo?.paymentTerms"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!custShowMore()">
                        <div class="col-4 leaders">
                          <label class="label"> Authorizer Name </label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="contact"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="contactName"
                            readonly
                          />
                        </div>
                      </div>

                      <div class="row mb-2" [hidden]="!custShowMore()">
                        <div class="col-4 leaders">
                          <label class="label"> Authorizer Phone </label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="contact"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="contactPhone"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!custShowMore()">
                        <div class="col-4 leaders">
                          <label class="label"> Authorizer Email </label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="contact"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="contactEmail"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </nb-card-body>
              </nb-card>
            </nb-accordion-item-body>
          </nb-accordion-item>

          <nb-accordion-item
            (collapsedChange)="onWODCollapseChange($event)"
            [collapsed]="woDetailsClosed()"
          >
            <nb-accordion-item-header>
              <div class="item-container">
                <div>Work Order Details</div>
                <div class="item-tags" *ngIf="woDetailsClosed()">
                  <nb-tag
                    size="tiny"
                    *ngIf="this.workorder.worker"
                    text="{{
                      this.workorder.worker.displayName | slice: 0 : 15
                    }}"
                  >
                  </nb-tag>
                  <nb-tag
                    size="tiny"
                    *ngIf="this.workorder.type"
                    text="{{ this.workorder.type }}"
                  >
                  </nb-tag>
                  <nb-tag
                    size="tiny"
                    *ngIf="this.workorder.category"
                    text="{{ this.workorder.category }}"
                  >
                  </nb-tag>
                  <nb-tag
                    size="tiny"
                    *ngIf="this.workorder.status"
                    text="{{ this.workorder.status }}"
                  >
                  </nb-tag>
                </div>
                <div class="item-tags" *ngIf="!woDetailsClosed()">
                  <button
                    nbButton
                    size="tiny"
                    status="primary"
                    ghost
                    (click)="toggleWOShowMore($event)"
                  >
                    {{ woShowMore() ? 'Show Less' : 'Show More' }}
                  </button>
                  &nbsp;
                </div>
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              <nb-card>
                <nb-card-body>
                  <div class="row">
                    <div class="col">
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label class="label">Work Order #</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="workOrdernum"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="workOrderNum"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label class="label">Work Order Status</label>
                        </div>
                        <div class="col">
                          <nb-select
                            fullWidth
                            size="small"
                            formControlName="status"
                            [(selected)]="selectedStatus"
                            (selectedChange)="onStatusChange($event)"
                            [nbSpinner]="isLoading('status')"
                            nbSpinnerStatus="primary"
                            nbSpinnerSize="small"
                          >
                            @for (status of statusList; track $index) {
                              <nb-option [value]="status.status">
                                {{ status.status }}
                              </nb-option>
                            }
                          </nb-select>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label class="label">Service Type</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="type"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="type"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label class="label">Service Category</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="branch"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="category"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!woShowMore()">
                        <div class="col-4 leaders">
                          <label class="label">Closed</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="branch"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="isClosed"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!woShowMore()">
                        <div class="col-4 leaders">
                          <label class="label">Branch</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="branch"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="branchNum"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!woShowMore()">
                        <div class="col-4 leaders">
                          <label class="label">Department</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="department"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="department"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!woShowMore()">
                        <div class="col-4 leaders">
                          <label class="label">Schedule Start</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="scheduledStartDateTime"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="scheduledStartDateTime"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!woShowMore()">
                        <div class="col-4 leaders">
                          <label class="label">Schedule End</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="scheduledEndDateTime"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="scheduledEndDateTime"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label class="label">Priority</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="branch"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="priority"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label class="label">Resource</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="resource"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="workerNames"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label class="label">Subject</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="subject"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="subject"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label class="label">Service Description</label>
                        </div>
                        <div class="col">
                          <textarea
                            style="height: 142px"
                            type="text"
                            nbInput
                            fullWidth
                            id="subject"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="serviceDescription"
                            readonly
                          ></textarea>
                        </div>
                      </div>

                      <div class="row mb-2" [hidden]="!woShowMore()">
                        <div class="col-4 leaders">
                          <label class="label">Preferred Resource </label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="preferredresource"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label class="label">Service Zone</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="servicezone"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!woShowMore()">
                        <div class="col-4 leaders">
                          <label class="label">Last Labor Date</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="lastlabordate"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="lastLabor"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!woShowMore()">
                        <div class="col-4 leaders">
                          <label class="label">Hours Posted</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="hoursposted"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!woShowMore()">
                        <div class="col-4 leaders">
                          <label class="label">Created</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="created"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="createdAt"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!woShowMore()">
                        <div class="col-4 leaders">
                          <label class="label">Created By</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="createdby"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="createdBy"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!woShowMore()">
                        <div class="col-4 leaders">
                          <label class="label">Modified</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="modified"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="updatedAt"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!woShowMore()">
                        <div class="col-4 leaders">
                          <label class="label">Modified By</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="modifiedby"
                            autocomplete="off"
                            fieldSize="small"
                            formControlName="updatedBy"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </nb-card-body>
              </nb-card>
            </nb-accordion-item-body>
          </nb-accordion-item>

          <nb-accordion-item
            (collapsedChange)="onEquipCollapseChange($event)"
            [collapsed]="equipClosed()"
          >
            <nb-accordion-item-header>
              <div class="item-container">
                <div>Equipment</div>
                <div class="item-tags" *ngIf="!equipClosed()">
                  <button
                    nbButton
                    size="tiny"
                    status="primary"
                    ghost
                    (click)="toggleEquipShowMore($event)"
                  >
                    {{ equipShowMore() ? 'Show Less' : 'Show More' }}
                  </button>
                  &nbsp;
                </div>
                <div class="item-tags" *ngIf="equipClosed()">
                  <nb-tag
                    size="tiny"
                    *ngIf="equipment?.equipmentNum"
                    text="{{ this.equipment.equipmentNum }}"
                  >
                  </nb-tag>
                  <nb-tag
                    size="tiny"
                    *ngIf="equipment?.make"
                    text="{{ this.equipment.make }}"
                  >
                  </nb-tag>
                  <nb-tag
                    size="tiny"
                    *ngIf="serviceVisit?.status"
                    text="{{ serviceVisit?.status }}"
                  >
                  </nb-tag>
                  <nb-tag
                    size="tiny"
                    *ngIf="equipment?.model"
                    text="{{ this.equipment.model }}"
                  >
                  </nb-tag>
                  <nb-tag
                    size="tiny"
                    *ngIf="form?.value?.serial"
                    text="{{ this.form.value.serial }}"
                  >
                  </nb-tag>
                  <!--Looks like somebody intended to add in the equipment fleet type?-->
                  <!--<button nbButton size="tiny">Fleet Type</button> -->
                </div>
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              <nb-card>
                <nb-card-body>
                  <div class="row">
                    <div class="col">
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label for="model" class="label">Equipment </label>
                        </div>
                        <div class="col">
                          <next-equipmentinput
                            [equipment]="equipment"
                            (onEquipmentChange)="equipmentChange($event)"
                          >
                          </next-equipmentinput>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label for="customerEquipmentNum" class="label"
                            >Fleet No.</label
                          >
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="fleetCode"
                            [value]="equipment?.fleetCode"
                            fieldSize="small"
                            autocomplete="off"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label for="fleettype" class="label"
                            >Fleet Type</label
                          >
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="fleetType"
                            autocomplete="off"
                            fieldSize="small"
                            [value]="equipment?.fleetType"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!equipShowMore()">
                        <div class="col-4 leaders">
                          <label for="warrantycode" class="label"
                            >Warranty Code</label
                          >
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="warrantyCode"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!equipShowMore()">
                        <div class="col-4 leaders">
                          <label for="warrantystart" class="label"
                            >Warranty Start</label
                          >
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="warrantyStart"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!equipShowMore()">
                        <div class="col-4 leaders">
                          <label for="warrantyend" class="label"
                            >Warranty End</label
                          >
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="warrantyEnd"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label for="serial" class="label">Serial</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="serial"
                            formControlName="serial"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label for="make" class="label">Make</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="make"
                            formControlName="make"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-4 leaders">
                          <label for="model" class="label">Model</label>
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="model"
                            formControlName="model"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!equipShowMore()">
                        <div class="col-4 leaders">
                          <label for="hourmeter1" class="label"
                            >Hour Meter 1</label
                          >
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="hourmeter1"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!equipShowMore()">
                        <div class="col-4 leaders">
                          <label for="hourmeter2" class="label"
                            >Hour Meter 2</label
                          >
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="hourmeter2"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!equipShowMore()">
                        <div class="col-4 leaders">
                          <label for="hourmeter3" class="label"
                            >Hour Meter 3</label
                          >
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="hourmeter3"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!equipShowMore()">
                        <div class="col-4 leaders">
                          <label for="hourmeter4" class="label"
                            >Hour Meter 4</label
                          >
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            nbInput
                            fullWidth
                            id="hourmeter4"
                            autocomplete="off"
                            fieldSize="small"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </nb-card-body>
              </nb-card>
            </nb-accordion-item-body>
          </nb-accordion-item>

          <hr />

          <!-- <nb-accordion-item #addyItem (collapsedChange)="onAddyCollapseChange($event)">
            <nb-accordion-item-header><div class="item-container">
              <div>Address</div>
              
              <div>
                <button nbButton *ngIf="addyClosed()">checking</button>
                <button nbButton *ngIf="addyClosed()">checking</button>
                <button nbButton *ngIf="addyClosed()">checking</button>
                <button nbButton *ngIf="addyClosed()">checking</button>
              </div>
              </div>
              </nb-accordion-item-header>
            <next-address [form]="form"></next-address>
          </nb-accordion-item> -->
        </nb-accordion>
      </form>
      <!--</nb-card-body>
      </nb-card>-->
    </nb-tab>
    <nb-tab
      tabTitle="Segments"
      tabIcon="list-outline"
      [active]="activeTab == 'Segments'"
    >
      <next-workordersegmentdetail
        *ngIf="workorder"
        [workOrder]="workorder"
        showList="true"
        [orFilters]="[
          {
            column: 'workOrder.id',
            value: workorder.id,
          },
        ]"
      ></next-workordersegmentdetail>
    </nb-tab>
    <nb-tab
      tabTitle="Service Visits"
      tabIcon="calendar-outline"
      [active]="activeTab == 'Service Visits'"
    >
      <next-object-list
        id="serviceVisitList"
        object="Service_Visit"
        [selectFirstRow]="true"
        (onSelect)="selectServiceVisit($event)"
        [hideToolBar]="true"
        height="200"
        [filters]="[
          {
            column: 'serviceVisitWorkOrders.workOrderId',
            value: this.workorder.id,
          },
        ]"
      ></next-object-list>
      <br />
      <nb-card>
        <nb-card-body>
          <app-service-visit-detail
            *ngIf="selectedServiceVisit?.id"
            [id]="selectedServiceVisit?.id"
            [workOrderNum]="workorder?.workOrderNum"
          ></app-service-visit-detail>
        </nb-card-body>
      </nb-card>
    </nb-tab>
    <nb-tab
      tabTitle="Resources"
      tabIcon="calendar-outline"
      [active]="activeTab == 'Resources'"
    >
      <div class="list-header-bar">
        <button
          nbButton
          ghost
          size="small"
          status="primary"
          [nbPopover]="workerListPopover"
          class="list-header-icon"
        >
          <i class="fa-light fa-plus mr-2"></i>
          Add
        </button>
        <button
          nbButton
          ghost
          status="danger"
          size="small"
          (click)="removeWorkersFromWorkOrder()"
        >
          <i class="fa-light fa-trash mr-2"></i>
          <span class="list-header-icon">Delete</span>
        </button>
      </div>
      <next-object-list
        #workerList
        *ngIf="workorder"
        id="workOrderWorkerList"
        object="Work_Order_Worker"
        [hideControlBar]="false"
        [hideToolBar]="true"
        height="200"
        [filters]="[
          {
            column: 'workOrderId',
            value: this.workorder.id,
          },
        ]"
        (onSelect)="onSelectRemoveWorkersFromWorkOrder($event)"
      ></next-object-list>
      <br />
    </nb-tab>
    <nb-tab
      tabTitle="Attachments"
      tabIcon="attach-outline"
      [active]="activeTab === 'Attachments'"
    >
      <next-attachment
        #attachments
        [subject]="workorder?.workOrderNum"
        *ngIf="activeTab === 'Attachments'"
        object="WorkOrder"
        [refId]="workorder?.id"
      ></next-attachment>
    </nb-tab>
    <nb-tab
      tabTitle="Checklists"
      tabIcon="checkmark-square-outline"
      [active]="activeTab === 'Checklists'"
    >
      <next-checklist-detail
        *ngIf="workorder"
        [workOrderId]="this.workorder.id"
      ></next-checklist-detail>
    </nb-tab>
  </nb-tabset>
</div>
<ng-template #workerListPopover let-data>
  <nb-card
    class="margin-bottom-0"
    style="background-color: transparent"
    style="width: 700px"
  >
    <nb-card-body class="p-0">
      <div class="d-flex padding-popover">
        <div class="my-auto padded">
          <b>Add Technicians to Work Order</b>
        </div>

        <button
          class="ml-auto mr-2"
          nbButton
          [status]="'success'"
          [disabled]="!selectedWorkersToAdd?.length"
          size="small"
          (click)="addWorkersToWorkOrder()"
        >
          <nb-icon icon="plus-outline"></nb-icon>
          {{
            selectedWorkersToAdd?.length
              ? selectedWorkersToAdd?.length + ' Workers'
              : 'Add'
          }}
        </button>
        <button
          class="margin-buttons"
          nbButton
          [status]="'danger'"
          size="small"
          (click)="closePopover()"
        >
          <nb-icon icon="close-outline"> Close </nb-icon>
        </button>
      </div>
      <next-object-list
        object="Worker"
        [height]="300"
        [hideToolBar]="true"
        [hideQueues]="true"
        (onSelect)="onSelectAddWorkers($event)"
      ></next-object-list>
    </nb-card-body>
  </nb-card>
</ng-template>
