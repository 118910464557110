import { gql } from 'apollo-angular';

export const Query_GetContacts = gql`
  query ($search: DtoPaginatedFilter!) {
    getContacts(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          firstname
          lastname
          phone
          email
          updatedAt
          notes
          category
          contactNum
          customer {
            id
            name
            address
            customerNum
          }
        }
      }
    }
  }
`;
