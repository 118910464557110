import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { WorkOrderComponent } from './workorder.component';
import { WorkOrderDetailComponent } from './detail/workorderdetail.component';
import { WorkOrderExternalDetailComponent } from './external-detail/workorderexternal.component';

const routes: Routes = [
  {
    path: '',
    component: WorkOrderComponent,
  },
  // {
  //   path: "create",
  //   component: WorkOrderCreateComponent,
  //},
  {
    path: 'external/:id',
    component: WorkOrderExternalDetailComponent,
  },
  {
    path: ':id',
    component: WorkOrderDetailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WorkOrdertRoutingModule {}
