import { gql } from 'apollo-angular';

export const MUTATION_REFRESHTOKEN = gql`
  mutation refreshToken($token: String!) {
    refreshToken(token: $token) {
      accessToken
      refreshToken
    }
  }
`;
