import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { DynamicListViewModule } from '../../../../shared/component/dynamiclistview/dynamiclistview.module';
import {
  NbBadgeModule,
  NbCardModule,
  NbIconModule,
  NbTabsetModule,
} from '@nebular/theme';
import { AttachmentsModule } from '../../../attachments/attachments.module';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { AttachmentComponent } from '../../../attachments/components/attachment/attachment.component';
import { WindowService } from 'packages/stack-web/src/app/@core/services/window.service';
import { AttachmentImageComponent } from '../../../attachments/components/attachment-image/attachment-image.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    DynamicListViewModule,
    NbCardModule,
    NbIconModule,
    NbBadgeModule,
    NbTabsetModule,
    AttachmentsModule,
    NbBadgeModule,
    NzAvatarModule,
  ],
  selector: 'next-checklist-detail',
  templateUrl: './checklist-detail.component.html',
  styleUrls: ['./checklist-detail.component.scss'],
})
export class ChecklistDetailComponent {
  @Input() workOrderId: string;
  @Input() equipmentId: string;
  @ViewChild('attachmentComponent') attachmentComponent: AttachmentComponent;
  checklist: any;
  groups: any;
  groupEntries: any = [];
  attachments;
  private lineMap: Map<string, any> = new Map();

  constructor(private windowService: WindowService) {}

  selectChecklist(checklist) {
    if (!checklist) {
      this.checklist = null;
      return;
    }
    //if checklist is array then get first

    this.checklist = checklist[0];
    this.lineMap = new Map();
    this.groupEntries = [];
    this.buildLineMap(this.checklist.json);
    this.groupChecklistItems();
  }

  displayImage(attachment) {
    this.attachmentComponent.openDialog(
      this.attachmentComponent.zoomDialog,
      attachment,
    );

    // this...openWindow(AttachmentImageComponent, {
    //   objectId: attachment.id,
    //   options: {
    //     title: 'Attachment - ' + attachment.fileName,
    //     windowClass: 'null',
    //   },
    //   context: {
    //     attachment,
    //   },
    // });
  }

  groupChecklistItems() {
    this.groups = {};
    this.lineMap?.forEach((lineObject: any, key: string) => {
      let status = lineObject.status || 'Not Completed';
      if (lineObject.informational || lineObject.type === 'input') {
        status = 'Detail';
      }
      if (!this.groups[status]) {
        this.groups[status] = [];
      }
      if (lineObject.type === 'multimeter') {
        const lines = lineObject.value.map((line: any) => {
          return {
            ...line,
            notes: line.note,
            label: 'Cell ' + line.cellId,
            id: 'cell-' + line.cellId,
            value: `${line.voltage || 0.0}v / ${line.gravity || 0.0}`,
          };
        });
        this.groups[status].push(...lines);
      } else {
        this.groups[status].push(lineObject);
      }
    });

    this.groupEntries = Object.entries(this.groups)
      .map(([name, items]) => ({ name, items }))
      .sort(
        (a, b) => this.getGroupPriority(a.name) - this.getGroupPriority(b.name),
      );
  }

  lineAttachments(lineId: string) {
    return this.attachments?.filter(
      (attachment: any) => attachment?.refId2 === lineId,
    );
  }

  getIconName(status: string): string {
    switch (status) {
      case 'Passed':
        return 'checkmark-circle';
      case 'Needs Attention':
        return 'alert-circle';
      case 'Critical':
        return 'close-circle';
      default:
        return 'question-mark-circle-outline';
    }
  }

  getIconColor(status: string): string {
    switch (status) {
      case 'Passed':
        return 'success';
      case 'Needs Attention':
        return 'warning';
      case 'Critical':
        return 'danger';
      default:
        return 'medium';
    }
  }

  getGroupPriority(groupName: string): number {
    const priorities = {
      'Immediate Action': 1,
      'Future Attention': 2,
      Detail: 3,
      Passed: 4,
      'Not Completed': 5,
    };
    return priorities[groupName as keyof typeof priorities] ?? 6;
  }

  buildLineMap(obj: any) {
    obj?.pages?.forEach((page: any) => {
      page?.groups.forEach((group: any) => {
        group?.lines.forEach((line: any) => {
          this.lineMap.set(line.id, line);
        });
      });
    });
  }
}
