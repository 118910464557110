import { gql } from 'apollo-angular';

export const QUERY_GetTimeSheetById = gql`
  query ($id: String!) {
    getTimeSheetById(id: $id) {
      id
      hours
      mins
      isOpen
      isPosted
      externalId
      note
      mapURL
      manuallyEntered
      lat
      lng
      worker {
        displayName
        id
        workerNum
        user {
          id
          avatarURL
        }
      }
      serviceVisitRef {
        id
        serviceVisitNum
        status
        customer {
          name
          id
          customerNum
        }
      }
      workOrderSegment {
        id
        workOrderSegmentNum
        workOrder {
          id
          make
          model
          serial
          workOrderNum
          type
          status
          shipTo {
            name
            customerNum
          }
        }
      }
      createdAt
      updatedAt
      startDateTime
      endDateTime
      qty
      activityCodeId
      activityCode {
        id
        code
        type
        fabColor
        fabIcon
        label
        showAsBlockedInScheduler
        showInSchedulerTimeline
      }
    }
  }
`;
