import { gql } from 'apollo-angular';

export const Mutation_UpdateServiceVisit = gql`
  mutation updateServiceVisit($dto: DtoUpdateServiceVisit!) {
    updateServiceVisit(dto: $dto) {
      id
      serviceVisitNum
      address
      address1
      address2
      city
      category
      state
      country
      lat
      lng
      scheduledStartDateTime
      scheduledEndDateTime
      estTravelTime
      estTravelDistance
      estTravelFromLatLng
      estTravelPolyline
      contact {
        id
        contactNum
        firstname
        lastname
        email
        phone
        category
      }
      customer {
        id
        name
      }
      # serviceVisitWorkers {
      #   workerId
      #   worker {
      #     displayName
      #     user {
      #       id
      #       email
      #       avatarURL
      #     }
      #   }
      # }
      # serviceVisitWorkOrders {
      #   workOrderNum
      #   workOrder {
      #     subject
      #   }
      # }
    }
  }
`;
