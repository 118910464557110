import { gql } from 'apollo-angular';

export const Query_GetQueues = gql`
  query ($search: DtoPaginatedFilter!) {
    getQueues(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          object
          name
          page
          sort
          type
          filter
          orFilter
          badgeLogic
          userId
          user {
            id
            email
            avatarURL
            fullName
          }
          userRoleRef {
            id
            name
          }
          userRoleId
        }
      }
    }
  }
`;
