import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import {
  ApolloClientOptions,
  InMemoryCache,
  ApolloLink,
  fromPromise,
  ApolloClient,
} from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { MUTATION_REFRESHTOKEN } from './@core/graphql/mutations/refreshtoken.mutation';
import { Router } from '@angular/router';
import { TokenService } from './@core/services/token.service';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { OperationDefinitionNode } from 'graphql';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { EnvService } from '@environment';
import { environment } from '../environments/environment';
import { WebSocketLink } from '@apollo/client/link/ws';

//const uri = "http://162.243.161.180:3000/graphql";
//const uri = "http://localhost:3000/graphql";

export function createApollo(
  httpLink: HttpLink,
  tokenService: TokenService,
  router: Router,
  //sharedEnv: EnvService
): ApolloClientOptions<any> {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  }));

  const auth = setContext((operation, context) => {
    const token: any = tokenService.token;
    if ((<any>operation).query.definitions[0].operation === 'subscription') {
      return {
        connectionParams: () => {
          const token: any = tokenService.token;
          return {
            headers: {
              authorization: `BEARER ${token?.accessToken || ''}`,
            },
          };
        },
      };
    }
    return {
      headers: {
        Authorization: `BEARER ${token?.accessToken || ''}`,
      },
    };
  });

  let isRefreshing = false;
  let pendingRequests = [];

  const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback());
    pendingRequests = [];
  };

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          switch (err?.extensions?.code) {
            case 'UNAUTHENTICATED':
              // eslint-disable-next-line no-case-declarations
              let forward$: Observable<any>;
              if (!isRefreshing) {
                isRefreshing = true;
                const oldToken = tokenService.token;
                forward$ = fromPromise(
                  client
                    .mutate({
                      mutation: MUTATION_REFRESHTOKEN,
                      variables: { token: oldToken?.refreshToken },
                    })
                    .then(({ data }) => {
                      const accessToken = <any>data;
                      tokenService.setToken(accessToken?.refreshToken);
                      const headers = operation.getContext();
                      resolvePendingRequests();
                      return accessToken;
                    })
                    .catch((error) => {
                      pendingRequests = [];
                      router.navigate(['/auth/login']);
                      // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                      return;
                    })
                    .finally(() => {
                      isRefreshing = false;
                    }),
                ).filter((value) => Boolean(value));
              } else {
                // Will only emit once the Promise is resolved
                forward$ = fromPromise(
                  new Promise((resolve) => {
                    pendingRequests.push(() => resolve(null));
                  }),
                );
              }

              return forward$.flatMap(() => forward(operation));
          }
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${JSON.stringify(networkError)}`);
        // if you would also like to retry automatically on
        // network errors, we recommend that you use
        // apollo-link-retry
      }
    },
  );

  const http = httpLink.create({
    uri: environment.graphQL,
  });
  const token = tokenService.token;

  // Create a WebSocket link:
  const ws = new WebSocketLink({
    uri: environment.graphQLWS,
    options: {
      reconnect: true,
      connectionParams: () => {
        const token: any = tokenService.token;
        return {
          authorization: `BEARER ${token?.accessToken || ''}`,
        };
      },
    },
  });

  // const ws = new GraphQLWsLink(
  //   createClient({
  //     url: environment.graphQLWS,
  //     lazy: true,
  //     on: {
  //       opened: (socket: any) => {
  //         const obj = {
  //           payload: { Authorization: `BEARER ${token?.accessToken || ''}` },
  //           type: 'connection_init',
  //         };
  //         socket.send(JSON.stringify(obj));
  //       },
  //     },
  //     retryWait: async function waitForServerHealthyBeforeRetry() {
  //       await new Promise((resolve) =>
  //         setTimeout(resolve, 1000 + Math.random() * 9000),
  //       );
  //     },
  //     connectionParams: () => {
  //       const token: any = tokenService.token;
  //       return {
  //         headers: {
  //           authorization: `BEARER ${token?.accessToken || ''}`,
  //         },
  //       };
  //     },
  //   }),
  // );

  const link = ApolloLink.from([
    errorLink,
    ApolloLink.concat(
      auth,
      ApolloLink.split(
        // split based on operation type
        ({ query }) => {
          const { kind, operation } = <OperationDefinitionNode>(
            getMainDefinition(query)
          );
          return kind === 'OperationDefinition' && operation === 'subscription';
        },
        ws,
        http,
      ),
      //http,
    ),
  ]);

  const cache = new InMemoryCache();
  const client = new ApolloClient({
    link,
    cache,
  });

  return {
    link,
    cache,
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, TokenService, Router],
    },
    EnvService,
  ],
})
export class GraphQLModule {}
