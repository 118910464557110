import { gql } from 'apollo-angular';

export const MUTATION_ADDWORKORDERSEGMENT = gql`
  mutation addWorkOrderSegment($data: DtoAddWorkOrderSegment!) {
    addWorkOrderSegment(data: $data) {
      id
      workOrderSegmentNum
    }
  }
`;
