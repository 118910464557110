import { gql } from 'apollo-angular';

export const Mutation_AddWorkOrderToServiceVisit = gql`
  mutation addWorkOrderToServiceVisit($dto: DtoAddWorkOrdersToServiceVisit!) {
    addWorkOrderToServiceVisit(dto: $dto) {
      id
      serviceVisitNum
      status
      address
      address1
      address2
      city
      state
      country
      lat
      lng
      mapURL
      scheduledStartDateTime
      scheduledEndDateTime
      serviceVisitWorkOrders {
        workOrder {
          workOrderNum
          subject
          status
          type
          workOrderWorkers {
            id
            worker {
              id
              workerNum
            }
          }
        }
      }
    }
  }
`;
