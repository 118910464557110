import { gql } from 'apollo-angular';

export const QUERY_GETCUSTOMERSBILLTOSEARCH = gql`
  query ($search: String!) {
    getCustomersBillToSearch(search: $search) {
      id
      name
      customerNum
    }
  }
`;
