import { gql } from 'apollo-angular';

export const Query_GetWorkers = gql`
  query ($search: DtoPaginatedFilter!) {
    getWorkers(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          displayName
          workerNum
          location
          branch
          team
          user {
            email
            avatarURL
          }
        }
      }
    }
  }
`;
