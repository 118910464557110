import { gql } from 'apollo-angular';

export const Query_GetSessionMessages = gql`
  query ($sessionId: String!) {
    getSessionMessages(sessionId: $sessionId) {
      message
      id
      createdAt
      fromUser {
        id
        fullName
      }
    }
  }
`;
