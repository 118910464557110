import { gql } from 'apollo-angular';

export const QUERY_GetActivityCodesPaginated = gql`
  query ($search: DtoPaginatedFilter!) {
    getActivityCodesPaginated(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          code
          description
          type
          visibleOnMobile
          defaultWork
          defaultTravel
          defaultPayroll
          disabled
          fabEnabled
          fabLabel
          fabColor
          fabIcon
          fabOrder
          updateWorkOrderStatus
          workOrderStatus {
            id
            status
          }
          forceStatusUpdate
          timeAllowedToCancelNotification
          onStartServiceVisitStatusRef {
            id
            status
          }
          onStartServiceVisitStatus
          onEndServiceVisitStatusRef {
            id
            status
          }
          onEndServiceVisitStatus
          useChargeLogic
          chargeLogicAlternativeActivityCodeRef {
            id
            code
          }
          chargeLogicAlternativeActivityCode
          otActivityCodeActivityCodes {
            id
            code
          }
          isWorkingOT
          isWorking
          isTravel
          isFieldActivityCode
          isShopActivityCode
          showOnServiceVisit
          label
          showAsBlockedInScheduler
          showInSchedulerTimeline
          visibleToWorker
          billable
          otActivityCode
          workOrderStatusId
          chargeLogicActivityCode
        }
      }
    }
  }
`;
