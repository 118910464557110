import { gql } from 'apollo-angular';

export const Query_GetWorkOrderWorkers = gql`
  query ($search: DtoPaginatedFilter!) {
    getWorkOrderWorkers(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          workOrderId
          workerId
          createdAt
          createdBy
          worker {
            displayName
            workerNum
            id
            user {
              id
              email
              avatarURL
            }
          }
        }
      }
    }
  }
`;
