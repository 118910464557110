import { gql } from 'apollo-angular';

export const QUERY_GETWORKORDER = gql`
  query ($id: String!) {
    getWorkOrder(id: $id) {
      id
      type
      createdAt
      createdBy
      updatedAt
      updatedBy
      category
      department
      status
      createdAt
      updatedAt
      workOrderNum
      serial
      make
      model
      address
      address1
      address2
      city
      state
      zipcode
      country
      lat
      lng
      subject
      serviceDescription
      contactName
      contactEmail
      contactPhone
      scheduledStartDateTime
      scheduledEndDateTime
      isPastDue
      isMissed
      isClosed
      mapURL
      poType
      rescheduleCount
      pastDueCount
      missedCount
      estTravelTime
      estTravelDistance
      estTravelHash
      estTravelFromLatLng
      locationNum
      branchNum
      departmentNum
      externalId
      workerId
      pastdue
      priority
      note_problemDesc
      note_Findings
      note_Corrections
      note_internal
      duration
      poNumber
      customerEquipmentNum
      equipmentId
      lastLabor
      isMachineDown
      workerNames
      location {
        id
        locationNum
      }
      worker {
        id
        displayName
        user {
          avatarURL
        }
      }
      shipTo {
        id
        name
        customerNum
        paymentTerms
      }
      billTo {
        id
        name
        customerNum
        paymentTerms
      }
      equipment {
        id
        equipmentNum
        make
        model
        fleetType
        fleetCode
      }
    }
  }
`;
