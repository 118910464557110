<div>
  <nb-form-field>
    <button
      nbPrefix
      nbButton
      ghost
      *ngIf="customer && !locked && showPrefix"
      type="button"
      (click)="gotoCustomer()"
      class="customerSearch"
    >
      <nb-icon icon="arrow-circle-right-outline"> </nb-icon>
    </button>
    <input
      #searchInput
      nbInput
      fullWidth
      status="primary"
      [fieldSize]="fieldSize"
      class="customerInput"
      [readonly]="locked"
      [value]="name"
      [nbPopover]="locked ? null : list"
      [nbPopoverPlacement]="'bottom'"
      (nbPopoverShowStateChange)="onPopOverStateChange($event)"
      (keyup)="onKeyUp($event)"
      (click)="onFocus($event)"
      (blur)="onBlur($event)"
      readonly
    />
    <button nbSuffix nbButton ghost *ngIf="!locked" type="button">
      <nb-icon icon="search-outline"> </nb-icon>
    </button>
  </nb-form-field>
</div>

<ng-template #list>
  <nb-card class="margin-bottom-0">
    <next-object-list
      [object]="'Customer'"
      [height]="400"
      [hideQueues]="true"
      [hideToolBar]="true"
      [filters]="filters"
      [orFilters]="orFilters"
      [page]="page"
      style="width: 1000px"
      (onDoubleClick)="selected($event)"
    >
    </next-object-list>
    <!-- <next-customerlist
      (onDoubleClicked)="selected($event)"
      [hideTopToolbar]="true"
      [size]="400"
      style="width: 1000px"
    ></next-customerlist> -->
  </nb-card>
</ng-template>
