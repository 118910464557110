import { Injectable } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import {
  NbGlobalPhysicalPosition,
  NbToastrService,
  NbWindowService,
  NbWindowState,
} from '@nebular/theme';
import { Apollo } from 'apollo-angular';
import { debounce, debounceTime, filter, map, tap } from 'rxjs/operators';
import { ChatComponent } from '../../../shared/component/chat/chat.component';
import { Query_GetMyMessagingSessions } from '../../graphql/queries/messaging/getMySessions.query';
import { DtoSendMessage } from '../../interfaces/DtoSendMessage';
import { TokenService } from '../token.service';

import { MessagingApiService } from './messaging-api.service';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  messageSub;
  hideNotifications: boolean = false; //TODO Someone say STATE STORE>??>>>?>?>?>?>?> maybe a record in a db.
  constructor(
    private api: MessagingApiService,
    private toastController: NbToastrService,
    private apollo: Apollo,
    private router: Router,
    private tokenService: TokenService,
    private windowService: NbWindowService,
  ) {}

  showSessionWindow(session) {
    const wRef = this.windowService.open(ChatComponent, {
      initialState: NbWindowState.MAXIMIZED,
      windowClass: 'chat-window',
      title: session?.teamName
        ? `${session?.teamName} (Dispatch)`
        : session?.recipentUser?.fullName,
      buttons: {
        close: true,
        fullScreen: false,
        minimize: true,
        maximize: false,
      },
    });
    wRef.componentInstance.session = session;
  }

  // async newMessageModal(
  //   toUser,
  //   toTeamId,
  //   pageEl,
  //   attachmentType: DomainObject = null,
  //   attachmentRefId: string = null
  // ) {
  //   const modal = await this.modalController.create({
  //     component: NewMessagePage,
  //     swipeToClose: true,
  //     presentingElement: pageEl,
  //     componentProps: {
  //       toUser,
  //       attachmentType,
  //       attachmentRefId,
  //     },
  //   });
  //   modal.present();
  // }

  hideSession(sessionId: string) {
    return this.api.hideSession(sessionId);
  }

  getSession(sessionId) {
    return this.api.getSession(sessionId);
  }

  subscribeToMessageAdded() {
    console.log('SUBSCRIBING');
    return this.api.subscriptionMessageAdded().pipe(
      map(({ data }) => (<any>data).messageAdded),
      tap(async (message) => {
        console.log('SUBSCRITION MESSAGE', message);
        if (!message) return;
        this.showNewMessageNotification(message);
        this.updateMessageCache(message);
      }),
    );
  }

  newDirectMessage(toUserId, name) {
    let session = this.checkForUserSession(toUserId);
    if (!session) {
      session = {
        type: 'Direct',
        recipentUser: {
          fullName: name,
          id: toUserId,
        },
      };
    }
    this.showSessionWindow(session);
  }

  checkForUserSession(userId) {
    let rtnSession = null;
    const sessions = <any>this.apollo.client.readQuery({
      query: Query_GetMyMessagingSessions,
    });

    sessions?.getMySessions?.forEach((session) => {
      if (session?.recipentUser?.id === userId) {
        rtnSession = session;
      }
    });

    return rtnSession;
  }

  async checkAndAddSession(sessionId) {
    const { getMySessions } = <any>this.apollo.client.readQuery({
      query: Query_GetMyMessagingSessions,
    });
    let foundSession: boolean = false;
    getMySessions?.forEach((session) => {
      if (session.sessionId === sessionId) {
        foundSession = true;
      }
    });

    if (foundSession) {
      return sessionId;
    }
    const session = await this.api.getSession(sessionId).toPromise();
    console.log(session);
    const newSession = (<any>session)?.data?.getSession;
    this.apollo.client.cache.writeQuery({
      query: Query_GetMyMessagingSessions,
      data: { getMySessions: [...getMySessions, newSession] },
    });
  }

  async updateMessageCache(message) {
    const { getMySessions } = <any>this.apollo.client.readQuery({
      query: Query_GetMyMessagingSessions,
    });
    let foundSession: boolean = false;
    const newSessions = getMySessions?.map((session) => {
      if (session.sessionId === message.sessionId) {
        foundSession = true;
        return {
          ...session,
          unreadMessageCount: (session.unreadMessageCount || 0) + 1,
          lastMessageRef: message,
          messages: [...session.messages, message],
        };
      } else {
        return session;
      }
    });

    if (foundSession) {
      this.apollo.client.cache.writeQuery({
        query: Query_GetMyMessagingSessions,
        data: { getMySessions: newSessions },
      });
    } else {
      console.log('SESSION NOT FOUND');
    }
  }

  async showNewMessageNotification(message) {
    const title = `${message?.fromUser?.firstname?.trim() || ''} ${
      message?.fromUser?.lastname?.trim() || ''
    }`;
    this.toastController.show(message?.message, title, {
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      status: 'primary',
      duration: 3000,
    });
  }

  setLastMessageRead(sessionId: string, messageId: string) {
    return this.api
      .setLastMessageRead(sessionId, messageId)
      .pipe(debounceTime(3000)); // of().pipe(
  }

  getMySessions(fetchPolicy: any = 'cache-and-network') {
    return this.api
      .getMySessions(fetchPolicy)
      .pipe(map(({ data }) => (<any>data)?.getMySessions));
  }

  getSessionMessages(sessionId: string) {
    return this.api
      .getSessionMessages(sessionId)
      .pipe(map(({ data }) => (<any>data)?.getSessionMessages));
  }

  sendMessage(dto: DtoSendMessage) {
    return this.api.sendMessage(dto);
  }
}
