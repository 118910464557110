import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayContains',
  pure: false,
})
export class ArrayContainsPipe implements PipeTransform {
  transform(value: string, items: any[], column: string): any {
    if (!value || !items) {
      return 0;
    }
    if (!column) {
      return (
        items.filter(
          (item) => item.toLowerCase().indexOf(value.toLowerCase()) !== -1,
        ).length > 0
      );
    }
    return (
      items.filter(
        (item) =>
          item[column].toLowerCase().indexOf(value.toLowerCase()) !== -1,
      ).length > 0
    );
  }
}
