import { gql } from 'apollo-angular';

export const Mutation_AddQueue = gql`
  mutation addQueue($dto: DtoAddQueue!) {
    addQueue(dto: $dto) {
      id
      type
      name
      filter
      sort
    }
  }
`;
