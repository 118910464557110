import { gql } from 'apollo-angular';

export const QUERY_GETOBJECTS = gql`
  query ($search: DtoPaginatedFilter!) {
    getObjects(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
          apiName
          createdAt
          createdBy
          isCustom
          object
        }
      }
    }
  }
`;
