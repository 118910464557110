import { gql } from "apollo-angular";

export const Mutation_UpdateSegmentNote = gql`
  mutation UpdateSegmentNote ($data: UpdateSegmentNote!) {
    updateSegmentNote(data: $data) {
      id
      canModify
      note
      type
      createdAt
      createdBy
      createdByRef {
        id
        firstname
        lastname
        avatarURL
      }
      updatedAt
      updatedBy
      updatedByRef {
        id
        firstname
        lastname
        avatarURL
      }
      workOrderSegmentId
    }
  }
`;
