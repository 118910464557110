import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceVisitDetailComponent } from './service-visit-detail.component';
import { WorkOrderSegmentModule } from '../../../workordersegment/workordersegment.module';
import {
  NbButtonModule,
  NbCardModule,
  NbDatepickerModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbPopoverModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTooltipModule,
  NbSelectModule,
  NbTableModule,
  NbTabsetModule,
  NbAccordionModule,
  NbTagModule,
  NbToggleModule,
  NbWindowModule,
} from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../../shared/shared.module';
import { CustomerInputModule } from '../../../../shared/component/customers/input/customer-input.module';
import { DynamicListViewModule } from '../../../../shared/component/dynamiclistview/dynamiclistview.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { AttachmentsModule } from '../../../attachments/attachments.module';
import { DispatchHubModule } from '../../../dispatchhub/dispatchhub.module';
import { WorkOrderModule } from '../../../workorder/workorder.module';
import { ContactInputModule } from '../../../customer/components/contact-input/contact-input.module';
import { ServiceVisitRecurringCreateComponent } from '../../components/sv-recurring-create/sv-recurring-create.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NbButtonModule,
    CustomerInputModule,
    NbCardModule,
    NbInputModule,
    NbDatepickerModule,
    NbSpinnerModule,
    NbIconModule,
    NbPopoverModule,
    NzDropDownModule,
    NbTooltipModule,
    NbTagModule,
    NzToolTipModule,
    NzStepsModule,
    DynamicListViewModule,
    NbSelectModule,
    NbTabsetModule,
    NbAccordionModule,
    AttachmentsModule,
    NbToggleModule,
    DispatchHubModule,
    WorkOrderSegmentModule,
    ContactInputModule,
    NzPopoverModule,
    ServiceVisitRecurringCreateComponent,
    NzModalModule,
    NbSpinnerModule,
    NbWindowModule,
    CustomerInputModule,
  ],

  declarations: [ServiceVisitDetailComponent],
  exports: [ServiceVisitDetailComponent],
})
export class ServiceVisitDetailModule {}
