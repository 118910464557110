import { gql } from 'apollo-angular';

export const Query_GetMyMessagingSessions = gql`
  query {
    getMySessions {
      id
      type
      sessionId
      lastReadMessageId
      teamName
      unreadMessageCount
      messages {
        message
        id
        createdAt
        fromUser {
          id
          fullName
          avatarURL
        }
      }
      recipentUser {
        fullName
        id
        avatarURL
      }
      lastMessageRef {
        message
        createdAt
        id
      }
    }
  }
`;
