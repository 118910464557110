import { gql } from 'apollo-angular';

export const QUERY_GETWORKORDERSEGMENT = gql`
  query ($id: String!) {
    getWorkOrderSegment(id: $id) {
      id
      workOrderSegmentNum
      subject
      status
      make
      model
      serial
      customerEquipmentNum
      address
      address1
      address2
      city
      state
      zipcode
      country
      lat
      lng
      note_ProblemDesc
      note_Findings
      note_Corrections
      note_Internal
      scheduledStartDateTime
      scheduledEndDateTime
      billTo {
        id
        name
        customerNum
      }
      equipment {
        id
        equipmentNum
        make
        model
      }
      worker {
        id
        displayName
      }
      SegmentNote {
        type
        note
      }
      SegmentReportingCode {
        reportingCodeId
        reportingCode {
          type
          name
          description
        }
      }
    }
  }
`;
