import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbButtonModule,
  NbCardModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbPopoverModule,
} from '@nebular/theme';
import { AgGridModule } from 'ag-grid-angular';
import { ContactListModule } from '../contact-list/contact-list.module';
import { DynamicListViewModule } from '../../../../shared/component/dynamiclistview/dynamiclistview.module';
import { ContactInputComponent } from './contact-input.component';

@NgModule({
  declarations: [ContactInputComponent],
  exports: [ContactInputComponent],
  imports: [
    CommonModule,
    NbCardModule,
    AgGridModule,
    NbPopoverModule,
    NbFormFieldModule,
    NbInputModule,
    NbButtonModule,
    NbIconModule,
    NbButtonModule,
    ContactListModule,
    DynamicListViewModule,
  ],
})
export class ContactInputModule {}
