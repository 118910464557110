import { gql } from 'apollo-angular';

export const Mutation_DeleteBlockTimes = gql`
  mutation deleteBlockTimes($ids: [String!]!) {
    deleteBlockTimes(ids: $ids) {
      id
      workerId
      scheduledStartDateTime
      scheduledEndDateTime
      reason
      description
    }
  }
`;
