import { gql } from 'apollo-angular';

export const Query_GetUserRolesPaginated = gql`
  query GetUserRolesPaginated($search: DtoPaginatedFilter!) {
    getUserRoles(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
          createdAt
          updatedAt
          userRolePermissions {
            id
            permission {
              id
              permission
              description
            }
          }
        }
      }
    }
  }
`;
