import { gql } from 'apollo-angular';

export const QUERY_GETWORKORDERSEGMENTBYNUM = gql`
  query ($segmentNum: String!) {
    getWorkOrderSegmentByNum(segmentNum: $segmentNum) {
      id
      workOrderSegmentNum
      subject
      status
      make
      model
      serial
      customerEquipmentNum
      address
      address1
      address2
      city
      state
      zipcode
      country
      lat
      lng
      scheduledStartDateTime
      scheduledEndDateTime
    }
  }
`;
