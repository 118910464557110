import { gql } from 'apollo-angular';

export const Query_GetTeamsLastTimeSheets = gql`
  query ($teamNums: [String!]!, $branchNum: String!) {
    getTeamsLastTimeSheets(teamNums: $teamNums, branchNum: $branchNum) {
      id
      hours
      mins
      isOpen
      isPosted
      worker {
        displayName
        id
      }
      serviceVisitRef {
        serviceVisitNum
        customer {
          name
          customerNum
        }
      }
      workOrderSegment {
        id
        workOrderSegmentNum
        workOrder {
          id
          make
          model
          serial
          workOrderNum
          type
          shipTo {
            name
            customerNum
          }
        }
      }
      createdAt
      startDateTime
      endDateTime
      qty
      activityCode {
        fabIcon
        fabColor
        code
        type
        label
      }
    }
  }
`;
