import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { QUERY_GETGPSLOGS } from '../graphql/queries/gpslogs/getgpslogs.query';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class GPSLoggingService {
  constructor(private apollo: Apollo) {}

  getGPSLogs(startDate: Date, endDate: Date, workerIds: string[]) {
    const data = {
      startDate,
      endDate,
    };
    return this.apollo.watchQuery({
      query: QUERY_GETGPSLOGS,
      variables: { data },
    }).valueChanges;
  }
}
