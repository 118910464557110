import { gql } from 'apollo-angular';

export const QUERY_GetTimeSheetsBySegmentId = gql`
  query ($id: String!) {
    getTimeSheetsBySegmentId(id: $id) {
      id
      worker {
        displayName
        id
      }
      createdAt
      startDateTime
      endDateTime
      qty
      hours
      mins
      activityCode {
        code
        label
      }
    }
  }
`;
