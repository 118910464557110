import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { MessagingService } from '../../../@core/services/messaging/messaging.service';
import { TokenService } from '../../../@core/services/token.service';

@Component({
  selector: 'next-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  sub = new SubSink();
  //sessionId: string;
  session$;
  session;

  scrolltop: number = null;
  client: any;
  tc: any;
  chatForm: FormGroup;
  messages = [];
  uid: number;
  selectedMessage: any;
  replyMessage: string;
  selectedChannel: any;

  channels$;

  public channel: any;
  isPrivate: boolean = false;
  userId;

  constructor(
    private tokenService: TokenService,
    private messageService: MessagingService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.isPrivate = true;
    this.chatForm = this.fb.group({
      message: ['', Validators.required],
    });
    this.sub.sink = this.tokenService.get().subscribe(async (user: any) => {
      if (!user?.userId) return;
      this.userId = user.userId;
      this.setupSubscriptions();
      if (this.session?.sessionId) {
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  setupSubscriptions() {
    this.session$ = this.messageService.getMySessions('cache-first').pipe(
      map((sessions: any[]) => {
        let selected;
        sessions.forEach((session) => {
          if (session.sessionId === this.session?.sessionId) {
            selected = session;
          }
        });
        return selected;
      }),
      distinctUntilChanged(),
      tap((session: any) => {
        if (session) {
          this.session = session;
          const lastMessage = session.messages.slice(-1)[0];
          if (session.lastReadMessageId !== lastMessage?.id) {
            this.updateLastReadMessage(lastMessage?.id);
          }
        }
      })
    );
  }

  async updateLastReadMessage(messageId) {
    if (!this.session?.sessionId) return;
    await this.messageService
      .setLastMessageRead(this.session?.sessionId, messageId)
      .toPromise();
  }

  async sendMessage(msg) {
    const message = msg.message;
    const { type } = this.session;

    //Fix this crap later plz.
    let nType: string = 'Direct';
    if (type !== 'Direct') {
      nType = 'Team_Private';
    }

    const dto: any = {
      type: nType,
      message,
      toUserId: type === 'Direct' ? this.session?.recipentUser?.id : undefined,
      toTeamId: type !== 'Direct' ? this.session?.sessionId : undefined,
    };

    //this.message.setValue('');
    const { data } = await this.messageService.sendMessage(dto).toPromise();
    if (this.session?.sessionId) return;

    const sessionId = (<any>data)?.sendMessage?.sessionId;
    this.session.sessionId = sessionId;
    await this.messageService.checkAndAddSession(sessionId);
    this.setupSubscriptions();
  }

  // async sendMessage(msg) {
  //   if (this.isPrivate) {
  //     let replyId;
  //     for (const [key, value] of Object.entries(this.selectedChannel.state.members)) {
  //       if (key !== this.userId) {
  //         replyId = key;
  //         continue;
  //       }
  //     }

  //     const data = {
  //       toUserId: replyId,
  //       message: msg.message,
  //     };

  //     await this.chat.sendPrivateMessage(data).subscribe();
  //   }
  // }
}
