import { gql } from 'apollo-angular';

export const Query_GetMyTeamsLastTimeSheet = gql`
  query {
    getMyTeamsLastTimeSheets {
      lat
      lng
      isOpen
      startDateTime
      endDateTime
      hours
      mins
      manuallyEntered
      worker {
        displayName
        user {
          firstname
          lastname
          id
        }
      }
      workOrderSegment {
        workOrder {
          workOrderNum
          id
          shipTo {
            name
          }
        }
      }
      activityCode {
        code
        fabIcon
        fabColor
        fabLabel
        label
      }
    }
  }
`;
