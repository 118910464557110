import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { DtoAddTimeSheet } from '../../../@core/interfaces/DtoAddTimeSheet';
import { IPaginatedFilter } from '../../../@core/interfaces/IPaginatedFilter';
import { TimeSheet } from '../../../@core/interfaces/TimeSheet';
import { TimeManagmentApiService } from './timemanagment-api.service';
import { DtoUpdateTimeSheet } from '../../../@core/interfaces/DtoUpdateTimeSheet';

@Injectable({
  providedIn: 'root',
})
export class TimeManagmentService {
  private openTimeSheetsObs$: BehaviorSubject<TimeSheet> = new BehaviorSubject(
    null,
  );
  public teamMembersLastTimeSheets$: BehaviorSubject<TimeSheet[]> =
    new BehaviorSubject(null);

  private onTimeSheetUpdates = new Subject<any>();
  onTimeSheetUpdates$ = this.onTimeSheetUpdates.asObservable();

  constructor(
    private tmApi: TimeManagmentApiService, // private commonService: CommonService
  ) {}

  deleteTimeSheets(ids: string[]) {
    return this.tmApi.deleteTimeSheets(ids);
  }

  getMyTeamsLastTimeSheet() {
    return this.tmApi.getMyTeamsLastTimeSheet().pipe(
      map(({ data }) => {
        return (<any>data)?.getMyTeamsLastTimeSheets;
      }),
      tap((timeSheets) =>
        this.setTeamMembersLastTimeSheetObs(<TimeSheet[]>timeSheets),
      ),
    );
  }

  getTeamsLastTimeSheet(teamNums: string[], branchNum: string) {
    return this.tmApi.getTeamsLastTimeSheet(teamNums, branchNum);
  }

  // async startActivityCode(dto: DtoStartActivityCode) {
  //   const { coords } = await this.commonService.getCurrentLocation();
  //   console.log(coords);
  //   dto.lat = `${coords.latitude}`;
  //   dto.lng = `${coords.longitude}`;

  //   const timeSheet = await this.tmApi.startActivityCode(dto).toPromise();
  //   await this.commonService.showCustomerNotificationCancelToast();
  //   return timeSheet;
  // }

  stopActivityCode() {
    return this.tmApi.stopActivityCode().pipe(
      tap((e) => {
        this.setOpenTimeSheetsObs(null);
        //  this.commonService.showCheckMark();
      }),
    );
  }

  getMyOpenTimeSheet() {
    return this.tmApi.getMyOpenTimeSheet().pipe(
      tap(({ data }) => {
        const timeSheet = (<any>data)?.getMyOpenTimeSheet;
        this.setOpenTimeSheetsObs(timeSheet);
      }),
    );
  }

  getOpenTimeSheetsObs() {
    return this.openTimeSheetsObs$.asObservable();
  }

  setOpenTimeSheetsObs(timeSheet: any) {
    this.openTimeSheetsObs$.next(timeSheet);
  }

  getTeamMembersLastTimeSheetObs() {
    return this.teamMembersLastTimeSheets$.asObservable();
  }

  setTeamMembersLastTimeSheetObs(timeSheets: TimeSheet[]) {
    this.teamMembersLastTimeSheets$.next(timeSheets);
  }

  // async showAddTimeSheet(el, segment: any) {
  //   const modal = await this.modalController.create({
  //     component: TimeSheetAddTimeModalComponent,
  //     swipeToClose: true,
  //     presentingElement: el,
  //     componentProps: {
  //       segment,
  //     },
  //   });
  //   await modal.present();
  //   return modal.onWillDismiss();
  // }

  async getActivityCodes() {
    let codeMap = [];

    const { getActivityCodes } = await (<any>(
      await this.tmApi.getActivityCodes().toPromise()
    )).data;

    getActivityCodes.forEach((ac) => {
      let type: string = ac?.type?.replace('_', ' ');
      codeMap[type] ? null : (codeMap[type] = []);
      codeMap[type]?.push(ac);
    });
    return codeMap;
  }

  getFabActivityCodes() {
    return this.tmApi.getFabActivityCodes();
  }

  getTimeSheetById(id: string) {
    return this.tmApi.getTimeSheetById(id);
  }
  async showFilteredTimeSheetList(
    filter,
    el,
    title?: string,
    metric?: boolean,
  ) {
    //  const loading = await this.commonService.showLoading();
    const sub$ = this.getTimeSheets(filter).valueChanges.subscribe(
      async ({ data }) => {
        const objs = [];
        (<any>data)?.getTimeSheets?.edges.forEach(({ node }) => {
          objs.push({ ...node });
        });
        // loading.dismiss();
        // const modal = await this.modalController.create({
        //   component: TimeSheetListModalComponent,
        //   swipeToClose: true,
        //   presentingElement: el,
        //   componentProps: {
        //     timeSheets: objs,
        //     metric,
        //     title,
        //   },
        // });
        sub$.unsubscribe();
        // await modal.present();
        // await modal.onWillDismiss();
      },
    );
  }

  getDefaultActivityWork() {
    return this.tmApi.getDefaultActivityWork();
  }

  addTimeSheet(dto: DtoAddTimeSheet) {
    return this.tmApi.addTimeSheet(dto);
  }

  updateTimeSheet(dto: DtoUpdateTimeSheet) {
    return this.tmApi.updateTimeSheet(dto);
  }

  addTimeSheets(dto: DtoAddTimeSheet[]) {
    return this.tmApi
      .addTimeSheets(dto)
      .pipe(tap(() => this.onTimeSheetUpdates.next(true)));
  }

  getTimeSheetsBySegmentId(id: string) {
    return this.tmApi.getTimeSheetsBySegmentId(id);
  }

  getTimeSheets(filter: IPaginatedFilter) {
    return this.tmApi.getTimeSheets(filter);
  }

  getSumTimeSheets(filter: IPaginatedFilter, pollInterval = null) {
    return this.tmApi.getSumTimeSheets(filter, pollInterval);
  }
}
