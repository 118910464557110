import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkOrderComponent } from './workorder.component';
import { WorkOrdertRoutingModule } from './workorder-routing.module';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbPopoverModule,
  NbRadioModule,
  NbRouteTabsetModule,
  NbSelectModule,
  NbTabsetModule,
  NbTagModule,
  NbTimepickerModule,
  NbTreeGridModule,
  NbUserModule,
  NbStepperModule,
  NbSpinnerModule,
} from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { WorkOrderCreateComponent } from './create/createworkorder.component';
import { WorkOrderDetailComponent } from './detail/workorderdetail.component';
import { WorkOrderSegmentModule } from '../workordersegment/workordersegment.module';
import { WorkOrderExternalDetailComponent } from './external-detail/workorderexternal.component';
import { AttachmentsModule } from '../attachments/attachments.module';
import { EquipmentInputModule } from '../../shared/component/equipment/input/equipment-input.module';
import { EquipmentListModule } from '../../shared/component/equipment/list/equipment-list.module';
import { DynamicListViewModule } from '../../shared/component/dynamiclistview/dynamiclistview.module';
import { CustomerInputModule } from '../../shared/component/customers/input/customer-input.module';
import { ServiceVisitModule } from '../service-visit/service-visit.module';
import { ServiceVisitDetailModule } from '../service-visit/pages/service-visit-detail/service-visit-detail.module';
import { ChecklistDetailComponent } from '../checklist/components/checklist-detail/checklist-detail.component';

@NgModule({
  declarations: [
    WorkOrderComponent,
    WorkOrderCreateComponent,
    WorkOrderDetailComponent,
    WorkOrderExternalDetailComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    WorkOrdertRoutingModule,
    NbAccordionModule,
    NbActionsModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbDialogModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbListModule,
    NbPopoverModule,
    NbRadioModule,
    NbRouteTabsetModule,
    NbSelectModule,
    NbTabsetModule,
    NbTagModule,
    NbTreeGridModule,
    NbUserModule,
    NbTimepickerModule,
    NbDatepickerModule,
    NbStepperModule,
    FormsModule,
    ReactiveFormsModule,
    WorkOrderSegmentModule,
    AttachmentsModule,
    EquipmentInputModule,
    EquipmentListModule,
    DynamicListViewModule,
    CustomerInputModule,
    ServiceVisitDetailModule,
    NbSpinnerModule,
    ChecklistDetailComponent,
  ],
})
export class WorkOrderModule {}
