import { gql } from 'apollo-angular';

export const Mutation_UpdateServiceVisitStatus = gql`
  mutation updateServiceVisitStatus($dto: DtoUpdateServiceVisitStatus!) {
    updateServiceVisitStatus(dto: $dto) {
      id
      status
      statusRef {
        id
        color
      }
      serviceVisitWorkers {
        id
        status
        statusRef {
          id
          color
        }
      }
    }
  }
`;
