import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import {
  NbAutocompleteComponent,
  NbAutocompleteDirective,
  NbOptionComponent,
  NbPopoverDirective,
} from '@nebular/theme';
import { CustomerListComponent } from '../list/customerlistcomponent';
import { CustomerPopoverComponent } from '../popover/customer-popover.component';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'next-customerinput',
  templateUrl: './customer-input.component.html',
  styleUrls: ['./customer-input.component.scss'],
})
export class CustomerInputComponent implements OnInit {
  _customer: any;
  customers: any;
  orFilters: any;
  name: string = '';
  isTyping = false;
  popover = CustomerPopoverComponent;
  lookupPopover = CustomerListComponent;
  searchSubject = new Subject<string>();
  @ViewChild('list', { read: TemplateRef }) templateList: TemplateRef<any>;
  @ViewChild(NbPopoverDirective) popOverRef: NbPopoverDirective;

  @ViewChild('autooption') autooption: NbOptionComponent;
  @ViewChild(NbAutocompleteDirective)
  autocompleteDirective: NbAutocompleteDirective<string>;
  @ViewChild('searchInput') searchInput: ElementRef;
  @Output() onCustomerChange = new EventEmitter();
  @Input() hideCustomerNum: boolean;
  @Input() locked: boolean = false;
  @Input() filters;
  @Input() fieldSize: string = 'medium';
  @Input() page: string;
  @Input() showPrefix = true;
  @Input() outline = false;
  @Input() hideCustomerName: boolean;
  @Input() set customer(customer: any) {
    this._customer = customer;
    this.updateLabel();
  }

  get customer() {
    return this._customer;
  }

  constructor(private readonly router: Router) {}

  onPopOverStateChange(event) {
    if (
      !event.isShown &&
      !this.customer?.id &&
      this.searchInput.nativeElement.value !== ''
    ) {
      this.name = '';
      this.searchInput.nativeElement.value = '';
      this.searchSubject.next('');
    }
  }
  ngOnInit(): void {
    this.searchSubject.pipe(debounceTime(700)).subscribe((text) => {
      this.isTyping = false;
      this.orFilters = [
        {
          column: 'customerNum',
          value: `*${text}*`,
        },
        {
          column: 'name',
          value: `*${text}*`,
        },
        {
          column: 'address1',
          value: `*${text}*`,
        },
      ];
    });
  }

  onFocus(event) {
    if (!this.popOverRef.isShown) {
      event.target.select();
    }
  }

  onKeyUp(event) {
    this.isTyping = true;
    if (this.customer) {
      this.customer = null;
    }
    if (!this.popOverRef.isShown) {
      this.popOverRef.show();
    }

    const text = event.target.value;
    this.searchSubject.next(text);
  }

  updateLabel() {
    if (this.isTyping) {
      return;
    }
    if (!this._customer) {
      this.name = '';
      return;
    }

    if (this.hideCustomerName) {
      this.name = this._customer.customerNum;
      return;
    }

    if (this.hideCustomerNum) {
      this.name = this._customer.name;
      return;
    }

    this.name = !this.hideCustomerNum
      ? `${this._customer?.customerNum || ''} `
      : '';
    this.name += !this.hideCustomerName
      ? ` - ${this._customer?.name || ''} `
      : '';
  }

  onBlur(event) {
    if (!this.customer) {
      this.name = '';
    } else {
      this.updateLabel();
    }
  }

  selected(customer) {
    this.customer = customer;
    this.updateLabel();
    this.onCustomerChange.emit(customer);
    this.popOverRef.hide();
  }

  gotoCustomer() {
    this.router.navigateByUrl(`/pages/customers/${this.customer.id}`);
  }
}
