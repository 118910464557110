import { gql } from "apollo-angular";

export const Mutation_CreateSegmentNote = gql`
  mutation createSegmentNote ($data: CreateSegmentNote!) {
    createSegmentNote(data: $data) {
      id
      canModify
      note
      type
      createdAt
      createdBy
      createdByRef {
        id
        firstname
        lastname
        avatarURL
      }
      updatedAt
      updatedBy
      updatedByRef {
        id
        firstname
        lastname
        avatarURL
      }
      workOrderSegmentId
    }
  }
`;
