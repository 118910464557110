<div class="sv-detail-pane">
  <div
    class="sv-detail-header"
    style="display: flex; justify-content: space-between"
  >
    <div class="sv-detail-num">
      <h4 class="mb-2">
        {{ isLoading ? 'Loading..' : serviceVisit?.serviceVisitNum }}
      </h4>
    </div>
  </div>

  <nb-tabset>
    <nb-tab tabTitle="Details" tabIcon="clipboard-outline">
      <div class="list-header-bar mb-0">
        <button
          nbButton
          ghost
          size="small"
          status="primary"
          class="ml-1 pl-1"
          [disabled]="isButtonDisabled('dispatch')"
          (click)="updateServiceVisitStatus(this.id, 'Scheduled', true)"
        >
          <!-- (click)="updateServiceVisitStatus( 'Scheduled')" -->
          <i class="fa-light fa-truck-fast mr-1"></i>
          <span class="list-header-icon">Dispatch</span>
        </button>
        <button
          nbButton
          ghost
          size="small"
          status="success"
          (click)="updateServiceVisitStatus(this.id, 'Complete')"
          [disabled]="isButtonDisabled('complete')"
          [nbTooltipDisabled]="isTooltipDisabled('complete')"
          [nbTooltip]="getButtonTooltip('complete')"
        >
          <i class="fa-solid fa-check mr-1"></i>
          <span class="list-header-icon">Complete</span>
        </button>
        <button
          nbButton
          ghost
          status="danger"
          size="small"
          (click)="cancelServiceVisit(this.id, 'Canceled')"
          [disabled]="isButtonDisabled('cancel')"
          [nbTooltipDisabled]="isTooltipDisabled('cancel')"
          [nbTooltip]="getButtonTooltip('cancel')"
        >
          <i class="fa-light fa-ban mr-1"></i>
          <!--<nb-icon icon="trash-outline"></nb-icon>-->
          <span class="list-header-icon">Cancel</span>
        </button>
        <button
          nbButton
          ghost
          size="small"
          status="danger"
          [nbPopover]="workerListPopover"
          class="ml-1"
          [disabled]="isButtonDisabled('delete')"
          [nbTooltipDisabled]="isTooltipDisabled('delete')"
          [nbTooltip]="getButtonTooltip('delete')"
          (click)="deleteServiceVisit()"
        >
          <i class="fa-light fa-trash mr-1"></i>
          <span class="list-header-icon">Delete</span>
        </button>
        <button
          nbButton
          ghost
          size="small"
          [status]="serviceVisit?.groupId ? 'primary' : 'basic'"
          nz-popover
          nzPopoverTrigger="click"
          [nzPopoverContent]="createRecurringPopover"
          nzPopoverPlacement="bottom"
          [nzPopoverOverlayStyle]="{
            width: '850px',
          }"
        >
          <i class="fa-solid fa-repeat mr-1"></i>
          <span class="list-header-icon">Recurring</span>
        </button>
        <button
          nbButton
          ghost
          size="small"
          status="basic"
          nz-popover
          [nbPopover]="generateSOW"
          nzPopoverTrigger="click"
          nzPopoverPlacement="bottom"
        >
          <i class="fa-solid fa-file-pdf mr-1"></i>
          <span class="list-header-icon">Generate SOW</span>
        </button>
      </div>
      <nb-accordion multi #woAccordion accordion-shadow="false">
        <nb-accordion-item #serviceVisitDetails expanded>
          <nb-accordion-item-header>
            <div class="item-container">
              <div>Service Visit Details</div>
              <div class="item-tags" *ngIf="serviceVisitDetails.expanded">
                <button
                  nbButton
                  size="tiny"
                  status="primary"
                  ghost
                  (click)="toggleDetailsShowMore($event)"
                >
                  {{ detailsShowMore() ? 'Show Less' : 'Show More' }}
                </button>
                &nbsp;
              </div>
              <div class="item-tags" *ngIf="serviceVisitDetails.collapsed">
                <nb-tag size="tiny" [text]="serviceVisit?.status"> </nb-tag>
                <nb-tag size="tiny" [text]="serviceVisit?.category"> </nb-tag>
                <nb-tag
                  size="tiny"
                  [text]="serviceVisit?.customer?.customerNum"
                >
                </nb-tag>
                <nb-tag size="tiny" [text]="serviceVisit?.customer?.name">
                </nb-tag>
              </div>
            </div>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <nb-card class="sv-detail-card">
              <nb-card-body class="sv-detail-card-body"> </nb-card-body>
              <nb-card-body>
                <div class="row">
                  <div class="col-6">
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Status</label>
                      </div>
                      <div class="col">
                        <input
                          nbInput
                          fieldSize="small"
                          fullWidth
                          readonly
                          [value]="serviceVisit?.status"
                        />
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Category</label>
                      </div>
                      <div class="col">
                        <nb-select
                          status="primary"
                          [selected]="serviceVisit?.category"
                          (selectedChange)="
                            updateServiceVisitField('category', $event)
                          "
                          size="small"
                          fullWidth
                        >
                          <nb-option
                            *ngFor="let option of serviceVisitCategories"
                            [value]="option"
                          >
                            {{ option }}
                          </nb-option>
                        </nb-select>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Ship To</label>
                        <!-- Needs to overflow with ...-->
                      </div>
                      <div class="col">
                        <next-customerinput
                          fieldSize="small"
                          outline
                          [showPrefix]="false"
                          [customer]="serviceVisit?.customer"
                          page="ShipTo"
                          (onCustomerChange)="shipToChange($event)"
                        >
                        </next-customerinput>
                      </div>
                      <!-- <div class="col">
                        <input
                          nbInput
                          fieldSize="small"
                          fullWidth
                          readonly
                          rows="2"
                          [value]="
                            serviceVisit?.customer?.customerNum +
                              ' - ' +
                              serviceVisit?.customer?.name || ''
                          "
                        />
                      </div> -->
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Ship To Address</label>
                      </div>
                      <div class="col">
                        <textarea
                          nbInput
                          readonly
                          fieldSize="small"
                          fullWidth
                          rows="3"
                          [value]="serviceVisit?.address || ''"
                        ></textarea>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Contact</label>
                      </div>
                      <div class="col">
                        <contact-input
                          [value]="contact"
                          [filters]="[
                            {
                              column: 'customer.id',
                              value: serviceVisit?.customer?.id || '',
                            },
                          ]"
                          (onValueChange)="contactChange($event)"
                        ></contact-input>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Contact Phone</label>
                      </div>
                      <div class="col">
                        <input
                          nbInput
                          readonly
                          type="tel"
                          mask="(000) 000-000"
                          fieldSize="small"
                          fullWidth
                          [value]="serviceVisit?.contact?.phone || ''"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Contact Email</label>
                      </div>
                      <div class="col">
                        <input
                          nbInput
                          readonly
                          type="email"
                          fieldSize="small"
                          fullWidth
                          [value]="serviceVisit?.contact?.email || ''"
                        />
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Est. Travel</label>
                      </div>
                      <div class="col">
                        <input
                          nbInput
                          readonly
                          fieldSize="small"
                          fullWidth
                          value="{{ serviceVisit?.estTravelTime || 0 }}min / {{
                            serviceVisit?.estTravelDistance || 0
                          }}mi"
                        />
                      </div>
                    </div>

                    <div class="row mb-2" [hidden]="!detailsShowMore()">
                      <div class="col-4 leaders">
                        <label class="label">Travel</label>
                      </div>
                      <div class="col">
                        <input nbInput readonly fieldSize="small" fullWidth />
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Scheduled Start</label>
                      </div>
                      <div class="col">
                        <input
                          nbInput
                          fullWidth
                          readonly
                          fieldSize="small"
                          [(ngModel)]="scheduledStartDateTime"
                          placeholder="Pick Date"
                          [nbDatepicker]="datepicker"
                          (ngModelChange)="
                            onDateTimeChange(
                              this.scheduledStartDateTime,
                              this.scheduledEndDateTime
                            )
                          "
                        />
                        <nb-date-timepicker
                          #datepicker
                          twelveHoursFormat
                          format="MMM d, y, h:mm a"
                          [step]="15"
                          [max]="maxDate"
                        ></nb-date-timepicker>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-4 leaders">
                        <label class="label">Scheduled End</label>
                      </div>
                      <div class="col">
                        <input
                          nbInput
                          fullWidth
                          readonly
                          fieldSize="small"
                          [(ngModel)]="scheduledEndDateTime"
                          placeholder="Pick Date"
                          [nbDatepicker]="datepicker"
                          (ngModelChange)="
                            onDateTimeChange(
                              this.scheduledStartDateTime,
                              this.scheduledEndDateTime
                            )
                          "
                        />
                        <nb-date-timepicker
                          #datepicker
                          twelveHoursFormat
                          format="MMM d, y, h:mm a"
                          [step]="15"
                          [max]="maxDate"
                        ></nb-date-timepicker>
                      </div>
                    </div>

                    <div class="row mb-2" [hidden]="!detailsShowMore()">
                      <div class="col-4 leaders">
                        <label class="label">Onsite Start</label>
                      </div>
                      <div class="col">
                        <input nbInput fullWidth readonly fieldSize="small" />
                      </div>
                    </div>

                    <div class="row mb-2" [hidden]="!detailsShowMore()">
                      <div class="col-4 leaders">
                        <label class="label">Onsite End</label>
                      </div>
                      <div class="col">
                        <input nbInput fullWidth readonly fieldSize="small" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-5 row align-items-right pr-0">
                    <div class="align-items-right map">
                      @if (serviceVisit) {
                        <next-dispatchhub
                          id="svHub"
                          [serviceVisit]="serviceVisit"
                          [isDispatchHub]="false"
                          [fullScreen]="false"
                          [height]="435"
                        >
                        </next-dispatchhub>
                      }
                      <!-- <img
                        *ngIf="serviceVisit?.mapURL"
                        class="mb-1 mx-auto map"
                        [src]="serviceVisit?.mapURL"
                      /> -->
                    </div>
                    <!--<p class="text-center">
                      Lat: {{ serviceVisit?.lat }} / Lng:
                      {{ serviceVisit?.lng }}
                    </p>-->
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
          </nb-accordion-item-body>
        </nb-accordion-item>
        <nb-accordion-item #assignedTechnicians>
          <nb-accordion-item-header>
            <div class="item-container">
              <div>Assigned Technicians</div>
              <div class="item-tags" *ngIf="assignedTechnicians.collapsed">
                <ng-container
                  *ngFor="
                    let w of serviceVisit?.serviceVisitWorkers?.slice(0, 3);
                    let i = index
                  "
                >
                  <ng-container *ngIf="i < 3">
                    <nb-tag size="tiny" [text]="w?.worker?.workerNum"> </nb-tag>
                  </ng-container>
                </ng-container>
                <ng-container
                  *ngIf="serviceVisit?.serviceVisitWorkers?.length > 3"
                >
                  <nb-tag
                    size="tiny"
                    class="show-more"
                    text="+{{ serviceVisit?.serviceVisitWorkers?.length - 3 }}"
                    nz-tooltip
                    nzTooltipTitle="{{ tooltipTechnicians | slice: 0 : -2 }}"
                  >
                  </nb-tag>
                </ng-container>
              </div>
            </div>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="list-header-bar">
              <button
                nbButton
                ghost
                size="small"
                status="primary"
                [nbPopover]="workerListPopover"
                (click)="addWorkersToServiceVisit()"
                [disabled]="serviceVisit?.status === 'Canceled'"
                class="list-header-icon"
              >
                <i class="fa-light fa-plus mr-2"></i>
                Add
              </button>
              <button
                nbButton
                ghost
                status="danger"
                size="small"
                (click)="deleteWorkers()"
                [disabled]="serviceVisit?.status === 'Canceled'"
              >
                <i class="fa-light fa-trash mr-2"></i>
                <span class="list-header-icon">Delete</span>
              </button>
              <button
                nbButton
                ghost
                size="small"
                nz-popover
                (click)="viewSignature()"
                [nbPopover]="signaturesPopover"
                nzPopoverTrigger="click"
                nzPopoverPlacement="bottom"
              >
                <i class="fa-solid fa-signature mr-2"></i>
                <span class="list-header-icon">View Signatures </span>
              </button>
            </div>
            <next-object-list
              #workerList
              *ngIf="serviceVisit"
              class="mb-2"
              object="Service_Visit_Worker"
              [height]="200"
              [hideControlBar]="false"
              [hideToolBar]="true"
              [hideQueues]="true"
              [filters]="[{ column: 'serviceVisitId', value: id }]"
              (onSelect)="onSelectWorkersToDelete($event)"
            ></next-object-list>
          </nb-accordion-item-body>
        </nb-accordion-item>

        <nb-accordion-item #assignedWorkOrders>
          <nb-accordion-item-header>
            <div class="item-container">
              <div>Assigned Work Orders</div>
              <div class="item-tags" *ngIf="assignedWorkOrders.collapsed">
                <ng-container
                  *ngFor="
                    let w of serviceVisit?.serviceVisitWorkOrders;
                    let i = index
                  "
                >
                  <ng-container *ngIf="i < 3">
                    <nb-tag size="tiny" [text]="w?.workOrder?.workOrderNum">
                    </nb-tag>
                  </ng-container>
                </ng-container>
                <ng-container
                  *ngIf="serviceVisit?.serviceVisitWorkOrder?.length > 3"
                >
                  <nb-tag
                    size="tiny"
                    class="show-more"
                    text="+{{
                      serviceVisit?.serviceVisitWorkOrders?.length - 3
                    }}"
                    nz-tooltip
                    nzTooltipTitle="{{ tooltipWOs | slice: 0 : -2 }}"
                  >
                  </nb-tag>
                </ng-container>
              </div>
            </div>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="list-header-bar">
              <button
                nbButton
                ghost
                size="small"
                status="primary"
                [nbPopover]="workOrderListPopover"
                (click)="selectedWorkOrdersToAdd = []"
                [disabled]="serviceVisit?.status === 'Canceled'"
                class="list-header-icon"
              >
                <i class="fa-light fa-plus mr-2"></i>
                Add
              </button>
              <button
                nbButton
                ghost
                status="danger"
                size="small"
                [disabled]="serviceVisit?.status === 'Canceled'"
                (click)="removeWorkOrder()"
              >
                <i class="fa-light fa-trash mr-2"></i>
                <span class="list-header-icon">Delete </span>
              </button>
            </div>
            <next-object-list
              object="WorkOrder"
              [height]="210"
              [hideToolBar]="true"
              [hideQueues]="true"
              [hideControlBar]="false"
              [filters]="[{ column: 'id', value: workOrderId_array_str }]"
              (onSelect)="onSelectWorkOrdersToDelete($event)"
              (onDoubleClick)="showWorkOrderDetail($event)"
              *ngIf="serviceVisit"
            ></next-object-list>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </nb-tab>
    <nb-tab tabTitle="Work Orders" tabIcon="list-outline">
      <next-workordersegmentdetail
        [showList]="true"
        [orFilters]="segmentOrFilters"
        *ngIf="workOrder?.id"
      ></next-workordersegmentdetail>
    </nb-tab>
    <nb-tab tabTitle="Attachments" tabIcon="attach-outline">
      <next-attachment
        #attachments
        *ngIf="serviceVisit?.id"
        object="Service_Visit"
        [refId]="serviceVisit.id"
        [associatedObjects]="associatedAttachmentObjects"
      ></next-attachment>
    </nb-tab>
  </nb-tabset>
</div>

<ng-template #workerListPopover let-data>
  <nb-card
    class="margin-bottom-0"
    style="background-color: transparent"
    style="width: 700px"
  >
    <nb-card-body class="p-0">
      <div class="d-flex padding-popover">
        <div class="my-auto padded">
          <b>Add Technicians to Service Visit</b>
        </div>

        <button
          class="ml-auto mr-2"
          nbButton
          [status]="'success'"
          [disabled]="!selectedWorkersToAdd?.length"
          size="small"
          (click)="addWorkersToServiceVisit()"
        >
          <nb-icon icon="plus-outline"></nb-icon>
          {{
            selectedWorkersToAdd?.length
              ? selectedWorkersToAdd?.length + ' Workers'
              : 'Add'
          }}
        </button>
        <button
          class="margin-buttons"
          nbButton
          [status]="'danger'"
          size="small"
          (click)="closePopover()"
        >
          <nb-icon icon="close-outline"> Close </nb-icon>
        </button>
      </div>
      <next-object-list
        object="Worker"
        [height]="300"
        [hideToolBar]="true"
        [hideQueues]="true"
        [filters]="[{ column: 'blocked', value: 'false' }]"
        (onSelect)="onSelectAddWorkers($event)"
        (test)="receiveWorkerRecords($event)"
        *ngIf="serviceVisit"
      ></next-object-list>
    </nb-card-body>
  </nb-card>
</ng-template>
<ng-template #workOrderListPopover let-data>
  <nb-card
    class="margin-bottom-0"
    style="background-color: transparent"
    style="width: 700px"
  >
    <nb-card-body class="p-0">
      <div class="d-flex padding-popover">
        <div class="my-auto padded">
          <b>Add Work Orders to Service Visit</b>
        </div>
        <button
          class="ml-auto mr-2"
          nbButton
          [status]="'success'"
          [disabled]="!selectedWorkOrdersToAdd?.length"
          size="small"
          (click)="addWorkOrdersToServiceVisit()"
        >
          <nb-icon icon="plus-outline"></nb-icon>
          {{
            selectedWorkOrdersToAdd?.length
              ? selectedWorkOrdersToAdd?.length + ' Work Orders'
              : 'Add'
          }}
        </button>
        <button
          class="margin-buttons"
          nbButton
          [status]="'danger'"
          size="small"
          (click)="closePopover()"
        >
          <nb-icon icon="close-outline"> Close </nb-icon>
        </button>
      </div>

      @if (serviceVisit?.id && workOrderListQueues) {
        <next-object-list
          object="WorkOrder"
          [height]="300"
          [hideToolBar]="true"
          [queues]="workOrderListQueues"
          (onSelect)="workOrderAddOnSelect($event)"
        ></next-object-list>
      }
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #createRecurringPopover let-data>
  <nb-card>
    <nb-card-body>
      <sv-recurring-create
        [serviceVisit]="serviceVisit"
        [updateStatus]="updateServiceVisitStatus.bind(this)"
      ></sv-recurring-create>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #generateSOW let-data>
  <div style="width: 700px">
    <next-object-list
      #sowList
      object="Service_Visit_Work_Order"
      [height]="300"
      [hideToolBar]="true"
      [hideQueues]="true"
      [hideControlBar]="false"
      [filters]="[{ column: 'serviceVisit.id', value: serviceVisit.id }]"
      [showCheckbox]="true"
      rowSelection="multiple"
      [selectedIds]="selectedWorkOrdersToSOW"
      (onSelect)="setSelectedWorkOrdersToPrintSOW($event)"
      *ngIf="serviceVisit"
    >
    </next-object-list>
    <div class="d-flex p-2">
      <span class="flex-expand"></span>
      <button
        nbButton
        size="small"
        status="primary"
        (click)="generateSOWDocument(generateSOW)"
        [disabled]="!selectedWorkOrdersToPrintSOW?.length"
        [nbSpinner]="isGenerateLoading"
        nbSpinnerStatus="success"
        [disabled]="isGenerateLoading"
      >
        <i class="fa-solid fa-file-pdf mr-1"></i>
        Generate SOW {{ selectedWorkOrdersToPrintSOW?.length || '' }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #signaturesPopover let-data>
  <div style="width: 400px">
    @for (
      signature of customerSignature;
      track signature.id;
      let last = $last
    ) {
      <div class="sig-container">
        <div class="d-flex pl-3 pt-3">
          <img
            alt=""
            [src]="signature?.createdByRef?.avatarURL"
            style="width: 55px; height: 50px; border-radius: 30px"
          />
          <div class="p-2">
            <div>
              {{ signature?.createdByRef?.firstname }}
              {{ signature?.createdByRef?.lastName }}
            </div>
            <div>{{ signature?.createdAt | date: 'short' }}</div>
          </div>
        </div>

        <div class="d-flex">
          <span class="flex-expand"></span>
          <img class="sig-image" alt="" [src]="signature.signatureURL" />
          <span class="flex-expand"></span>
        </div>
        <div class="d-flex pt-1">
          <span class="flex-expand"></span>
          <p>{{ signature?.name }} &bull; {{ signature?.email }}</p>
          <span class="flex-expand"></span>
        </div>
      </div>
    }
  </div>
</ng-template>
