<nb-card style="width: 600px">
  <nb-card-header>Add Contact</nb-card-header>
  <nb-card-body>
    <form [formGroup]="form">
      <div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="customerEquipmentNum" class="label required"
                >Customer</label
              >

              <next-customerinput
                [customer]="customer"
                locked="true"
              ></next-customerinput>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="customerEquipmentNum" class="label required"
                >First Name</label
              >
              <input
                type="text"
                nbInput
                fullWidth
                id="firstname"
                formControlName="firstname"
                autocomplete="off"
              />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label for="qty" class="label required">Last Name</label>
              <input
                type="text"
                nbInput
                fullWidth
                id="lastname"
                formControlName="lastname"
                autocomplete="off"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="customerEquipmentNum" class="label required"
                >Phone #</label
              >
              <nb-form-field>
                <nb-icon nbPrefix icon="phone-outline" pack="eva"></nb-icon>
                <input
                  type="text"
                  nbInput
                  fullWidth
                  id="phone"
                  autocomplete="off"
                  formControlName="phone"
                  mask="(000) 000-0000"
                />
              </nb-form-field>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label for="qty" class="label required">Email</label>
              <nb-form-field>
                <nb-icon nbPrefix icon="at-outline" pack="eva"></nb-icon>
                <input
                  type="text"
                  nbInput
                  fullWidth
                  id="email"
                  formControlName="email"
                  autocomplete="off"
                  mask="A*@A*.SSS"
                />
              </nb-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label for="externalQuoteNumber" class="label">Notes</label>
              <textarea
                nbInput
                fullWidth
                id="notes"
                formControlName="notes"
                autocomplete="off"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer style="display: flex">
    <button nbButton (click)="close()">Cancel</button>
    <span style="flex-grow: 1"></span>
    <button
      nbButton
      status="primary"
      [nbSpinner]="isSaving"
      nbSpinnerStatus="warning"
      (click)="save()"
    >
      Add
    </button>
    <!-- <button
      nbButton
      status="primary"
      (click)="update(data.dto)"
      [nbSpinner]="isSavingOrderLine"
      *ngIf="data.dto.id"
      nbSpinnerStatus="warning"
    >
      Update
    </button> -->
  </nb-card-footer>
</nb-card>
