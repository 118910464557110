import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { FormsModule } from '@angular/forms';
//import { SafePipe } from '../safe.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {
  NbAlertModule,
  NbBadgeModule,
  NbButtonGroupModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbPopoverModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTagModule,
  NbToggleModule,
  NbTooltipModule,
} from '@nebular/theme';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMasonryModule } from 'ngx-masonry';
import { AttachmentImageComponent } from './components/attachment-image/attachment-image.component';
import { AgGridModule } from 'ag-grid-angular';
import { ThemeModule } from '../../@theme/theme.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

@NgModule({
  declarations: [AttachmentComponent, AttachmentImageComponent],
  exports: [AttachmentComponent],
  imports: [
    CommonModule,
    FormsModule,
    PdfViewerModule,
    NbCardModule,
    NbSpinnerModule,
    NbButtonModule,
    NbSelectModule,
    NbPopoverModule,
    NbBadgeModule,
    NbCheckboxModule,
    NbIconModule,
    NgxFileDropModule,
    NgxMasonryModule,
    NbInputModule,
    NbToggleModule,
    AgGridModule,
    NbButtonGroupModule,
    NbFormFieldModule,
    NbTagModule,
    ThemeModule,
    NbAlertModule,
    NzDropDownModule,
    NbTooltipModule,
  ],
  providers: [],
})
export class AttachmentsModule {}
