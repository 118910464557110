import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { CustomerService } from '../../../../pages/customer/services/customer.service';
import {
  GridUtilService,
  ISearchFilter,
} from '../../../../@core/services/gridUtil.service';
import { CustomerCreateComponent } from '../../../../pages/customer/create/createcustomer.component';
import { IPaginatedFilter } from 'packages/stack-web/src/app/@core/interfaces/IPaginatedFilter';

@Component({
  selector: 'next-customerlist',
  templateUrl: './customerlist.component.html',
  styleUrls: ['./customerlist.component.scss'],
})
export class CustomerListComponent implements OnInit {
  @Input() size: string;
  @Input() hideTopToolbar: boolean;
  @Input() gotoDetailOnClick: boolean;
  @Output() onDoubleClicked = new EventEmitter();

  gridApi: any;
  isLoading: boolean = false;
  customers: any = [];
  gridOptions: any = null;
  columnDefs = [
    { headerName: 'Type', field: 'type', width: 80 },
    {
      headerName: 'Customer #',
      field: 'customerNum',
      width: 90,
    },
    { headerName: 'Name', field: 'name', width: 250 },
    {
      headerName: 'BillTo #',
      field: 'billTo.customerNum',
      width: 90,
    },
    //{ headerName: "Address", field: "address", width: 400 },
    { headerName: 'Address', field: 'address1', width: 250 },
    { headerName: 'City', field: 'city', width: 100 },
    { headerName: 'State', field: 'state', width: 100 },
    { headerName: 'Zipcode', field: 'zipcode', width: 100 },
  ];
  constructor(
    private customerService: CustomerService,
    private dialogService: NbDialogService,
    private router: Router,
    private gridUtilService: GridUtilService,
  ) {
    //  this.setupGridOptions();
  }

  ngOnInit(): void {}

  addCustomer() {
    this.dialogService.open(CustomerCreateComponent, {
      closeOnBackdropClick: false,
      dialogClass: 'modal-class',
    });
  }

  // getCustomers() {
  //   this.customerService
  //     .getCustomers()
  //     .subscribe(({ error, loading, data }) => {
  //       const rtn: any = data;
  //       if (loading) {
  //         this.isLoading = loading;
  //         return;
  //       }
  //       this.customers = rtn.getCustomers;
  //     });
  // }

  setDefaultGridFilter() {
    if (!this.gridApi) return;
    this.gridApi.setFilterModel({});
    this.gridApi.onFilterChanged();
  }

  viewDetail(customer: any) {
    const { id } = customer.data;
    if (!id) return;
    this.router.navigate(['/pages/customers/', id]);
  }

  // setupGridOptions() {
  //   this.gridOptions = {
  //     columnDefs: this.columnDefs,
  //     rowSelection: "single",

  //     onRowDoubleClicked: (row) => {
  //       this.onDoubleClicked.emit(row.data);
  //       if (this.gotoDetailOnClick) {
  //         this.viewDetail(row);
  //       }
  //     },
  //     defaultColDef: {
  //       resizable: true,
  //       sortable: true,
  //       filter: "agTextColumnFilter",
  //       floatingFilter: true,
  //       suppressMenu: true,
  //       suppressFilterButton: true,
  //       filterParams: { buttons: ["reset"], debounceMs: 200 },
  //       floatingFilterComponentParams: {
  //         suppressFilterButton: true,
  //         debounceMs: 200,
  //       },
  //     },
  //   };
  // }
}
