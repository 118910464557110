import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NbAutocompleteComponent,
  NbAutocompleteDirective,
  NbOptionComponent,
} from '@nebular/theme';
import { CustomerPopoverComponent } from '../../customers/popover/customer-popover.component';

@Component({
  selector: 'next-equipmentinput',
  templateUrl: './equipmentinput.component.html',
  styleUrls: ['./equipmentinput.component.scss'],
})
export class EquipmentInputComponent implements OnInit {
  _equipment: any;
  _customer: any = {};
  isLoading: boolean;
  equipmentNum: string = '';
  popover = CustomerPopoverComponent;

  @ViewChild('autooption') autooption: NbOptionComponent;
  @ViewChild(NbAutocompleteDirective)
  autocompleteDirective: NbAutocompleteDirective<string> = null;

  @Output() onEquipmentChange = new EventEmitter();
  @Input() set equipment(equipment: any) {
    this._equipment = equipment;
    this.updateLabel();
  }
  @Input() set customer(customer: any) {
    this._customer = customer;
  }

  get equipment() {
    return this._equipment;
  }
  get customer() {
    return this._customer;
  }

  updateLabel() {
    this.equipmentNum = this.equipment?.equipmentNum || '';
  }

  constructor() {}

  ngOnInit(): void {}

  inputClick() {
    this.autooption.value = undefined;
  }

  selected(equipment) {
    this.equipment = equipment;
    this.updateLabel();
    this.onEquipmentChange.emit(equipment);
    if (!equipment) return;
    this.autooption.value = this.equipmentNum;
    this.autooption.onClick({
      preventDefault: () => {
        return;
      },
    });
  }
}
