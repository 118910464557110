import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  signal,
} from '@angular/core';
import {
  NbButtonGroupModule,
  NbButtonModule,
  NbCalendarComponent,
  NbIconModule,
  NbPopoverDirective,
  NbPopoverModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbTagModule,
  NbToggleModule,
} from '@nebular/theme';
import { NbCalendarModule } from '@nebular/theme';
import {
  CalendarCustomDayCellComponent,
  dayCellService,
} from '../dayCellComponent/dayCellComponent.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';
import { DynamicListViewModule } from 'packages/stack-web/src/app/shared/component/dynamiclistview/dynamiclistview.module';
import { CommonModule } from '@angular/common';
import moment from 'moment';
import { WorkOrderService } from 'packages/stack-web/src/app/@core/services/workorder.service';
import { SubSink } from 'subsink';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    NbCalendarModule,
    FormsModule,
    NzSelectModule,
    NbTabsetModule,
    DynamicListViewModule,
    NbButtonGroupModule,
    NbButtonModule,
    NbTagModule,
    NbIconModule,
    NbSpinnerModule,
    NbPopoverModule,
    NzPopoverModule,
    NbToggleModule,
  ],
  selector: 'sv-recurring-create',
  templateUrl: './sv-recurring-create.component.html',
  styleUrls: ['./sv-recurring-create.component.scss'],
})
export class ServiceVisitRecurringCreateComponent implements OnInit, OnDestroy {
  @ViewChild('calendar', { static: true }) calendar: NbCalendarComponent<Date>;
  // @ViewChildren(NbPopoverDirective) popovers: QueryList<NbPopoverDirective>;
  // @ViewChild(NbPopoverDirective) recurringDispatchPopUp: NbPopoverDirective;
  dayCellComponent = CalendarCustomDayCellComponent;
  dateUOM = null;
  dateInterval = 0;
  allowedIntervals = [];
  allowedDaysOfWeek = [];
  proposedServiceVisits = [];
  associatedServiceVisits = [];
  sub = new SubSink();
  tabValue: 'modify' | 'all' = 'all';
  createCount = 0;
  deleteCount = 0;
  isSaving = false;
  plannedServiceVisits = signal([]);
  showPopover = false;
  isDispatching = false;
  notifyWorkersOnDispatch = true;

  @Input() serviceVisit: any;
  @Input() updateStatus: (
    svId: string,
    status: string,
    notifyWorkers: boolean,
  ) => void;
  date: Date = new Date('5/1/2023');
  constructor(
    private dayCellService: dayCellService,
    private workOrderService: WorkOrderService,
  ) {}

  ngOnInit() {
    this.calendar.visibleDate = new Date(
      this.serviceVisit.scheduledStartDateTime,
    );
    this.allowedDaysOfWeek = [moment().format('ddd')];
    this.setAllowedNumberOfInterval();
    this.getAllRecurringServiceVisits();
  }

  showDispatchPopover(visible: boolean) {
    this.showPopover = visible;
  }

  onDateUOMChange(event) {
    this.dateUOM = event;
    this.setAllowedNumberOfInterval();
    this.calculatePropossedServiceVisits();
  }

  onDateIntervalChange(event) {
    this.dateInterval = event;
    this.calculatePropossedServiceVisits();
  }

  getAllowedNumberOfInterval() {
    switch (this.dateUOM) {
      case 'daily':
        return 30;
      case 'weekly':
        return 5;
    }
  }

  setAllowedNumberOfInterval() {
    this.dateInterval = 1;
    const interval = this.getAllowedNumberOfInterval();
    this.allowedIntervals = [];
    for (let i = 1; i <= interval; i++) {
      this.allowedIntervals.push(i);
    }
  }

  calculatePropossedServiceVisits() {
    const scheduledStartDateTime = new Date(
      this.serviceVisit.scheduledStartDateTime,
    );
    const scheduledEndDateTime = new Date(
      this.serviceVisit.scheduledEndDateTime,
    );

    const dateUOM = this.dateUOM;
    const dateInterval = this.dateInterval;
    const selectedDays = this.allowedDaysOfWeek;
    let serviceVisits = [];
    if (dateUOM === 'daily') {
      for (let i = 2; i < dateInterval + 2; i++) {
        let newStartDate = new Date(scheduledStartDateTime);
        let newEndDate = new Date(scheduledEndDateTime);
        newStartDate.setDate(scheduledStartDateTime.getDate() + i);
        newEndDate.setDate(scheduledEndDateTime.getDate() + i);
        //if current proposed service visit is not in the list, add it.
        const hasDate = this.associatedServiceVisits.find(
          (element) =>
            moment(element?.scheduledStartDateTime).format('YYYY-MM-DD') ==
            moment(newStartDate).format('YYYY-MM-DD'),
        );

        if (!hasDate) {
          serviceVisits.push({
            serviceVisitNum: this.serviceVisit.serviceVisitNum,
            scheduledStartDateTime: newStartDate,
            scheduledEndDateTime: newEndDate,
          });
        }
      }
    } else if (dateUOM === 'weekly') {
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      let currentWeekDay = scheduledStartDateTime.getDay();

      for (
        let i = 0;
        moment(moment(scheduledStartDateTime).add(i, 'day')).isBefore(
          moment(scheduledStartDateTime)
            .add(dateInterval, 'week')
            .endOf('week'),
        );
        i++
      ) {
        let newStartDate = new Date(scheduledStartDateTime);
        let newEndDate = new Date(scheduledEndDateTime);
        newStartDate.setDate(scheduledStartDateTime.getDate() + i);
        newEndDate.setDate(scheduledEndDateTime.getDate() + i);
        if (
          selectedDays.includes(daysOfWeek[newStartDate.getDay()]) &&
          newStartDate.getDate() !== new Date().getDate()
        ) {
          const hasDate = this.associatedServiceVisits.find(
            (element) =>
              moment(element?.scheduledStartDateTime).format('YYYY-MM-DD') ==
              moment(newStartDate).format('YYYY-MM-DD'),
          );

          if (!hasDate) {
            serviceVisits.push({
              serviceVisitNum: this.serviceVisit.serviceVisitNum,
              scheduledStartDateTime: newStartDate,
              scheduledEndDateTime: newEndDate,
            });
          }
        }
        currentWeekDay = (currentWeekDay + 1) % 7;
      }
    }

    this.proposedServiceVisits = [
      ...serviceVisits,
      ...this.associatedServiceVisits,
    ];
    this.sortProposedServiceVisits();
    this.updateCounts();
    this.dayCellUpdate();
  }

  dayCellUpdate() {
    this.dayCellService.changeData(this.proposedServiceVisits);
    this.calendar.dateChange.emit(new Date());
  }

  updateMultiSelectGroupValue(event) {
    this.allowedDaysOfWeek = event;
    this.calculatePropossedServiceVisits();
  }

  ngOnDestroy() {
    this.dayCellService.changeData(null);
    this.sub.unsubscribe();
  }

  onDateChange(date) {
    if (
      date.getDate() === new Date().getDate() ||
      new Date(date) < new Date()
    ) {
      return;
    }
    const formattedComparisonDate = date.toISOString().split('T')[0];
    const hasDate = this.proposedServiceVisits.find((element) => {
      const scheduledStart = new Date(element?.scheduledStartDateTime);
      return (
        scheduledStart.toISOString().split('T')[0] === formattedComparisonDate
      );
    });

    if (hasDate?.status && hasDate?.status !== 'Planned') {
      return;
    }

    if (hasDate?.id) {
      hasDate.toBeDeleted = !hasDate.toBeDeleted;
      this.sortProposedServiceVisits();
      this.updateCounts();
      return;
    }

    if (hasDate) {
      const formattedComparisonDate = date.toISOString().split('T')[0];
      this.proposedServiceVisits = this.proposedServiceVisits.filter(
        (element) => {
          const scheduledStart = new Date(element?.scheduledStartDateTime);
          return (
            scheduledStart.toISOString().split('T')[0] !==
            formattedComparisonDate
          );
        },
      );

      this.sortProposedServiceVisits();
      this.updateCounts();
      this.dayCellUpdate();
      return;
    }

    const scheduledStartDateTime = new Date(date);
    const scheduledEndDateTime = new Date(date);
    const startTimeSource = new Date(this.serviceVisit.scheduledStartDateTime);
    const endTimeSource = new Date(this.serviceVisit.scheduledEndDateTime);

    // Set hours, minutes, seconds, and milliseconds from 'scheduledStartDateTime'
    scheduledStartDateTime.setHours(
      startTimeSource.getHours(),
      startTimeSource.getMinutes(),
      startTimeSource.getSeconds(),
      startTimeSource.getMilliseconds(),
    );
    scheduledEndDateTime.setHours(
      endTimeSource.getHours(),
      endTimeSource.getMinutes(),
      endTimeSource.getSeconds(),
      endTimeSource.getMilliseconds(),
    );

    this.proposedServiceVisits.unshift({
      scheduledStartDateTime,
      scheduledEndDateTime,
    });

    this.sortProposedServiceVisits();
    this.updateCounts();
    this.dayCellUpdate();
    return true;
  }

  //Multi sort, First toBeDeleted, then items without id, then by date
  sortProposedServiceVisits() {
    this.proposedServiceVisits = this.proposedServiceVisits.sort(
      (a, b) =>
        (a.toBeDeleted === b.toBeDeleted ? 0 : a.toBeDeleted ? -1 : 1) ||
        (a.id ? 1 : -1) ||
        (a.scheduledStartDateTime > b.scheduledStartDateTime ? 1 : -1),
    );

    this.dayCellUpdate();
  }

  resetPropossed() {
    this.proposedServiceVisits = [...this.associatedServiceVisits];
    // this.proposedServiceVisits.forEach((item) => {
    //   item.toBeDeleted = false;
    // });
    this.updateCounts();
    this.dayCellUpdate();
  }

  async modifyPropossed() {
    this.isSaving = true;
    await this.createRecurring();
    await this.deleteRecurring();
    await this.getAllRecurringServiceVisits();
    this.isSaving = false;
  }

  async createRecurring() {
    let dto = {
      serviceVisitId: this.serviceVisit.id,
      lines: this.proposedServiceVisits
        .filter((serviceVisit) => {
          return !serviceVisit.id;
        })
        .map((serviceVisit) => {
          return {
            scheduledStartDateTime: serviceVisit.scheduledStartDateTime,
            scheduledEndDateTime: serviceVisit.scheduledEndDateTime,
          };
        }),
    };

    if (!dto.lines.length) {
      return;
    }

    const rtn = await this.workOrderService
      .createRecurringServiceVisit(dto)
      .toPromise();
    const groupId = rtn?.data?.['createRecurringServiceVisit']?.[0].groupId;
    if (groupId) {
      this.serviceVisit.groupId = groupId;
    }
  }

  async deleteRecurring() {
    const serviceVisitIds = this.proposedServiceVisits
      .filter((serviceVisit) => {
        return serviceVisit.toBeDeleted;
      })
      .map((serviceVisit) => {
        return serviceVisit.id;
      });

    if (!serviceVisitIds.length) {
      return;
    }

    await this.workOrderService
      .deleteServiceVisits(serviceVisitIds)
      .toPromise();
  }

  getAllRecurringServiceVisits() {
    if (!this.serviceVisit.groupId) {
      return;
    }
    this.sub.sink = this.workOrderService
      .getServiceVisitsByGroupId(this.serviceVisit.groupId)
      .valueChanges.subscribe(({ data }) => {
        if (!data) {
          return;
        }
        const serviceVisits = [...data['getServiceVisitsByGroupId']];
        this.associatedServiceVisits = serviceVisits.map((item) => {
          return { ...item };
        });
        this.proposedServiceVisits = serviceVisits.map((item) => {
          return { ...item };
        });
        const plannedVisits = serviceVisits.filter(
          (item) => item.status === 'Planned',
        );
        this.plannedServiceVisits.set(plannedVisits);
        this.dayCellUpdate();
        this.updateCounts();
        this.sortProposedServiceVisits();
      });
  }

  updateCounts() {
    this.createCount =
      this.proposedServiceVisits?.filter((item) => !item?.id).length || 0;

    this.deleteCount =
      this.proposedServiceVisits?.filter((item) => item?.toBeDeleted).length ||
      0;
  }

  async callUpdateStatus(serviceVisitId) {
    this.isDispatching = true;
    if (Array.isArray(serviceVisitId)) {
      serviceVisitId = serviceVisitId.map((item) => item.id);
    }
    if (this.updateStatus) {
      await this.updateStatus(serviceVisitId,'Scheduled', this.notifyWorkersOnDispatch);
      this.isDispatching = false;
      this.showDispatchPopover(false);
    }
  }

  get plannedServiceVisitTotalTravelTime() {
    return this.plannedServiceVisits()
      ?.reduce((acc, curr) => {
        return acc + curr.estTravelTime / 60;
      }, 0)
      .toFixed(1);
  }

  get plannedServiceVisitTotalScheduledWorkHours() {
    return this.plannedServiceVisits()
      ?.reduce((acc, curr) => {
        return (
          acc +
          (new Date(curr.scheduledEndDateTime).valueOf() -
            new Date(curr.scheduledStartDateTime).valueOf()) /
            1000 /
            60 /
            60
        );
      }, 0)
      .toFixed(1);
  }

  // get plannedServiceVisitWorkers() {
  //   const workerFacts = [];
    // this.plannedServiceVisits()?.forEach((visit) => {
    //   visit.serviceVisitWorkers.forEach((svw) => {
    //     const foundFact = workerFacts.filter(
    //       (wf) => wf.displayName === svw?.worker?.displayName,
    //     )[0];
    //     const workHours =
    //       (new Date(visit.scheduledEndDateTime).valueOf() -
    //         new Date(visit.scheduledStartDateTime).valueOf()) /
    //       1000 /
    //       60 /
    //       60;

    //     if (!foundFact) {
    //       workerFacts.push({
    //         displayName: svw?.worker?.displayName,
    //         totalServiceVisits: 1,
    //         totalScheduledWorkHours: workHours,
    //         totalTravelTime: visit?.estTravelTime,
    //         totalWorkOrders: visit?.serviceVisitWorkOrders?.length,
    //       });
    //     } else {
    //       foundFact.totalServiceVisits += 1;
    //       foundFact.totalScheduledWorkHours += workHours || 0;
    //       foundFact.totalTravelTime += visit?.estTravelTime || 0;
    //       foundFact.totalWorkOrders +=
    //         visit?.serviceVisitWorkOrders?.length || 0;
    //     }
    //   });
    // });
    // return Array.from(workerFacts);
  // }
}
