import { gql } from "apollo-angular";

export const Mutation_AddWorkersToServiceVisit = gql`
  mutation addWorkersToServiceVisit($serviceVisitId: String!, $workerNums: [String!]!) {
    addWorkersToServiceVisit(id: $serviceVisitId, workerNums: $workerNums) {
      id
      serviceVisitWorkers {
        id
        worker {
          id
          workerNum
          displayName
        }
      }
    }
  }
`;