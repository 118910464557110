import { gql } from 'apollo-angular';

export const Mutation_DispatchServiceVisits = gql`
  mutation dispatchServiceVisits($dto: DtoDispatchServiceVisits!) {
    dispatchServiceVisits(dto: $dto) {
      id
      status
      scheduledStartDateTime
      scheduledEndDateTime
      estTravelTime
      status
      statusRef {
        id
        color
      }
      serviceVisitWorkOrders {
        id
        workOrderNum
        workOrder {
          id
          status
        }
      }
    }
  }
`;
