import { gql } from 'apollo-angular';

export const Query_GetSession = gql`
  query ($id: String!) {
    getSession(id: $id) {
      id
      type
      sessionId
      lastReadMessageId
      teamName
      unreadMessageCount
      messages {
        message
        id
        createdAt
        fromUser {
          id
          fullName
        }
      }
      recipentUser {
        fullName
        id
        avatarURL
      }
      lastMessageRef {
        message
        createdAt
        id
      }
    }
  }
`;
