<div [nbSpinner]="loading" nbSpinnerStatus="primary">
  @if (attachment.loadingError) {
    <nb-card
      class="loading-card"
      [nbSpinner]="attachment.loadingError"
      nbSpinnerStatus="primary"
      nbSpinnerMessage="In Progress..."
    ></nb-card>
  } @else if (imageLoadFailed) {
    <nb-card>
      <nb-card-body>
        <nb-icon style="width: 30px; height: 30px" icon="image-outline">
          Image failed to load
        </nb-icon>
      </nb-card-body>
    </nb-card>
  } @else if (attachment?.fileName.toLowerCase().endsWith('pdf')) {
    <pdf-viewer
      [src]="attachment?.signedURL"
      [original-size]="false"
      [fit-to-page]="true"
      (after-load-complete)="imageDidLoad()"
      [autoresize]="true"
      [style.width.px]="width"
      [style.height.px]="width + 120"
      (click)="displayImage(attachment)"
    ></pdf-viewer>
  } @else if (isOfficeDocument(attachment?.fileName)) {
    <iframe
      [src]="msURL"
      [style.width.px]="width"
      [style.height.px]="width + 120"
      frameborder="0"
      (load)="imageDidLoad()"
    ></iframe>
  } @else if (isVideo(attachment?.fileName)) {
    <video
      [autoplay]="autoPlay"
      controls
      [src]="attachment?.signedURL"
      [style.max-width.px]="width"
      [style.min-height.px]="100"
      (click)="displayImage(attachment)"
      (loadeddata)="imageDidLoad()"
    ></video>
  } @else {
    <img
      alt="Attachment"
      masonryLazy
      loading="lazy"
      [src]="attachment?.signedURL"
      [id]="attachment?.id"
      [style.max-width.px]="width"
      [style.min-height.px]="100"
      (load)="imageDidLoad()"
      (click)="displayImage(attachment)"
    />
    <!-- <div *ngIf="loading">
      <nb-spinner></nb-spinner>
    </div> -->
  }
</div>
