import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { _finally } from 'rxjs-compat/operator/finally';
import { OrderNewPacketComponent } from '../../../../../pages/orders/components/order-new-packet/order-new-packet.component';
import { ComponentMasterService } from '../../services/componentMaster.service';

export interface GridMenuButton {
  type?: 'button' | 'dropdown' | 'divider';
  label?: string;
  icon?: string;
  iconColor?: string;
  action?:
    | 'new'
    | 'edit'
    | 'delete'
    | 'copy'
    | 'attachments'
    | 'popup'
    | 'custom';
  actionParams?: any;
  customAction?: string;
  disabled?: any;
  children?: GridMenuButton[];
  isDisabled?: boolean;
}

@Component({
  selector: 'grid-menu-button',
  templateUrl: './grid-menu-button.component.html',
  styleUrls: ['./grid-menu-button.component.scss'],
})
export class GridMenuButtonComponent implements OnInit {
  @Input() button: GridMenuButton = null;
  @Input() disabled = false;
  @Output() buttonAction = new EventEmitter();
  popoverComponent: any;

  constructor(private componentMaster: ComponentMasterService) {}

  async ngOnInit() {
    const component = this.componentMaster.getComponent('new-order-packet');
    this.popoverComponent = component;
  }

  emitButtonAction(button, e) {
    const dto = { ...button, ...{ event: e } };
    this.buttonAction.emit(dto);
    //this.showPopover(e);
  }

  async showPopover(e) {
    console.log(e);
  }
}
