<div *ngIf="workorder">
  <nb-stepper orientation="horizontal">
    <nb-step [label]="labelOne">
      <ng-template #labelOne>Scheduled</ng-template>
    </nb-step>
    <nb-step [label]="labelTwo">
      <ng-template #labelTwo>In-Route</ng-template>
    </nb-step>
    <nb-step label="In-Process"> </nb-step>
    <nb-step [label]="labelFour">
      <ng-template #labelFour>Complete</ng-template>
    </nb-step>
  </nb-stepper>
  <h4>{{ workorder?.workOrderNum }}</h4>
  <p class="text-hint">{{ workorder?.type }}</p>
  <nb-tabset (changeTab)="onTabChange($event)">
    <nb-tab
      tabTitle="Details"
      tabIcon="clipboard-outline"
      [active]="activeTab == 'Details' || !activeTab"
    >
      <div class="header-container">
        <span class="flex-expand"></span>
        <button
          nbButton
          hero
          size="small"
          status="primary"
          (click)="updateWorkOrder()"
        >
          <nb-icon icon="save-outline"></nb-icon>Save Workorder
        </button>
      </div>
      <form [formGroup]="form" autocomplete="off">
        <nb-card>
          <nb-card-body>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="label">
                    Ship To
                    <nb-icon
                      icon="search-outline"
                      status="primary"
                      style="font-size: 1.2em"
                    >
                    </nb-icon>
                  </label>
                  <next-customerinput
                    [customer]="shipTo"
                    (onCustomerChange)="shipToChange($event)"
                  >
                  </next-customerinput>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="label"
                    >Bill To
                    <nb-icon
                      icon="search-outline"
                      status="primary"
                      style="font-size: 1.2em"
                    >
                    </nb-icon>
                  </label>
                  <next-customerinput
                    [customer]="billTo"
                    (onCustomerChange)="billToChange($event)"
                  >
                  </next-customerinput>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="label">Contact</label>
                  <input
                    type="text"
                    nbInput
                    fullWidth
                    id="contact"
                    autocomplete="off"
                    fieldSize="small"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="label">Authorizer</label>
                  <input
                    type="text"
                    nbInput
                    fullWidth
                    id="authorizer"
                    autocomplete="off"
                    fieldSize="small"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="model" class="label">
                    Equipment
                    <nb-icon
                      icon="search-outline"
                      status="primary"
                      style="font-size: 1.2em"
                    >
                    </nb-icon>
                  </label>
                  <next-equipmentinput
                    [equipment]="equipment"
                    (onEquipmentChange)="equipmentChange($event)"
                  >
                  </next-equipmentinput>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="serial" class="label">Serial</label>
                  <input
                    type="text"
                    nbInput
                    fullWidth
                    id="serial"
                    formControlName="serial"
                    autocomplete="off"
                    fieldSize="small"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="customerEquipmentNum" class="label"
                    >Customer Equipment#</label
                  >
                  <input
                    type="text"
                    nbInput
                    fullWidth
                    id="customerEquipmentNum"
                    formControlName="customerEquipmentNum"
                    fieldSize="small"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="make" class="label">Make</label>
                  <input
                    type="text"
                    nbInput
                    fullWidth
                    id="make"
                    formControlName="make"
                    autocomplete="off"
                    fieldSize="small"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="model" class="label">Model</label>
                  <input
                    type="text"
                    nbInput
                    fullWidth
                    id="model"
                    formControlName="model"
                    autocomplete="off"
                    fieldSize="small"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="lastMeterReading" class="label"
                    >Last Meter Reading</label
                  >
                  <input
                    type="text"
                    nbInput
                    fullWidth
                    id="lastMeterReading"
                    fieldSize="small"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="subject" class="label">Subject</label>
                  <input
                    type="text"
                    nbInput
                    fullWidth
                    id="subject"
                    formControlName="subject"
                    autocomplete="off"
                    fieldSize="small"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="type" class="label">Type</label>
                  <input
                    type="text"
                    nbInput
                    fullWidth
                    id="type"
                    formControlName="type"
                    autocomplete="off"
                    fieldSize="small"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="status" class="label">Status</label>
                  <input
                    type="text"
                    nbInput
                    fullWidth
                    id="status"
                    formControlName="status"
                    autocomplete="off"
                    fieldSize="small"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="note_ProblemDesc" class="label"
                    >Customer Complaint</label
                  >
                  <textarea
                    type="textarea"
                    nbInput
                    fullWidth
                    id="note_ProblemDesc"
                    formControlName="note_ProblemDesc"
                    autocomplete="off"
                    rows="4"
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="note_Internal" class="label"
                    >Internal Notes</label
                  >
                  <textarea
                    type="textarea"
                    nbInput
                    fullWidth
                    id="note_Internal"
                    formControlName="note_Internal"
                    autocomplete="off"
                    fieldSize="small"
                    rows="4"
                  ></textarea>
                </div>
              </div>
            </div>
            <hr />
            <next-address [form]="form"></next-address>
          </nb-card-body>
        </nb-card>
      </form>
    </nb-tab>
    <nb-tab
      tabTitle="Segments"
      tabIcon="list-outline"
      [active]="activeTab == 'Segments'"
    >
      <next-workordersegmentdetail
        *ngIf="workorder"
        [workOrder]="workorder"
        showList="true"
      ></next-workordersegmentdetail>
    </nb-tab>
    <nb-tab
      tabTitle="Attachments"
      tabIcon="attach-outline"
      [active]="activeTab == 'Attachments'"
    >
    </nb-tab>
  </nb-tabset>
</div>
