import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ComponentMasterService {
  map = new Map<string, { component: string; path: string }>();

  constructor() {
    this.initMap();
  }

  getComponent(action) {
    const component = this.map.get(action);
    return this.map.get(action);
  }

  async initMap() {
    this.map.set('new-order-packet', {
      component: 'OrderNewPacketComponent',
      path: 'order-new-packet.component',
    });
  }
}
