import { gql } from 'apollo-angular';

export const Query_GetMobileChangeLogs = gql`
  query ($search: DtoPaginatedFilter!) {
    getMobileChangeLogs(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          createdAt
          arrivedAt
          processedAt
          completedAt
          tenantId
          action
          payload
          worker {
            id
            displayName
            workerNum
          }
          workerId
          isError
          response
          errorMessage
          isProcessed
          offlineId
          offlineCreatedAt
          apiHost
          clientVersion
        }
      }
    }
  }
`;
