import { gql } from 'apollo-angular';

export const Query_GetMyOpenTimeSheet = gql`
  query {
    getMyOpenTimeSheet {
      id
      hours
      mins
      isOpen
      worker {
        displayName
        id
      }
      workOrderSegment {
        id
        workOrderSegmentNum
        workOrder {
          id
          make
          model
          serial
          workOrderNum
          type
          shipTo {
            name
          }
        }
      }
      createdAt
      startDateTime
      endDateTime
      qty
      activityCode {
        code
        type
        fabIcon
        fabColor
        label
      }
    }
  }
`;
