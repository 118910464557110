// gqlFragments.js
import gql from 'graphql-tag';

export const Fragment_Order = gql`
  fragment OrderFragment on Order {
    id
    orderNum
    createdAt
    updatedAt
    poNumber
    isTaxExempt
    customerApprovalType
    salesPersonRef {
      id
      salesPersonNum
      type
      user {
        id
        fullName
        email
        avatarURL
      }
    }
    activeApprovalRequestNum
    activeApprovalRequestStatus
    activeApprovalRequestApprover {
      id
      fullName
      email
    }
    canApprove
    salesPersonRef {
      id
    }
    assignedTo {
      id
      fullName
      email
    }
    createdBy {
      id
      fullName
      email
    }
    updatedBy {
      id
      fullName
    }
    status
    orderTemplate {
      id
    }
    statusRef {
      id
      status
      buttonLabel
      requireApproval
    }
    orderObjectType
    type
    notes
    description
    orderFrom
    contact {
      id
      firstname
      lastname
      phone
      email
      contactNum
    }
    contactName
    contactPhone
    contactEmail
    billToContact {
      id
      firstname
      lastname
      phone
      email
      contactNum
    }
    billToContactName
    billToContactPhone
    billToContactEmail
    deliveryExpectedDate
    externalQuoteNumber
    deliveryDropOffType
    paymentType
    financeCompany
    financeIsCurrentCustomer
    financeTerms
    financePaymentAmount
    financeInterestRate
    financeWantsPurchaseOption
    financeSalesTaxPaymentType
    financeLeaseTermOvertimeRate
    financeLeaseTermHours
    financeLeaseOverTimeRate
    financeResidual
    financeDownPayment
    customerPONumber
    customerPOTerms
    includeFM
    doesRentalNeedPickedUp
    isUnitShippingOutOfTerritory
    customerExpectedDeliveryDate
    shippingDestination
    deliveryNotes
    makeReadyLocation
    makeReadyNotes
    locked
    notes
    crmWorkSheetReleased
    hasTSDR
    hasTradeIn
    hasLeaseReturn
    totalAmount
    totalTaxAmount
    totalOptionsAmount
    totalBaseAmount
    totalDiscountAmount
    totalPurchaseAmount
    isTemplate
    requiresApprovalBy {
      id
      fullName
    }
    shipTo {
      id
      name
      customerNum
      address
      address1
      address2
      city
      state
      lat
      lng
      zipcode
    }
    invoiceTo {
      id
      name
      customerNum
      address
      address1
      address2
      city
      state
      lat
      lng
      zipcode
    }
    billTo {
      id
      name
      customerNum
      address
      address1
      address2
      city
      state
      zipcode
    }
    orderLines {
      id
      qty
      unitAmount
      optionsAmount
      baseAmount
      downPaymentAmount
      crmTotalCost
      crmTotalSell
      childLinesAmount
      lineType
      description
      lineAmount
      purchAmount
      priceEffectiveDate
      itemNum
      make
      externalQuoteNumber
      notes
      equipment {
        id
        equipmentNum
        serial
        make
        model
      }
      options {
        description
        qty
        salePrice
        purchPrice
        notes
      }
      childLines {
        id
        qty
        parentLineId
        unitAmount
        optionsAmount
        baseAmount
        lineType
        purchAmount
        description
        lineAmount
        priceEffectiveDate
        itemNum
        make
        externalQuoteNumber
        notes
        options {
          description
          qty
          salePrice
          purchPrice
          notes
        }
        equipment {
          id
          equipmentNum
          serial
          make
          model
        }
        model {
          imageUrl
          id
          model
          group
          category
          make
        }
      }
      model {
        imageUrl
        id
        model
        make
      }
    }
  }
`;
