import { gql } from 'apollo-angular';

export const Query_GetServiceVisitsByGroupId = gql`
  query ($groupId: String!) {
    getServiceVisitsByGroupId(groupId: $groupId) {
      id
      serviceVisitNum
      status
      createdAt
      address
      address1
      address2
      city
      state
      country
      lat
      lng
      mapURL
      scheduledStartDateTime
      scheduledEndDateTime
      duration
      estTravelDistance
      estTravelTime
      estTravelFromLatLng
      estTravelPolyline
      category
      contactName
      contactPhone
      contactEmail
    }
  }
`;
