import { gql } from 'apollo-angular';

export const Query_GetServiceVisits = gql`
  query ($search: DtoPaginatedFilter!) {
    getServiceVisits(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          serviceVisitNum
          status
          createdAt
          address
          address1
          address2
          city
          state
          country
          lat
          lng
          mapURL
          scheduledStartDateTime
          scheduledEndDateTime
          duration
          estTravelDistance
          estTravelTime
          estTravelFromLatLng
          estTravelPolyline
          category
          contactName
          contactPhone
          contactEmail
          customer {
            id
            name
            customerNum
          }
          # serviceVisitWorkers {
          #   worker {
          #     displayName
          #     workerNum
          #     id
          #     user {
          #       id
          #       email
          #       avatarURL
          #     }
          #   }
          # }
          # serviceVisitWorkOrders {
          #   workOrder {
          #     id
          #     workOrderNum
          #     subject
          #     status
          #     type
          #   }
          # }
        }
      }
    }
  }
`;
