import { gql } from 'apollo-angular';

export const Mutation_GenerateServiceVisitSummaryOfWork = gql`
  mutation fillForm_ServiceVisitSummaryOfWork(
    $dto: DtoFillServiceVisitSummaryOfWork!
  ) {
    fillForm_ServiceVisitSummaryOfWork(dto: $dto) {
      id
    }
  }
`;
