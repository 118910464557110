import { gql } from 'apollo-angular';

export const Mutation_UpdateWorkOrderStatus = gql`
mutation updateWorkOrderStatus($dto: DtoUpdateWorkOrderStatus!) {
  updateWorkOrderStatus(dto:$dto){
    id
    status
  }
}
`;
