import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, RequiredValidator } from '@angular/forms';
import {
  NbAutocompleteComponent,
  NbAutocompleteDirective,
  NbDialogRef,
  NbOptionComponent,
  NbPopoverDirective,
} from '@nebular/theme';
import { SubSink } from 'subsink';
import { CustomerService } from '../../services/customer.service';
import { ContactListComponent } from '../contact-list/contact-list.component';

@Component({
  selector: 'add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss'],
})
export class AddContactComponent implements OnInit, OnDestroy {
  @Input() customerId: string;
  sub = new SubSink();
  customer;
  isSaving: boolean = false;

  form: FormGroup = new FormGroup({
    firstname: new FormControl(''),
    lastname: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    notes: new FormControl(''),
  });
  constructor(
    private readonly ref: NbDialogRef<AddContactComponent>,
    private readonly customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.getCustomer();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async save() {
    const dto = this.form.value;
    dto.customerId = this.customerId;
    this.isSaving = true;
    const resp = await this.customerService
      .addContactToCustomer(dto)
      .toPromise();
    const contact = resp['data']['addContactToCustomer'];
    this.isSaving = false;
    this.ref.close(contact);
  }

  async getCustomer() {
    this.sub.sink = this.customerService
      .getCustomer(this.customerId)
      .subscribe((resp) => {
        this.customer = resp['data']['getCustomer'];
      });
  }

  close() {
    this.ref.close();
  }
}
