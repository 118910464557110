import { gql } from 'apollo-angular';

export const Query_GetCustomerSignatureByServiceVisitId = gql`
  query ($serviceVisitId: String!) {
    getCustomerSignaturesByServiceVisitId(serviceVisitId: $serviceVisitId) {
      id
      signatureURL
      signatureImage
      name
      email
      createdAt
      createdBy
      signerNotAvailable
      createdByRef {
        id
        firstname
        lastname
        email
        avatarURL
      }
    }
  }
`;
