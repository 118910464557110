import { Injectable } from '@angular/core';
import { ISearchFilter } from '../../../@core/services/gridUtil.service';
import { CustomerServiceApi } from './customer-api.service';
import { NbDialogService } from '@nebular/theme';
import { AddContactComponent } from '../components/contact-add/add-contact.component';
import { IPaginatedFilter } from '../../../@core/interfaces/IPaginatedFilter';
@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(
    private readonly api: CustomerServiceApi,
    private readonly dialogService: NbDialogService,
  ) {}

  async showAddContact(customerId: string) {
    const dialog = this.dialogService.open(AddContactComponent, {
      context: {
        customerId,
      },
    });

    const result = await dialog.onClose.toPromise();
    return result;
  }

  addCustomer(data: any) {
    return this.api.addCustomer(data);
  }

  getContacts(search: any) {
    return this.api.getContacts(search);
  }

  getCustomerContacts(customerId: string) {
    return this.api.getCustomerContacts(customerId);
  }

  updateCustomer(data: any) {
    return this.api.updateCustomer(data);
  }

  getCustomers(search: IPaginatedFilter) {
    return this.api.getCustomers(search);
  }

  getCustomer(id: string) {
    return this.api.getCustomer(id);
  }

  getCustomerTypes() {
    return this.api.getCustomerTypes();
  }

  getCustomersBillToSearch(search: string) {
    return this.api.getCustomersBillToSearch(search);
  }

  addContactToCustomer(data: any) {
    return this.api.addContactToCustomer(data);
  }
}
