import { gql } from 'apollo-angular';

export const Mutation_AcknowledgeAttachment = gql`
  mutation acknowledgeAttachment($data: DtoAcknowledgeAttachment!) {
    acknowledgeAttachment(data: $data) {
      id
      isError
      errorMessage
      acknowledgedAt
    }
  }
`;
