import { gql } from "apollo-angular";

export const Mutation_DeleteSegmentNote = gql`
  mutation DeleteSegmentNote ($data: DeleteSegmentNote!) {
    deleteSegmentNote(data: $data) {
      id
      note
      type
      updatedAt
      updatedBy
    }
  }
`;
