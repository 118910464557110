<div class="header-container">
  <h6>
    <b>
      <i class="fa-solid fa-repeat mr-1"></i>
      Recurring Service Visit &bull;
      {{ serviceVisit?.serviceVisitWorkOrders?.length }} Work Order{{
        serviceVisit?.serviceVisitWorkOrders?.length > 1 ? 's' : ''
      }}
      &bull; {{ serviceVisit?.serviceVisitWorkers?.length }} Tech{{
        serviceVisit?.serviceVisitWorkers?.length > 1 ? 's' : ''
      }}
    </b>
  </h6>
  <button
    nbButton
    class="mr-1"
    [disabled]="plannedServiceVisits()?.length === 0"
    status="primary"
    nz-popover
    nzPopoverTrigger="click"
    [(nzPopoverVisible)]="showPopover"
    [nzPopoverContent]="recurringDispatchPopUp"
    nzPopoverPlacement="topRight"
    size="small"
  >
    <i class="fa-solid fa-truck-fast mr-1"></i>
    Dispatch Service Visits ({{ plannedServiceVisits()?.length }})
  </button>
</div>
<hr class="my-3" />
<ng-template #recurringDispatchPopUp>
  <nb-card class="popover-card">
    <nb-card-body>
      <div class="header-container">
        <h6><b>Dispatch Service Visits</b></h6>
        <span class="flex-expand"></span>
        <button
          nbButton
          status="primary"
          ghost
          class="closeMapOptionsButton"
          (click)="showDispatchPopover(false)"
        >
          <nb-icon icon="close-outline"></nb-icon>
        </button>
      </div>
      <hr class="py-0" />

      <h6><u>Summary</u></h6>
      <ul class="leaders">
        <li>
          <span>Service Visits</span>
          <span class="kpi">
            <i class="fa-solid fa-location-dot"></i>
            {{ plannedServiceVisits()?.length }}
          </span>
        </li>
        <li>
          <span>Travel Time</span>
          <span class="kpi">
            <i class="fa-solid fa-car"></i>
            {{ plannedServiceVisitTotalTravelTime
            }}<span class="hrPrefix">hrs </span>
          </span>
        </li>
        <li>
          <span>Work Hours</span>
          <span class="kpi">
            <i class="fa-solid fa-screwdriver-wrench"></i>
            {{ plannedServiceVisitTotalScheduledWorkHours
            }}<span class="hrPrefix">hrs </span>
          </span>
        </li>
      </ul>

      <h6><u>Worker Detail</u></h6>

      <ul class="leaders workers">
        <li *ngFor="let worker of plannedServiceVisitWorkers">
          <span>{{ worker.displayName }}</span>
          <span class="kpi">
            <i class="fa-solid fa-car"></i>
            {{ worker?.totalTravelTime / 60 | number: '1.1-1' }}hrs
            <span class="mx-1"> &bull; </span>
            <i class="fa-solid fa-screwdriver-wrench"></i>
            {{ worker?.totalScheduledWorkHours | number: '1.1-1' }}hrs
          </span>
        </li>
      </ul>

      <div>
        <!-- <div class="options">
          <nb-toggle
            class="my-2"
            status="primary"
            [checked]="notifyWorkersOnDispatch"
            (checkedChange)="notifyWorkersOnDispatch = $event"
            >Notify Workers
          </nb-toggle>
        </div> -->
        <br />
        <div class="row">
          <button
            [nbSpinner]="isDispatching"
            nbSpinnerStatus="info"
            nbButton
            fullWidth
            (click)="callUpdateStatus(plannedServiceVisits())"
            status="success"
          >
            {{ isDispatching ? 'Dispatching...' : 'Dispatch' }}
          </button>
        </div>
      </div>
      <div></div>
    </nb-card-body>
  </nb-card>
</ng-template>

<!-- <nb-tabset class="px-0">
  <nb-tab tabTitle="Calendar" class="px-0"> -->
<div class="d-flex mt-2">
  <div class="d-flex-row cal-header">
    <div>
      <div
        class="d-flex mt-2 align-items-baseline justify-content-center"
        style="margin-bottom: -10px"
      >
        <h6>Repeat</h6>
        <nz-select
          [(ngModel)]="dateUOM"
          nzSize="large"
          nzAutoFocus
          nzBorderless
          class="mr-0"
          (ngModelChange)="onDateUOMChange($event)"
          style="width: 120px"
          nzPlaceHolder="Select"
          [nzCustomTemplate]="defaultTemplate"
        >
          <nz-option nzValue="daily" nzLabel="Daily"></nz-option>
          <nz-option nzValue="weekly" nzLabel="Weekly"></nz-option>
        </nz-select>
      </div>

      @if (dateUOM) {
        <div class="d-flex align-items-baseline justify-content-center mb-2">
          <h6>for the next</h6>
          <nz-select
            [(ngModel)]="dateInterval"
            nzSize="large"
            nzAutoFocus
            nzBorderless
            class="mr-0 text-center"
            style="width: 80px"
            (ngModelChange)="onDateIntervalChange($event)"
            [nzCustomTemplate]="defaultTemplate"
          >
            @for (i of allowedIntervals; track i) {
              <nz-option [nzValue]="i" [nzLabel]="i"></nz-option>
            }
          </nz-select>

          @if (dateUOM === 'weekly') {
            <h6 class="mr-0">week{{ dateInterval > 1 ? 's' : '' }}</h6>
          } @else {
            <h6 class="mr-0">day{{ dateInterval > 1 ? 's' : '' }}</h6>
          }
        </div>
      } @else {
        <br />
      }

      @if (dateUOM === 'weekly') {
        <div class="d-flex justify-content-center">
          <nb-button-group
            multiple
            (valueChange)="updateMultiSelectGroupValue($event)"
            class="pb-2"
          >
            <button
              nbButtonToggle
              value="Sun"
              [pressed]="allowedDaysOfWeek.includes('Sun')"
            >
              S
            </button>
            <button
              nbButtonToggle
              value="Mon"
              [pressed]="allowedDaysOfWeek.includes('Mon')"
            >
              M
            </button>
            <button
              nbButtonToggle
              value="Tue"
              [pressed]="allowedDaysOfWeek.includes('Tue')"
            >
              T
            </button>
            <button
              nbButtonToggle
              value="Wed"
              [pressed]="allowedDaysOfWeek.includes('Wed')"
            >
              W
            </button>
            <button
              nbButtonToggle
              value="Thu"
              [pressed]="allowedDaysOfWeek.includes('Thu')"
            >
              T
            </button>
            <button
              nbButtonToggle
              value="Fri"
              [pressed]="allowedDaysOfWeek.includes('Fri')"
            >
              F
            </button>
            <button
              nbButtonToggle
              value="Sat"
              [pressed]="allowedDaysOfWeek.includes('Sat')"
            >
              S
            </button>
          </nb-button-group>
        </div>
      }
    </div>
    <div>
      <nb-calendar
        style="width: 328px"
        #calendar
        [(date)]="date"
        (dateChange)="onDateChange($event)"
        [dayCellComponent]="dayCellComponent"
      >
      </nb-calendar>
    </div>
  </div>

  <div class="pl-4 w-100">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <nb-tag-list>
        <nb-tag
          appearance="filled"
          text="All {{ associatedServiceVisits.length || 0 }}"
          status="primary"
        ></nb-tag>
        @if (createCount > 0) {
          <nb-tag text="{{ createCount }} Add" status="success"></nb-tag>
        }
        @if (deleteCount > 0) {
          <nb-tag text="{{ deleteCount }} Delete" status="danger"></nb-tag>
        }
      </nb-tag-list>
    </div>
    <hr class="my-2" />
    <div
      class="newSvList"
      [style.maxHeight.px]="dateUOM === 'weekly' ? 435 : 360"
    >
      @for (item of proposedServiceVisits; track item) {
        <div
          class="d-flex justify-content-between align-items-center my-2 mx-1"
        >
          <div class="flex-column date">
            <h6 class="service-visit-num">
              <b>{{ item.id ? item.serviceVisitNum : 'New Service Visit' }}</b>
            </h6>
            <p>
              {{ item?.scheduledStartDateTime | date: 'EEE, MMM dd Y' }}
            </p>
            <div class="d-flex align-items-baseline mt-0">
              {{ item?.scheduledStartDateTime | date: 'h:mm a' }}
              <h6>-</h6>
              {{ item?.scheduledEndDateTime | date: 'h:mm a' }}
            </div>
          </div>
          <div class="flex-column">
            @if (item?.id && !item?.toBeDeleted) {
              <p class="status">{{ item?.status }}</p>
            } @else if (item?.toBeDeleted) {
              <span class="tbd">
                <nb-tag status="danger" text="Delete"></nb-tag>
              </span>
            } @else {
              <span class="tbc">
                <nb-tag status="success" text="Add"></nb-tag>
              </span>
            }
          </div>
          <div class="flex-column status">
            <button
              nbButton
              ghost
              size="small"
              status="primary"
              class="ml-1 pl-1"
              (click)="callUpdateStatus(item.id)"
              [disabled]="item?.status !== 'Planned'"
            >
              <i class="fa-light fa-truck-fast mr-1"></i>
              <span class="list-header-icon">Dispatch</span>
            </button>
          </div>
        </div>
        <hr class="my-2" />
      }
    </div>
    @if (createCount + deleteCount > 0) {
      <div class="d-flex mt-2">
        <button
          nbButton
          size="small"
          status="basic"
          class="ml-2"
          (click)="resetPropossed()"
        >
          Reset
        </button>
        <div class="flex-grow-1"></div>
        <button
          nbButton
          size="small"
          status="primary"
          class="mr-2"
          (click)="modifyPropossed()"
          [nbSpinner]="isSaving"
          nbSpinnerStatus="light"
        >
          Save
        </button>
      </div>
    }
  </div>
</div>
<!--  </nb-tab>
<nb-tab tabTitle="List" class="px-0">
    <next-object-list
      [hideQueues]="true"
      [hideToolBar]="true"
      [object]="'Service_Visit'"
      [height]="300"
    ></next-object-list>
  </nb-tab>
</nb-tabset> -->
<ng-template #defaultTemplate let-selected>
  <span class="select-label">{{ selected.nzLabel }}</span>
</ng-template>
