export const TABS = [
  {
    title: 'Complaint',
    key: 'noteComplaint',
    hasCodes: false,
  },
  {
    title: 'Cause',
    key: 'noteCause',
    hasCodes: false,
  },
  {
    title: 'Correction',
    key: 'noteCorrection',
    hasCodes: false,
  },
  {
    title: 'Recommendation',
    key: 'noteRecommendation',
    hasCodes: false,
    excludeHeader: true,
  },
  {
    title: 'Internal',
    key: 'noteInternal',
    hasCodes: false,
    excludeHeader: true,
  },
];

export const NOTE_MENU_ITEMS = [
  {
    title: 'Edit Note',
    icon: 'edit-outline',
  },
  {
    title: 'Delete',
    icon: 'trash-2-outline',
  },
]

export const CODE_MENU_ITEMS = [
  {
    title: 'Edit Codes',
    icon: 'edit-outline',
  },
]