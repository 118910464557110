import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { MUTATION_ADDCUSTOMER } from '../../../@core/graphql/mutations/customers/addcustomer.mutation';
import { QUERY_GETCUSTOMERS } from '../../../@core/graphql/queries/customers/getcustomers.query';
import { QUERY_GETCUSTOMERSBILLTOSEARCH } from '../../../@core/graphql/queries/customers/getCustomersBillToSearch.query';
import { QUERY_GETCUSTOMER } from '../../../@core/graphql/queries/customers/getcustomer.query';
import { QUERY_GETCUSTOMERTYPES } from '../../../@core/graphql/queries/customers/getcustomertypes.query';
import { MUTATION_UPDATECUSTOMER } from '../../../@core/graphql/mutations/customers/updatecustomer.mutation';
import { ISearchFilter } from '../../../@core/services/gridUtil.service';
import { Query_GetCustomerContacts } from '../../../@core/graphql/queries/customers/getCustomerContacts.query';
import { Query_GetContacts } from '../../../@core/graphql/queries/customers/getContacts.query';
import { DtoAddContact } from '../dto/dtoAddContact';
import { Mutation_AddContact } from '../../../@core/graphql/mutations/customers/addContact.mutation';
import { IPaginatedFilter } from '../../../@core/interfaces/IPaginatedFilter';

@Injectable({
  providedIn: 'root',
})
export class CustomerServiceApi {
  constructor(private apollo: Apollo) {}

  addCustomer(data: any) {
    return this.apollo.mutate({
      mutation: MUTATION_ADDCUSTOMER,
      variables: { data },
      refetchQueries: [
        {
          query: QUERY_GETCUSTOMERS,
        },
      ],
    });
  }

  getContacts(search: any) {
    return this.apollo.watchQuery({
      query: Query_GetContacts,
      variables: { search },
    }).valueChanges;
  }

  getCustomerContacts(customerId: string) {
    return this.apollo.watchQuery({
      query: Query_GetCustomerContacts,
      variables: { customerId },
    }).valueChanges;
  }

  updateCustomer(data: any) {
    return this.apollo.mutate({
      mutation: MUTATION_UPDATECUSTOMER,
      variables: { data },
      refetchQueries: [
        {
          query: QUERY_GETCUSTOMER,
          variables: { id: data.id },
        },
      ],
    });
  }

  getCustomers(search: IPaginatedFilter) {
    return this.apollo.watchQuery({
      query: QUERY_GETCUSTOMERS,
      variables: { search: search },
    }).valueChanges;
  }

  getCustomer(id: string) {
    return this.apollo.watchQuery({
      query: QUERY_GETCUSTOMER,
      variables: { id: id },
    }).valueChanges;
  }

  getCustomerTypes() {
    return this.apollo.watchQuery({
      query: QUERY_GETCUSTOMERTYPES,
    }).valueChanges;
  }

  getCustomersBillToSearch(search: string) {
    return this.apollo.watchQuery({
      query: QUERY_GETCUSTOMERSBILLTOSEARCH,
      variables: { search },
    }).valueChanges;
  }

  addContactToCustomer(data: DtoAddContact) {
    return this.apollo.mutate({
      mutation: Mutation_AddContact,
      variables: { data },
      refetchQueries: [
        {
          query: Query_GetCustomerContacts,
          variables: { customerId: data.customerId },
        },
      ],
    });
  }
}
