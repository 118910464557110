<nb-chat size="large">
  <nb-chat-message
    *ngFor="let msg of (session$ | async)?.messages"
    [message]="msg.message"
    [reply]="msg.fromUser.id === userId"
    [sender]="msg.fromUser.fullName"
    [date]="msg.createdAt"
    [avatar]="msg.fromUser.avatarURL"
  >
  </nb-chat-message>
  <nb-chat-form (send)="sendMessage($event)" [dropFiles]="true"> </nb-chat-form>
</nb-chat>
