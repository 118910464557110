import { gql } from 'apollo-angular';

export const Query_GetServiceVisitWorkOrder = gql`
  query ($search: DtoPaginatedFilter!) {
    getServiceVisitWorkOrders(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          workOrder {
            id
            workOrderNum
            id
            type
            category
            status
            createdAt
            updatedAt
            workOrderNum
            category
            serial
            make
            poNumber
            priority
            customerEquipmentNum
            model
            address
            address1
            address2
            city
            state
            zipcode
            country
            lat
            lng
            subject
            scheduledStartDateTime
            scheduledEndDateTime
            isPastDue
            isMissed
            isClosed
            contactEmail
            contactName
            contactPhone
            rescheduleCount
            pastDueCount
            missedCount
            mapURL
            estTravelTime
            estTravelDistance
            estTravelHash
            estTravelFromLatLng
            branchNum
            locationNum
            departmentNum
            workerNames
          }
          workOrderStatusRef {
            id
            status
            isCompleteOnServiceVisit
          }
        }
      }
    }
  }
`;
