<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <nb-card>
    <nb-card-header>New Customer</nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="customername" class="label">Customer Name</label>
            <input
              type="text"
              nbInput
              fullWidth
              id="customername"
              formControlName="name"
              fieldSize="small"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="customerType" class="label">Customer Type</label>
            <nb-select fullWidth formControlName="type" size="small">
              <nb-option *ngFor="let type of customerTypes" [value]="type.name">
                {{ type.name }}
              </nb-option>
            </nb-select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-lg-5">
            <label for="customerType" class="label"
              >Bill To Customer#
              <nb-icon
                icon="search-outline"
                status="primary"
                style="font-size: 1.2em"
              >
              </nb-icon
            ></label>
            <next-customerinput
              [customer]="billTo"
              (onCustomerChange)="billToChange($event)"
            ></next-customerinput>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-2"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="externalId" class="label">External Id</label>
            <input
              type="text"
              nbInput
              fullWidth
              id="externalId"
              formControlName="externalId"
              fieldSize="small"
            />
          </div>
        </div>
        <!-- <div class="col-lg-6">
                      <div class="form-group ">
                          <label for="externalId" class="label">Reporting Tags</label>
                          <nb-tag-list>
                              <nb-tag *ngFor="let tag of tags" [text]="tag" removable
                                  status="success"></nb-tag>
                              <input type="text" nbTagInput fullWidth>
                          </nb-tag-list>
                      </div>
                  </div> -->
      </div>
      <next-address [form]="form"></next-address>
    </nb-card-body>
    <nb-card-footer>
      <div class="header-container">
        <span class="flex-expand"></span>
        <button nbButton status="primary" type="submit">Create</button>
        <button nbButton (click)="close()">Cancel</button>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
