import { Component, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { CustomerService } from '../services/customer.service';
import { of } from 'rxjs';

@Component({
  selector: 'next-createcustomer',
  templateUrl: './createcustomer.component.html',
  styleUrls: ['./createcustomer.component.scss'],
})
export class CustomerCreateComponent {
  customerTypes: any;
  billTo: any;
  error: string;
  form = new FormGroup({
    name: new FormControl(''),
    type: new FormControl(''),
    externalId: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    zipcode: new FormControl(''),
    country: new FormControl(''),
    lat: new FormControl(''),
    lng: new FormControl(''),
    billToId: new FormControl(''),
  });

  constructor(
    protected ref: NbDialogRef<CustomerCreateComponent>,
    private customerService: CustomerService
  ) {
    this.getCustomerTypes();
  }

  getCustomerTypes() {
    this.customerService.getCustomerTypes().subscribe(({ data }) => {
      const rtn: any = data;
      this.customerTypes = rtn.__type.enumValues;
    });
  }

  billToChange(customer: any) {
    this.billTo = customer;
  }

  close() {
    this.ref.close();
  }

  onSubmit() {
    let obj: any = this.form.value;
    obj.billToId = this.billTo?.id || null;
    this.customerService.addCustomer(obj).subscribe(
      (rnt) => {
        this.ref.close();
      },
      (err) => {
        this.error = err;
      }
    );
  }
}
