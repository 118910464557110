import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbPopoverModule,
  NbTabsetModule,
} from '@nebular/theme';
import { DynamicListViewModule } from '../../dynamiclistview/dynamiclistview.module';
import { CustomerInputComponent } from './customer-input.component';

@NgModule({
  declarations: [CustomerInputComponent],
  exports: [CustomerInputComponent],
  imports: [
    NbCardModule,
    NbButtonModule,
    NbFormFieldModule,
    NbIconModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbInputModule,
    NbPopoverModule,
    NbDialogModule,
    DynamicListViewModule,
  ],
})
export class CustomerInputModule {}
