import { gql } from 'apollo-angular';

export const Mutation_AddContact = gql`
  mutation addContactToCustomer($data: DtoAddContact!) {
    addContactToCustomer(data: $data) {
      id
      firstname
      lastname
      email
      phone
      notes
    }
  }
`;
