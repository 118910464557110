export enum QueueType {
  Global = 'Global',
  Location = 'Location',
  Personal = 'Personal',
}

export enum QueueViewType {
  Default = 'Default',
  Mobile = 'Mobile',
  Scheduler = 'Scheduler',
}

export class DtoAddQueue {
  name: string;
  description?: string;
  type: string;
  viewType: QueueViewType;
  object: string;
  filter?: any[];
  locationId?: string;
  userId?: string;
  sort?: number;
  page?: string;
}
