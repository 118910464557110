import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as statesjson from '../../../data/states.json';

@Component({
  selector: 'next-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {
  get form() {
    return this._form;
  }
  @Input() set form(form: any) {
    this.ngZone.run(() => {
      this._form = form;
    });
  }

  @ViewChild('search')
  public searchElementRef: ElementRef;
  map: any;
  zoom: number;
  geoCoder: any;
  _form: any;
  lat: string;
  lng: string;
  states = (statesjson as any).default;

  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {
    this.setupPlacesAutocomplete();
  }

  mapReady(map: any) {
    this.map = map;
    this.lat = this.form.value.lat;
    this.lng = this.form.value.lng;
  }

  zoomChange(x) {
    console.log(x);
  }

  setupPlacesAutocomplete() {
    // this.mapsAPILoader.load().then(() => {
    //   this.geoCoder = new google.maps.Geocoder();
    //   let autocomplete = new google.maps.places.Autocomplete(
    //     this.searchElementRef.nativeElement
    //   );
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }
    //       this.lat = place.geometry.location.lat().toString();
    //       this.lng = place.geometry.location.lng().toString();
    //       const addy_obj = this.getAddressObject(place.address_components);
    //       this.form.patchValue({
    //         lat: place.geometry.location.lat().toString(),
    //         lng: place.geometry.location.lng().toString(),
    //         address1: `${addy_obj.home || ""} ${addy_obj.street || ""}`,
    //         city: addy_obj.city,
    //         state: addy_obj.region,
    //         zipcode: addy_obj.postal_code,
    //         country: addy_obj.country,
    //       });
    //     });
    //   });
    // });
  }

  handleAddressChange(address) {}

  markerDragEnd($event: any) {
    this.form.patchValue({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
    });

    //this.getAddress(this.latitude, this.longitude);
  }

  getAddressObject(address_components) {
    var ShouldBeComponent = {
      home: ['street_number'],
      postal_code: ['postal_code'],
      street: ['street_address', 'route'],
      region: [
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
      ],
      city: [
        'locality',
        'sublocality',
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
      ],
      country: ['country'],
    };

    var address = {
      home: '',
      postal_code: '',
      street: '',
      region: '',
      city: '',
      country: '',
    };
    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          address[shouldBe] = component.long_name;
        }
      }
    });
    return address;
  }
}
