export const environment = {
  local: false,
  production: false,
  mapbox: {
    accessToken:
      'pk.eyJ1IjoicmFtaW5ub29kbGUiLCJhIjoiY2swN2h5OHNuNDBjbTNicGZpeHUwd3c3NyJ9.1Wdc6fxsLIWKgX9DjXu2Fg',
  },
  graphQL: 'https://dev.api.nextfieldservice.com/graphql',
  graphQLWS: 'wss://dev.api.nextfieldservice.com/graphql',
};
