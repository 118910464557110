import { gql } from 'apollo-angular';

export const Mutation_SendMessage = gql`
  mutation sendMessage($data: DtoSendMessage!) {
    sendMessage(data: $data) {
      id
      sessionId
      message
      createdAt
      fromUser {
        id
        fullName
      }
    }
  }
`;
