import { gql } from "apollo-angular";


export const Mutation_AddReportingCode = gql`
  mutation addReportingCode($dto: CreateReportingCode!) {
    addReportingCode(addReportingCode: $dto) {
      id
      type
      name
      description
    }
  }
`;