import { Component, Input, OnInit } from '@angular/core';
import { CustomerService } from '../../../../pages/customer/services/customer.service';

@Component({
  selector: 'ngx-customer-popover',
  templateUrl: './customer-popover.component.html',
  styleUrls: ['./customer-popover.component.scss'],
})
export class CustomerPopoverComponent implements OnInit {
  @Input() id: string;
  loading: boolean = true;
  customer: any = {};
  constructor(private customerService: CustomerService) {}

  ngOnInit(): void {
    this.customerService
      .getCustomer(this.id)
      .subscribe(({ data, loading, error }) => {
        const rtn: any = data;
        this.loading = loading;
        this.customer = rtn.getCustomer;
      });
  }
}
