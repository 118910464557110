<div>
  <nb-form-field>
    <input
      nbInput
      status="primary"
      fullWidth
      fieldSize="small"
      class="customerInput"
      [value]="label"
      [disabled]="locked"
      [nbPopover]="locked ? null : list"
      [nbPopoverPlacement]="'bottom'"
      readonly
    />
    <button
      nbSuffix
      nbButton
      ghost
      *ngIf="!locked"
      type="button"
      [nbPopover]="locked ? null : list"
      [nbPopoverPlacement]="'bottom'"
    >
      <nb-icon icon="search-outline"> </nb-icon>
    </button>
  </nb-form-field>
</div>

<ng-template #list>
  <!-- <div style="position: absolute; top: -35px; right: 0px" *ngIf="!hideToolBar">
    <span class="flex-expand"></span>
    <button nbButton hero size="small" status="primary" (click)="newContact()">
      <nb-icon icon="plus-outline"></nb-icon>New Contact
    </button>
  </div> -->
  <nb-card class="margin-bottom-0">
    <next-object-list
      [object]="'Contact'"
      [height]="400"
      [hideQueues]="true"
      [hideToolBar]="true"
      [filters]="filters"
      style="width: 900px"
      (onDoubleClick)="selected($event)"
    ></next-object-list>
  </nb-card>
</ng-template>
