import { gql } from 'apollo-angular';

export const Query_GetWorkOrderByNum = gql`
  query ($id: String!) {
    getWorkOrderByNum(id: $id) {
      id
      type
      status
      workOrderNum
      serial
      make
      model
      address
      address1
      address2
      city
      state
      zipcode
      country
      lat
      lng
      subject
      contactName
      contactEmail
      contactPhone
      scheduledStartDateTime
      scheduledEndDateTime
      shipTo {
        id
        name
        customerNum
      }
      billTo {
        id
        name
        customerNum
      }
      equipment {
        id
        equipmentNum
        make
        model
      }
    }
  }
`;
