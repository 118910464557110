import { gql } from 'apollo-angular';

export const QUERY_GETUSERS = gql`
  query ($search: DtoPaginatedFilter!) {
    getUsers(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          firstname
          lastname
          fullName
          createdAt
          email
          phone
          disabled
          lastLoginDateTime
          lastTokenRefreshDateTime
          roles {
            role {
              name
              userType
            }
            createdAt
          }
        }
      }
    }
  }
`;
