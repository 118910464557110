import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { _finally } from 'rxjs-compat/operator/finally';
import { SubSink } from 'subsink';
const jsonLogic = require('json-logic-js');

export interface GridMenuButton {
  type?: 'button' | 'dropdown' | 'divider';
  label?: string;
  icon?: string;
  iconColor?: string;
  popOver?: any;
  action?:
    | 'new'
    | 'edit'
    | 'delete'
    | 'copy'
    | 'attachments'
    | 'popup'
    | 'custom';
  actionParams?: any;
  customAction?: string;
  disabled?: any;
  children?: GridMenuButton[];
  isDisabled?: boolean;
}

@Component({
  selector: 'grid-menu',
  templateUrl: './grid-menu.component.html',
  styleUrls: ['./grid-menu.component.scss'],
})
export class GridMenuComponent implements OnInit {
  sub = new SubSink();
  @Output() buttonAction = new EventEmitter();
  @Input() set selectedRow(value: any) {
    if (!value) return;
    this._selectedRow = value;
    this.runButtonLogic();
  }
  get selectedRow(): any {
    return this._selectedRow;
  }
  _selectedRow: any;

  @Input() set gridMenuButtons(value: any) {
    this._gridMenuButtons = value;
    this.runButtonLogic();
  }
  get gridMenuButtons(): any {
    return this._gridMenuButtons;
  }
  _gridMenuButtons: any;

  constructor() {
    // this.getButtons();
  }

  ngOnInit(): void {}

  emitButtonAction(button) {
    this.buttonAction.emit(button);
  }

  runButtonLogic() {
    this.gridMenuButtons.forEach(({ ...button }) => {
      if (button?.disabled) {
        if (button.disabled === 'NoRecord') {
          button.isDisabled = this.selectedRow;
        } else {
          button.isDisabled = !jsonLogic.apply(
            button.disabled,
            this.selectedRow,
          );
        }
      }

      // if (button.children) {
      //   button.children.forEach((childButton) => {
      //     if (childButton.disabled) {
      //       if (button.disabled === "NoRecord") {
      //         button.isDisabled = !this.selectedRow;
      //       } else {
      //         childButton.isDisabled = !jsonLogic.apply(
      //           childButton.disabled,
      //           this.selectedRow
      //         );
      //       }
      //     }
      //   });
      // }
    });
  }
}
