import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { MUTATION_AddAttachment } from '../../../@core/graphql/mutations/attachment/addAttachment.mutation';
import { QUERY_GetAttachments } from '../../../@core/graphql/queries/attachment/getAttachments';
import { Mutation_AcknowledgeAttachment } from '../../../@core/graphql/mutations/attachment/acknowledgeAttachment.mutation';
import { Mutation_RemoveAttachment } from '../../../@core/graphql/mutations/attachment/removeAttachment.mutation';

@Injectable({
  providedIn: 'root',
})
export class AttachmentApiService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
  ) {}

  removeAttachment(id: string) {
    //remove attachment from apollo cache

    return this.apollo.mutate({
      mutation: Mutation_RemoveAttachment,
      variables: {
        id,
      },
      update(cache) {
        const normalizedId = cache.identify({ id, __typename: 'Attachment' });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  }

  addAttachment(data: any) {
    return this.apollo.mutate({
      mutation: MUTATION_AddAttachment,
      variables: { data },
      // refetchQueries: [
      //   {
      //     query: QUERY_GetAttachments,
      //     variables:{
      //       data: { refId:data.refId, object: data.object },
      //     }
      //   },
      // ],
    });
  }

  acknowledgeAttachment(data: any) {
    return this.apollo.mutate({
      mutation: Mutation_AcknowledgeAttachment,
      variables: {
        data,
      },
    });
  }

  getAttachments(refId: string, object: string) {
    return this.apollo.watchQuery({
      query: QUERY_GetAttachments,
      fetchPolicy: 'network-only',
      variables: {
        data: { refId, object },
      },
    }).valueChanges;
  }

  addAttachmentToS3(file, url) {
    return this.http.put(url, file, {
      headers: {
        'Content-Type': 'application/octet-stream',
        'Content-Encoding': 'base64',
      },
    });
  }
}
