import { gql } from 'apollo-angular';

export const Subscription_MessageAdded = gql`
  subscription {
    messageAdded {
      message
      id
      createdAt
      sessionId
      fromUser {
        id
        firstname
        lastname
      }
    }
  }
`;
