import { gql } from 'apollo-angular';

export const Mutation_ResetColumnLayoutByObjectType = gql`
  mutation ResetColumnLayoutByObjectType(
    $dto: DtoResetColumnLayoutByObjectType!
  ) {
    ResetColumnLayoutByObjectType(dto: $dto) {
      id
      columns
      object
      buttons
    }
  }
`;
