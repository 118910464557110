import { Injectable } from '@angular/core';

export interface ISearchFilterColumnDef {
  column: number;
  value: number;
}

export interface ISearchFilter {
  skip: number;
  take: number;
  countOnly?: boolean;
  filter: ISearchFilterColumnDef[];
  orderBy: ISearchFilterColumnDef[];
}

export interface IPaginatedFilter_OLD {
  first?: number;
  last?: number;
  before?: string;
  after?: string;
  //countOnly?: boolean;
  filter?: ISearchFilterColumnDef[];
  orderBy?: ISearchFilterColumnDef[];
}

@Injectable({
  providedIn: 'root',
})
export class GridUtilService {
  constructor() {}

  normalizeGridFilter(filterModel: any) {
    let filter = [];
    Object.entries(filterModel).forEach(([key, value]) => {
      filter.push({ column: key, value: (<any>value).filter });
    });
    return filter;
  }

  normalizeGridSort(sortModel: any) {
    let sort = [];
    sortModel.forEach((_sort: any) => {
      sort.push({ column: _sort.colId, value: _sort.sort });
    });
    return sort;
  }
}
