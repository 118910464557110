import { gql } from 'apollo-angular';

export const QUERY_GETGPSLOGS = gql`
  query ($data: DtoGetGPSLogs!) {
    getGPSLogs(data: $data) {
      id
      latitude
      longitude
      speed
      heading
      accuracy
      workerId
    }
  }
`;
