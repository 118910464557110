import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbButtonModule, NbCardModule, NbIconModule } from '@nebular/theme';

import { AgGridModule } from 'ag-grid-angular';
import { ContactListComponent } from './contact-list.component';
import { DynamicListViewModule } from '../../../../shared/component/dynamiclistview/dynamiclistview.module';

@NgModule({
  declarations: [ContactListComponent],
  exports: [ContactListComponent],
  imports: [
    CommonModule,
    NbCardModule,
    AgGridModule,
    NbButtonModule,
    NbIconModule,
    DynamicListViewModule,
  ],
})
export class ContactListModule {}
