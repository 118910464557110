import { gql } from 'apollo-angular';

export const QUERY_GETWORKORDERSEGMENTSBYWORKORDERID = gql`
  query ($id: String!) {
    getWorkOrderSegmentsByWorkOrderId(workOrderId: $id) {
      id
      workOrderSegmentNum
      subject
      status
      make
      model
      serial
      customerEquipmentNum
      address
      address1
      address2
      city
      state
      zipcode
      country
      lat
      lng
      note_ProblemDesc
      note_Findings
      note_Corrections
      note_Internal
      scheduledStartDateTime
      scheduledEndDateTime
      workOrder {
        id
        workOrderNum
        shipTo {
          id
          name
          customerNum
          address
          lat
          lng
        }
      }
      billTo {
        id
        name
        customerNum
      }
      equipment {
        id
        equipmentNum
        make
        model
      }
      worker {
        id
        displayName
      }
    }
  }
`;
