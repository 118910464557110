import { gql } from 'apollo-angular';

export const Query_GetWorkOrderStatuses = gql`
  query {
    getWorkOrderStatuses {
      status
      description
    }
  }
`;
