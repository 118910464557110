import { gql } from 'apollo-angular';

export const Mutation_AddBlockTimes = gql`
  mutation addBlockTimes($dto: [DtoAddBlockTime!]!) {
    addBlockTimes(dto: $dto) {
      workerId
      scheduledStartDateTime
      scheduledEndDateTime
      reason
      description
    }
  }
`;
