import { gql } from 'apollo-angular';

export const Mutation_UpdateServiceVisitEstTravel = gql`
  mutation updateServiceVisitEstTravel(
    $dto: DtoUpdateServiceVisitEstTravelTime!
  ) {
    updateServiceVisitEstTravel(dto: $dto) {
      id
      estTravelTime
      estTravelDistance
      estTravelFromLatLng
      estTravelPolyline
    }
  }
`;
