import { gql } from 'apollo-angular';

export const Query_GetCustomerSignatureByServiceVisitWorker = gql`
  query ($dto: DtoGetCustomerSignatureByObject!) {
    getCustomerSignatureByServiceVisitWorker(dto: $dto) {
      id
      signatureURL
      signatureImage
      name
      email
      createdAt
      createdBy
      signerNotAvailable
      createdByRef {
        id
        firstName
        lastName
        email
        avatarURL
      }
    }
  }
`;
