import { gql } from "apollo-angular";

export const Mutation_UpdateSegmentReportingCode = gql`
  mutation updateSegmentReportingCode ($data: UpdateSegmentReportingCode!) {
    updateSegmentReportingCode (data: $data) {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      workOrderSegmentId
      reportingCodeId
    }
  }
`;