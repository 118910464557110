import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridQueueComponent } from './grid-queue.component';
import {
  NbButtonGroupModule,
  NbButtonModule,
  NbCardModule,
  NbIconModule,
  NbPopoverModule,
  NbSelectModule,
} from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({
  declarations: [GridQueueComponent],
  exports: [GridQueueComponent],
  imports: [
    CommonModule,
    FormsModule,
    NbButtonGroupModule,
    NbIconModule,
    NbPopoverModule,
    NbCardModule,
    NbButtonModule,
    NbSelectModule,
    DragDropModule,
  ],
})
export class GridQueueModule {}
