import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  signal,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbPopoverDirective,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import { filter, map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TokenService } from '../../../@core/services/token.service';
import { MessagingService } from '../../../@core/services/messaging/messaging.service';
import { EnvService } from '@environment';
import { SubSink } from 'subsink';
const { version } = require('../../../../../../../package.json');

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(NbPopoverDirective) list: NbPopoverDirective;
  @ViewChild('envTog') envTog: ElementRef;
  private destroy$: Subject<void> = new Subject<void>();
  private sub = new SubSink();
  userPictureOnly = false;
  user: any;
  user$;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [
    { title: 'Profile' },
    { title: 'Log out' },
    { title: `v${version}` },
  ];
  messageSessions$;
  teamMessages$;
  directMessages$;
  unreadMessaegeCount = 0;
  messageSub;
  currentEnv = signal(true);
  activeApi;
  isProd;
  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private tokenService: TokenService,
    private breakpointService: NbMediaBreakpointsService,
    private messagingService: MessagingService,
    public sharedEnv: EnvService,
  ) {
    this.isProd = this.sharedEnv.getCurrentEnv();
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.user$ = this.tokenService.get().subscribe((user) => {
      if (!user) {
        return;
      }
      this.user = user;
      this.initSubscriptions();
      if (this.messageSub) {
        this.messageSub?.unsubscribe();
      }

      this.messageSub = this.messagingService
        .getMySessions()
        .pipe(
          map((p: any[]) => {
            return p.filter((v) => v.hidden !== true);
          }),
          tap((sessions: any) => {
            let unreadMessaeges = 0;
            sessions.forEach((session) => {
              unreadMessaeges += session.unreadMessageCount;
            });
            this.unreadMessaegeCount = unreadMessaeges;
          }),
        )
        .subscribe();

      this.sub.sink = this.messagingService
        .subscribeToMessageAdded()
        .subscribe((a) => {});
    });

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'userMenu'),
        map(({ item: { title } }) => title),
      )
      .subscribe((title) => {
        switch (title.toUpperCase()) {
          case 'LOG OUT':
            this.tokenService.logout();
            break;
          case 'ENVIRONMENT':
            //console.log('ENVIRONMENT: ' + JSON.stringify(this.environment));
            //console.log('is current environment Prod?: ' + this.sharedEnv.getCurrentEnv());
            //console.log('changing environment...');
            //   this.sharedEnv.activateProdEnv(!this.sharedEnv.getCurrentEnv());
            break;
        }
      });

    const { xl } = this.breakpointService.getBreakpointsMap();
    // this.themeService
    //   .onMediaQueryChange()
    //   .pipe(
    //     map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe(
    //     (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
    //   );

    // this.themeService
    //   .onThemeChange()
    //   .pipe(
    //     map(({ name }) => name),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe((themeName) => (this.currentTheme = themeName));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.user$.unsubscribe();
    this.sub.unsubscribe();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  ngAfterViewInit() {
    this.getSidebarState();
  }

  getSidebarState() {
    const isOpen = localStorage.getItem('sidebarState');
    if (isOpen === 'expanded') {
      this.sidebarService.expand('menu-sidebar');
    } else {
      this.sidebarService.compact('menu-sidebar');
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.sidebarService.getSidebarState('menu-sidebar').subscribe((state) => {
      localStorage.setItem('sidebarState', `${state}`);
    });
    return false;
  }

  toggleChatSidebar(): boolean {
    this.sidebarService.toggle(true, 'chat-sidebar');
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  openChatSession(session) {
    this.list.hide();
    this.messagingService.showSessionWindow(session);
  }

  hideSession(session) {
    this.messagingService.hideSession(session?.sessionId).toPromise();
  }

  async initSubscriptions() {
    //const loading = await this.commonService.showLoading();

    const sessions = this.messagingService.getMySessions().pipe(
      map((p: any[]) => {
        return p.filter((v) => v.hidden !== true);
      }),
      tap((sessions) => {
        let unreadMessaeges = 0;
        sessions.forEach((session) => {
          unreadMessaeges += session.unreadMessageCount;
        });
        this.unreadMessaegeCount = unreadMessaeges;
      }),
    );

    this.messageSessions$ = sessions;

    this.teamMessages$ = this.messageSessions$.pipe(
      map((p: any[]) => {
        return p.filter((v) => v.type === 'Team');
      }),
    );
    this.directMessages$ = this.messageSessions$.pipe(
      map((p: any[]) => {
        return p.filter((v) => v.type === 'Direct');
      }),
    );
  }
}
