import { gql } from 'apollo-angular';

export const QUERY_GetDefaultActivityWork = gql`
  query {
    getDefaultActivityWork {
      id
      code
      description
      type
    }
  }
`;
