import { gql } from 'apollo-angular';

export const Query_GetBlockTimes = gql`
  query ($search: DtoPaginatedFilter!) {
    getBlockTimes(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          createdBy
          updatedBy
          tenantId
          scheduledStartDateTime
          scheduledEndDateTime
          workerId
          worker {
            id
            team
            displayName
          }
          reason
          description
        }
      }
    }
  }
`;
