import { gql } from 'apollo-angular';

export const Query_GetCustomerContacts = gql`
  query ($customerId: String!) {
    getCustomerContacts(customerId: $customerId) {
      id
      firstname
      lastname
      phone
      email
      updatedAt
      notes
    }
  }
`;
