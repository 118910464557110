import { gql } from 'apollo-angular';

export const Mutation_HideSession = gql`
  mutation hideSession($sessionId: String!) {
    hideSession(sessionId: $sessionId) {
      id
      hidden
    }
  }
`;
